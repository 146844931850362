/* eslint-disable max-classes-per-file */
import BaseModel from './BaseModel';

export class ProductTypeModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/products?page=1&limit=100';
  }

  getSubmitMethod() {
    return 'PATCH';
  }

  getSubmitUrl() {
    return `admin/products/${this.data.productId}`;
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: false,
        type: 'field'
      },
      {
        name: 'name',
        label: 'Name',
        sortable: false,
        type: 'field'
      },
      {
        name: 'limitGroup.name',
        label: 'Limit group',
        sortable: false,
        type: 'field'
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions'
      }
    ];
  }
}

export class AccountProductTypeModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/products?page=1&limit=100&productType=ACCOUNT';
  }
}

export class PaymentDeviceProductTypeModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/products?page=1&limit=100&productType=PAYMENT_DEVICE';
  }
}
