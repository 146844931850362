import { useSelector } from 'react-redux';
import { PolicyAction } from './access-control/access-control.enums';
import {
  AccessControlResource,
  Policy
} from './access-control/access-control.types';
import { requestPermission } from './access-control/access-control.utils';

// eslint-disable-next-line import/prefer-default-export
export const useCheckPermission = (
  permission: PolicyAction,
  resource: AccessControlResource | undefined
): boolean => {
  const policies = useSelector(
    (state: { auth: { policies: Policy[] } }) => state.auth.policies
  );
  if (!policies) return true;

  return requestPermission(policies, [permission], resource);
};
