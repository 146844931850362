import Api from '../../../logic/api/Api';
import BaseModel from '../../../logic/model/BaseModel';

export default class FeeCollectionModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/fee-collections';
  }

  /**
   * Get retry url
   */
  getRetryFeeUrl(id) {
    return `admin/fee-collections/${id}/retry`;
  }

  async retryFee(id) {
    const result = await Api.call(this.getRetryFeeUrl(id), 'POST');
    return result;
  }

  /**
   * Get revert url
   */
  getRevertFeeUrl(id) {
    return `admin/fee-collections/${id}/revert`;
  }

  async revertFee(id, amount = null) {
    const result = await Api.call(
      this.getRevertFeeUrl(id),
      'POST',
      amount ? { amount: String(amount) } : {}
    );
    return result;
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: false,
        type: 'field',
      },
      {
        name: 'title',
        label: 'Title',
        sortable: false,
        type: 'field',
      },
      {
        name: 'totalAmount',
        label: 'Total amount',
        sortable: false,
        type: 'field',
      },
      {
        name: 'originalTotalAmount',
        label: 'Original total amount',
        sortable: false,
        type: 'field',
      },
      {
        name: 'revertedAmount',
        label: 'Reverted amount',
        sortable: false,
        type: 'field',
      },
      {
        name: 'feeTypeName',
        label: 'Fee type',
        sortable: false,
        type: 'field',
      },
      {
        name: 'entity',
        label: 'Entity',
        sortable: false,
        type: 'field',
      },
      {
        name: 'entityId',
        label: 'Entity Id',
        sortable: false,
        type: 'field',
      },
      {
        name: 'collectionDate',
        label: 'Collected at',
        sortable: false,
        type: 'field',
      },
      {
        name: 'status',
        label: 'Status',
        sortable: false,
        type: 'field',
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions',
      },
    ];
  }
}
