import AppStore from '../store/AppStore';
import { refreshAuthAction } from '../../modules/auth/AuthActions';
import Api from '../api/Api';

export default class Auth {
  static refreshTimeout: any;

  private static multipleTabsSession = false;

  static getAuthStorage(): Storage {
    if (this.multipleTabsSession) {
      return window.localStorage;
    }
    return window.sessionStorage;
  }

  static setAuthStorage(multipleTabsSession: boolean): void {
    this.multipleTabsSession = multipleTabsSession;
  }

  /**
   * Refresh auth
   */
  static async refreshAuth() {
    await AppStore.dispatch(refreshAuthAction());
  }

  // static async poolStatusUpdate() {
  //   if(this.refreshTimeout) {
  //     clearTimeout(this.refreshTimeout);
  //     this.refreshTimeout = null;
  //   }

  //   try {
  //     if(AppStore.getState().auth.token) {
  //       let response = await Api.call(
  //         'member/status-update',
  //       );

  //       if(response.success) {
  //         AppStore.dispatch(statusUpdateReceivedAction(response.data));
  //       }
  //     }
  //   } catch(e) {}

  //   this.refreshTimeout = setTimeout(() => {
  //     Auth.poolStatusUpdate();
  //   }, 5000);
  // }
}
