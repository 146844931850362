import { AppRoute } from '@launchpad';
import React from 'react';
import { Switch } from 'react-router-dom';
import MemberFilesList from './pages/membersFiles/MemberFilesList';
import MembersFileDetails from './pages/membersFiles/MembersFileDetails';
import PriorityPassDashboard from './pages/PriorityPassDashboard';
import VisitsFileDetails from './pages/visitsFiles/VisitsFileDetails';
import VisitsFilesList from './pages/visitsFiles/VisitsFilesList';

export default class PriorityPassNavigation extends React.PureComponent {
  render() {
    return (
      <Switch>
        <AppRoute
          path="/priority-pass/members-files/:id"
          component={MembersFileDetails}
        />
        <AppRoute
          path="/priority-pass/visits-files/:id"
          component={VisitsFileDetails}
        />
        <AppRoute
          path="/priority-pass/members-files"
          component={MemberFilesList}
        />
        <AppRoute
          path="/priority-pass/visits-files"
          component={VisitsFilesList}
        />
        <AppRoute path="/priority-pass" component={PriorityPassDashboard} />
      </Switch>
    );
  }
}
