import { AppConfig } from '@launchpad/types';

type ExtendedAppConfig = AppConfig & {
  modules: AppConfig['modules'] & {
    paymentDevice: {
      statuses?: { status: string; label: string }[];
      replacementStatuses?: { status: string; label: string }[];
    };
  };
  flags: {
    showAdditionalReferences?: boolean;
    showTransactBalances?: boolean;
    showPinReminder: boolean;
    showPinButton?: boolean;
    showSpentLimit?: boolean;
  };
};

const isTemenosVariant = process.env.REACT_APP_TEMENOS_VARIANT === 'true';

const getTemenosStatuses = () => [
  { status: 'ok', label: 'All Good' },
  { status: 'expired', label: 'Expired' },
  { status: 'pending_activation', label: 'Pending activation' },
  { status: 'stolen', label: 'Lost, Stolen (irreversible!)' },
  { status: 'restricted', label: 'Restricted' },
  { status: 'security_violation', label: 'Security Violation' },
  {
    status: 'cardholder_to_contact_issuer',
    label: 'Cardholder to contact issuer',
  },
  { status: 'closed', label: 'Closed (irreversible!)' },
  { status: 'voided', label: 'Voided (irreversible!)' },
  { status: 'capture', label: 'Capture' },
];

const getTemenosReplacementStatuses = () => [
  { status: '', label: '- do not change -' },
  { status: 'expired', label: 'Expired' },
  { status: 'stolen', label: 'Lost, Stolen (irreversible!)' },
  { status: 'restricted', label: 'Restricted' },
  { status: 'closed', label: 'Closed (irreversible!)' },
  { status: 'voided', label: 'Voided (irreversible!)' },
];

const config: ExtendedAppConfig = {
  env: process.env.REACT_APP_ENVIRONMENT || 'dev',
  url: process.env.REACT_APP_URL || 'http://localhost',
  authStrategy: 'refresh-token',
  doNotMergeModules: true,
  notesCharLength: 500,
  policiesEnabled: true,
  modules: {
    fee: {
      partialRefundEnabled: true,
    },
    user: {
      editDisabled: false,
      createDisabled: false,
      deleteDisabled: false,
    },
    paymentDevice: {
      statuses: isTemenosVariant ? getTemenosStatuses() : undefined,
      replacementStatuses: isTemenosVariant
        ? getTemenosReplacementStatuses()
        : undefined,
    },
  },
  flags: {
    showAdditionalReferences:
      process.env.REACT_APP_SHOW_ADDITIONAL_REFERENCES === 'true',
    showTransactBalances:
      process.env.REACT_APP_SHOW_TRANSACT_BALANCES === 'true',
    showPinButton: process.env.REACT_APP_TEMENOS_VARIANT === 'true',
    showSpentLimit: process.env.REACT_APP_TEMENOS_VARIANT === 'true',
    showPinReminder: false,
  },
  multipleTabsSession: process.env.REACT_APP_MULTIPLE_TABS_SESSION === 'true',
};

export default config;
