import BaseModel from './BaseModel';

export default class LimitTypesModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/limit-types?limitCategory=SPENDING_LIMIT&page=1&limit=100';
  }
}
