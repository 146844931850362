import BaseModel from '../../../logic/model/BaseModel';
import Api from '../../../logic/api/Api';

export default class ForgotPasswordModel extends BaseModel {
  getSubmitUrl() {
    return 'auth/password_reset';
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      email: {
        presence: {
          message: '^Please enter an email address'
        },
        email: {
          message: '^Please enter a valid email address'
        }
      }
    };
  }
}
