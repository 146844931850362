import { ModalDialog } from '@launchpad';
import AppTable from '@launchpad/components/crud/AppTable';
import DeleteButton from '@launchpad/components/crud/DeleteButton';
import RenderIfAllowed from '@launchpad/util/access-control/access-control.component';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OrganizationsModel from './models/OrganizationsModel';

interface HTMLInputElement extends HTMLElement {
  getContext(arg0: string): any;
  /**
   * Returns the value of the data at the cursor's current position.
   */
  value: string;
  history: any;
  justTable?: boolean;
}

export default class Organizations extends Component<HTMLInputElement, any> {
  _tableComponent: any;

  constructor(props: Readonly<HTMLInputElement>) {
    super(props);
    this.state = {};
  }

  onDeleteItem(id) {
    new OrganizationsModel()
      .delete(id)
      .then(() => {
        this._tableComponent._refresh();
      })
      .catch(() => {
        this.setState({
          showInfoModal: true,
          infoModalContent: 'You can not delete this organisation!',
        });
      });
  }

  render() {
    const inner = (
      <>
        <AppTable
          icon="building"
          title="Organisations"
          onInit={(tableComponent) => {
            this._tableComponent = tableComponent;
          }}
          headerActions={
            <RenderIfAllowed action="Admin:Organisation:Manage:*">
              <Link
                to="/organisations/add-new"
                className="button width-240"
                style={{ marginRight: '10px' }}
              >
                <i className="fa fa-fw fa-plus" />
                Add new
              </Link>
            </RenderIfAllowed>
          }
          filter={[
            {
              name: 'term',
              type: 'term',
            },
          ]}
          order={[
            {
              field: 'id',
              direction: 'desc',
            },
          ]}
          model={OrganizationsModel}
          getColumnActions={(member, index) => {
            return (
              <div>
                <Link
                  to={`/organisations/${member.id}`}
                  className="button-info"
                >
                  Details
                </Link>
                <RenderIfAllowed action="Admin:Organisation:Manage:*">
                  <DeleteButton
                    onConfirm={() => this.onDeleteItem(member.id)}
                    text={`Are you sure you wish to delete "${`${member.name}`}"?`}
                  />
                </RenderIfAllowed>
              </div>
            );
          }}
        />
        <ModalDialog
          show={this.state.showInfoModal}
          onClose={() => this.setState({ showInfoModal: false })}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <p style={{ textAlign: 'center', paddingBottom: 20 }}>
              {this.state.infoModalContent}
            </p>
            <button
              type="button"
              onClick={() => this.setState({ showInfoModal: false })}
              className="button-info"
            >
              OK
            </button>
          </div>
        </ModalDialog>
      </>
    );

    if (this.props.justTable) {
      return inner;
    }

    return (
      <div className="dashboard-management">
        <h1>
          <i className="fa fa-building" aria-hidden="true" /> Organisations
        </h1>
        {inner}
      </div>
    );
  }
}
