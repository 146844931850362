import BaseModel from '../../../logic/model/BaseModel';

export default class ChargeFeeFormModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'POST';
  }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'admin/fee-collections';
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      title: {
        presence: {
          allowEmpty: false,
          message: '^Please enter Fee title',
        },
      },
      feeId: {
        presence: {
          allowEmpty: false,
          message: '^Please select Fee type',
        },
      },
      amount: {
        presence: {
          allowEmpty: false,
          message: '^Please enter Fee amount',
        },
      },
    };
  }
}
