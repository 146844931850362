import React from 'react';

export default class ProgressBarComponent extends React.Component {
  constructor(props) {
    super(props);

    // this.state = {
    //   percentage: 45
    // }
  }

  render() {
    let props = {
      ...this.props
    };

    return (
      <div style={{ display: 'flex', flex: 1 }}>
        <ProgressBar
          percentage={this.props.percentage}
          // showProgressBar={this.props.showProgressBar}
          showProgressBar={true}
          {...props}
        />
      </div>
    );
  }
}

const ProgressBar = props => {
  return (
    <div
      className="progress-bar"
      style={{
        display: 'flex',
        flex: 1,
        margin: 0,
        backgroundColor: 'transparent'
      }}
    >
      <Filler percentage={props.percentage} />
    </div>
  );
};

const Filler = props => {
  return (
    <div
      className="filler"
      style={{
        display: 'flex',
        flex: 1,
        width: `${props.percentage}%`,
        margin: 0,
        backgroundColor: '#007bff'
      }}
    />
  );
};

ProgressBarComponent.defaultProps = {
  showProgressBar: true
};
