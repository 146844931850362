import ModalService from '../services/ModalService';
import store, { history } from '../store/AppStore';

interface ActionType {
  [key: string]: any;
}
const actions: ActionType = {};

export default class SCAService {
  static registerAction(action: any, actionName = '') {
    if (actionName) {
      actions[actionName] = action;
      return actionName;
    }
    const hash = this.generateHash();
    actions[hash] = action;
    return hash;
  }

  static getAction(actionName = '') {
    if (actionName && actions.actionName) {
      const foundAction = actions.actionName;
      delete actions.actionName;
      return foundAction;
    }
    if (Object.keys(actions).length > 0) {
      const foundAction = actions[Object.keys(actions)[0]];
      delete actions[Object.keys(actions)[0]];
      return foundAction;
    }
    return [];
  }

  static registerApiCall(action: any, actionName = '') {
    if (actionName) {
      actions[actionName] = action;
      return actionName;
    }
    const hash = this.generateHash();
    actions[hash] = action;
    return hash;
  }

  static getApiCall(actionName = '') {
    if (actionName && actions.actionName) {
      const foundAction = actions.actionName;
      delete actions.actionName;
      return foundAction;
    }
    if (Object.keys(actions).length > 0) {
      const foundAction = actions[Object.keys(actions)[0]];
      delete actions[Object.keys(actions)[0]];
      return foundAction;
    }
    return [];
  }

  static handleScaCode(url: string, apiCallId?: string, payload?: any) {
    // const urlMaps = [
    //   {
    //     url: 'consent/approve',
    //     function: store.dispatch(push('/sca-code-verification', { apiCallId }))
    //   }
    //   ...OverrideService.getConfig()[]
    // ];
    // TODO: check if user is authenticated
    const { token } = store.getState().auth;
    if (token) {
      ModalService.closeModal();

      if (payload.channel === 'device') {
        // Show wait for device to approve modal
        return ModalService.showModal('wait-for-sca-modal', {
          isAppModal: true,
          apiCallId,
          authorizationId: payload.authorizationId
        });
      }

      // Show modal
      console.warn('SHOW MODAL');
      return ModalService.showModal('2fa-verification-modal', {
        isAppModal: true,
        apiCallId
      });
    }
    // redirect to sca screen
    if (payload.channel === 'sca') {
      // Redirect to authorization pending screen
      return store.dispatch(
        // push('/wait-for-sca', {
        //   authorizationId: payload.authorizationId,
        //   apiCallId
        // })
        window.location.replace('/wait-for-sca')
      );
    }
    // TODO: handle sca base on url
    // return store.dispatch(push('/sca-code-verification', { apiCallId }));
    return history.push('/login/sca-code-verification', {
      payload
    });
  }

  // We do not need hash for now
  static generateHash() {
    return (
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15)
    );
  }
}
