import React from 'react';

import logo from '../../assets/img/logo.svg';
import cardFront from '../../assets/img/card-front.svg';

export const variants = {
  tnb: {
    logo,
    cardFront,
    loginText: (): React.ReactElement => (
      <h1>
        Welcome to TN Bank
        <span style={{ display: 'block', textAlign: 'center' }}>
          Administration
        </span>
      </h1>
    ),
  },
};

const currentVariant = process.env.REACT_APP_ADMIN_VARIANT || 'tnb';
let exportableVariant = variants[currentVariant];
if (!variants[currentVariant]) {
  exportableVariant = variants.tnb;
}
export const CurrentVariant = exportableVariant;
