import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  FormHandler, FormSubmitWrapper, InputField
} from '../../../index';
import { loginTriggerAction } from '../AuthActions';
import ChangePasswordModel from '../models/ChangePasswordModel';

class ChangePasswordPage extends Component<any, any> {
  formHandler: FormHandler;

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: Readonly<{}>) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new ChangePasswordModel({
        password: '',
        repeatedNewPassword: '',
      }),
      {
        redirect: false
      }
    );
  }

  onSubmitSuccess(data: {
    success: any;
    code: any;
    data: { token: any; account: any };
  }) {
    if (data.success) {
      this.props.history.push('/login');
    }

  }

  _getModalMessage(data: { success: any; message: any }) {
    if (!data.success) {
      return data.message;
    }

    if (data.success && !data.message) {
      return 'Password setup successful!';
    }
  }

  render() {
    // if (this.state.redirect) {
    //   return <Redirect to="/" />;
    // }
    const { formHandler } = this;
    return (
      <div className="public-form-holder">
        <h4>CHANGE PASSWORD</h4>
        <p>A password change is requested by the system.</p>
        <div className="form-wrapper">
          <FormSubmitWrapper
            formHandler={formHandler}
            buttonClass="gold-button"
            to="/"
            buttonText="Submit"
            noCancelButton
            getModalMessage={(data: { success: any; message: any }) =>
              this._getModalMessage(data)
            }
          >
            <div className="login-input-wrapper">
              <InputField
                className="input-group"
                materialProps={{
                  fullWidth: true,
                }}
                label="Enter new password"
                type="password"
                name="password"
                // placeholder={'Password'}
                InputLabelProps={{
                  shrink: true,
                }}
                value={this.state.record.password}
                handler={formHandler}
              />
              <InputField
                className="input-group"
                materialProps={{
                  fullWidth: true,
                }}
                label="Re-enter new password"
                type="password"
                name="repeatedNewPassword"
                // placeholder={'Password'}
                InputLabelProps={{
                  shrink: true,
                }}
                value={this.state.record.passwordConfirmation}
                handler={formHandler}
              />
            </div>
          </FormSubmitWrapper>
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}
          >
            <Link
              to="/login"
              style={{
                color: 'white',
                textDecoration: 'none',
                fontWeight: 'lighter',
                fontSize: 15
              }}
            >
              {' '}
              back to login{' '}
            </Link>
          </div>

          {/* <AppCheckboxInput
            name='remember'
            id='rememberField'
            htmlFor='rememberField'
            labelText='Remember me next time'
            value={this.state.remember}
            handler={this.formHandler}
          /> */}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (
  dispatch: (arg0: {
    type: string;
    payload: { token: any }; // Initial state
  }) => void
) => {
  return {
    userLoggedIn: (token: any, account: any) => {
      return dispatch(loginTriggerAction(token, account));
    }
  };
};

export default connect(null, mapDispatchToProps)(ChangePasswordPage);
