import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import {
  AppCheckboxInput,
  InputField,
  LoadingOverlay,
  ModalDialog,
} from '../../../../../@launchpad';
import AccountsModel from '../models/AccountsModel';

const ChangeForcedAvailableLimit = ({ account, onUpdate }) => {
  const [showModal, setShowModal] = useState(false);

  const onLimitChanged = () => {
    setShowModal(false);
    if (onUpdate) {
      onUpdate();
    }
  };
  return (
    <>
      <button
        type="button"
        className="btn btn-link"
        onClick={() => setShowModal(true)}
      >
        (change)
      </button>
      <ModalDialog
        show={showModal}
        onClose={() => setShowModal(false)}
        customContentStyle="account-forced-limit-modal"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <ChangeLimitForm
            account={account}
            onSave={() => onLimitChanged()}
            onCancel={() => setShowModal(false)}
          />
        </div>
      </ModalDialog>
    </>
  );
};

const ChangeLimitForm = ({ account, onCancel, onSave }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [limitRemoved, setLimitRemoved] = useState(
    [undefined, null].includes(account.forcedAvailableLimit)
  );
  const [forcedAvailableLimit, setForcedAvailableLimit] = useState(
    account.forcedAvailableLimit ?? 0
  );

  const submitData = async () => {
    try {
      setIsLoading(true);
      const response = await new AccountsModel().updateForcedAvailableLimit(
        account.id,
        limitRemoved ? -1 : Number(forcedAvailableLimit)
      );
      onSave();
    } catch (e) {
      console.error(
        `Error while updating forced available limit: ${e.message}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? <LoadingOverlay /> : null}
      <h3>Change the forced available limit</h3>
      <InputField
        materialProps={{
          fullWidth: true,
          disabled: limitRemoved,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        customInput={TextField}
        label="Forced available limit"
        type="number"
        name="forcedAvailableLimit"
        value={forcedAvailableLimit}
        onChange={(e) => setForcedAvailableLimit(e.target.value)}
      />
      <br />
      <AppCheckboxInput
        name="removeForcedAvailableLimit"
        value={limitRemoved}
        onChange={(e) => setLimitRemoved(e)}
        id="removeForcedAvailableLimit"
        htmlFor="removeForcedAvailableLimit"
        labelText="Remove forced available limit"
      />
      <div className="form-button-container">
        <button
          type="button"
          onClick={() => onCancel()}
          className="button-danger"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={() => submitData()}
          className="button-info"
        >
          Save
        </button>
      </div>
    </>
  );
};

export default ChangeForcedAvailableLimit;
