import { AppPage } from '@launchpad';
import React from 'react';
import { MembersFileTable } from '../../components';

const MemberFilesList = (props) => {
  return (
    <AppPage title="Uploaded members files" icon="list">
      <MembersFileTable />
    </AppPage>
  );
};

export default MemberFilesList;
