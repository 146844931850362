import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class LoadingOverlay extends React.Component<any, any> {
  render() {
    return (
      <div className="loading-overlay" style={this.props.style}>
        <CircularProgress className="circle-progress" size={50} />
      </div>
    );
  }
}
