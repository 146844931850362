import React, { Component } from 'react';
import { FormSubmitWrapper, FormHandler, InputField } from '@launchpad/index';
import AppSelect from '@launchpad/components/crud/AppSelect';
import AppSelectWithSearch from '@launchpad/components/crud/AppSelectWithSearch';
import AccountOwnershipModel from '../../models/AccountOwnershipModel';
import BreadcrumbHelper from '../../../../../../@launchpad/util/BreadcrumbHelper';

export default class AccountAddNewOwnership extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.formHandler = new FormHandler(
      this,
      new AccountOwnershipModel({
        id: props.match.params.id,
        userId: '',
        organisationId: '',
        ownershipType: '',
        permisionLevel: '',
      })
    );
    if (props.match.params.id) {
      BreadcrumbHelper.setCrumbs([
        {
          label: 'Account details',
          link: `/accounts/details/${props.match.params.id}`,
        },
      ]);

      BreadcrumbHelper.addCrumb(props.match.params.id);
    }
  }

  render() {
    const { formHandler } = this;
    const { accountNumber } = this.props.location.state;

    const toAccount = accountNumber || this.props.match.params.id;

    return (
      <div>
        <h1>Add new user to account: {toAccount}</h1>
        <div
          className="element-with-blur"
          style={{ paddingTop: '2px', marginRight: 20 }}
        >
          <div className="dark-blur" />
          <div className="box-header">
            <h2>Add new user to account</h2>
          </div>
          <div className="add-new-group" style={{ position: 'relative' }}>
            <div className="container">
              <FormSubmitWrapper
                formHandler={formHandler}
                to={`/accounts/details/${this.props.match.params.id}`}
              >
                <div className="row">
                  <div className="col-md-6">
                    <AppSelectWithSearch
                      url="admin/users"
                      label="User"
                      name="userId"
                      value={this.state.record.userId}
                      handler={formHandler}
                      placeholder="Search a user"
                      wrapperStyle={{ zIndex: 99999 }}
                      getOptionValue={(user) => user.id}
                      getOptionLabel={(user) =>
                        `${user.firstName} ${user.lastName} (${user.username}) (${user.customerReference})`
                      }
                    />
                    {/* <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Organisation id"
                      type="text"
                      name="organisationId"
                      value={this.state.record.organisationId}
                      handler={formHandler}
                    /> */}
                  </div>
                  <div className="col-md-6">
                    <AppSelect
                      wrapperStyle={{ zIndex: 99999 }}
                      textFieldProps={{
                        label: 'Ownership type',
                        InputLabelProps: {
                          shrink: true,
                        },
                      }}
                      options={AccountOwnershipModel.getOwnershipTypeOptions()}
                      name="ownershipType"
                      value={this.state.record.ownershipType}
                      getOptionValue={(item) => item.id}
                      getOptionLabel={(item) => item.name}
                      handler={formHandler}
                      placeholder="Select ownership type"
                    />

                    {/* <AppSelect
                      wrapperStyle={{ paddingTop: 15 }}
                      textFieldProps={{
                        label: 'Permission level',
                        InputLabelProps: {
                          shrink: true,
                        },
                      }}
                      options={AccountOwnershipModel.getPermissionLevelOptions()}
                      name="permisionLevel"
                      value={this.state.record.permisionLevel}
                      getOptionValue={(item) => item.id}
                      getOptionLabel={(item) => item.name}
                      handler={formHandler}
                      placeholder="Select permission level"
                    /> */}
                  </div>
                </div>
              </FormSubmitWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
