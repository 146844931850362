import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';
import React from 'react';
import AccountsNavigation from './AccountsNavigation';

export const NAME = 'accounts';

export default class AccountsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'accounts.*';
  }

  getPolicy() {
    return 'Admin:Account:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Accounts',
        icon: 'address-card',
        path: '/accounts',
        permission: 'accounts.*',
        policy: 'Admin:Account:View:*',
      },
    ];
  }

  getRoutes() {
    return [
      <AppRoute
        permission="accounts.*"
        policy="Admin:Account:View:*"
        path="/accounts"
        component={AccountsNavigation}
      />,
    ];
  }
}
