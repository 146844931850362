import React, { useEffect, useCallback, useState } from 'react';
import Api from '@launchpad/logic/api/Api';
import styled from 'styled-components';
import { date } from '@launchpad/util/DateHelper';
import DeleteIcon from '../crud/DeleteIcon';

const NotesList = styled.div`
  ul {
    list-style: none;
    padding: 20px 10px 0 10px;
  }
`;

const NoteItem = styled.div`
  position: relative;
  width: 100%;
  border-radius: 5px;
  background: white;
  padding: 20px;
  min-height: 90px;
  box-sizing: border-box;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
`;

const NoteItemTop = styled.div`
  position: absolute;
  top: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 40px;
`;

const NoteItemTopText = styled.p`
  color: gray;
  margin: 0;
  font-size: 14px;
  margin-right: 10px;
`;

const NoteText = styled.p`
  color: #222;
  font-size: 15px;
  margin: 10px 40px 0 0;
`;

export default function FetchNoteWidget(props) {
  const [notesData, setNotesData] = useState([]);
  const { headerTitle, handleAlertClick } = props;

  useEffect(() => {
    getNotes().catch(console.error);
  }, []);

  const getNotes = useCallback(async () => {
    const result = await Api.call(
      `admin/notes?entity=${props.entity.type}&entityId=${props.entity.id}`,
      'GET'
    );
    setNotesData(result.data);
  }, []);

  const deleteNote = useCallback(async id => {
    await Api.call(`admin/notes/${id}`, 'DELETE');
    getNotes();
  }, []);

  return (
    <div className="add-note">
      <div className="add-note-header">
        <p>{headerTitle}</p>
      </div>
      <NotesList>
        <ul>
          {!notesData ||
            (notesData.length === 0 && <span>There are no notes</span>)}
          {notesData &&
            notesData.length > 0 &&
            notesData.map(item => {
              return (
                <li key={item.id}>
                  <NoteItem>
                    <NoteItemTop>
                      <NoteItemTopText>
                        Created: {date(item.createdAt)}
                      </NoteItemTopText>
                        {item.reminder &&        
                          <NoteItemTopText>
                            Reminder: {date(item.reminder)}
                          </NoteItemTopText>
                        }
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <NoteItemTopText>
                          Created by: {item.userFirstName} {item.userLastName}
                        </NoteItemTopText>
                        <DeleteIcon
                          onConfirm={() => deleteNote(item.id)}
                          text="Are you sure you wish to delete note?"
                        />
                      </div>
                    </NoteItemTop>
                    <div>
                      <NoteText>{item.message}</NoteText>
                    </div>
                  </NoteItem>
                </li>
              );
            })}
        </ul>
      </NotesList>
    </div>
  );
}
