import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'users';

export default class UsersModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'user.*';
  }

  getPolicy() {
    return 'Admin:User:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Users',
        icon: 'users',
        path: null,
        permission: 'user.*',
        policy: 'Admin:User:View:*',
        children: [
          {
            label: 'Customers',
            path: '/users/customers',
            icon: 'building',
            permission: 'user.view',
            policy: 'Admin:User:View:*'
          },
          {
            label: 'Users',
            path: '/users/all-users',
            icon: 'users',
            permission: 'user.view',
            policy: 'Admin:User:View:*'
          },
          {
            label: 'User groups',
            path: '/user-groups',
            icon: 'file',
            permission: 'user.groups.*',
            policy: 'Admin:User:UserGroup:View:*'
          },
          {
            label: 'Roles',
            path: '/users/roles',
            icon: 'user-plus',
            permission: 'user.roles.*',
            policy: 'Admin:Role:View:*'
          },
          {
            label: 'Permissions',
            path: '/users/permissions',
            icon: 'key',
            permission: 'user.permissions.*',
            policy: 'Admin:Permission:View:*'
          },
          {
            label: 'User Devices',
            path: '/users/user-devices',
            icon: 'laptop',
            permission: 'user.devices.*',
            policy: 'Admin:User:UserDevice:View:*'
          },
          {
            label: 'Users Logs',
            path: '/users/users-logs',
            icon: 'file',
            permission: 'user.logs.*',
            policy: 'Admin:User:Log:View:*'
          }
        ]
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}users`}
        load={() => import('./UsersPage')}
      />
    ];
  }
}
