import { FormHandler, FormSubmitWrapper, InputField } from '@launchpad/index';
import Api from '@launchpad/logic/api/Api';
import ToastHelper from '@launchpad/util/ToastHelper';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import NotificationUserGroupModel from '../../models/NotificationUserGroupModel';
import SelectSpecificUsers from './SelectSpecificUsers';

class UserGroupForm extends Component {
  constructor(props) {
    super(props);

    let modelData = {
      id: this.props.id,
      name: '',
      // description: '',
      // filterType: 'specific_users',
      userIds: [],
      // filter: {
      //   createdFrom: '',
      //   createdTo: '',
      //   lastActivityFrom: '',
      //   lastActivityTo: '',
      //   balanceFrom: '',
      //   balanceTo: '',
      //   cardNotOrdered: null,
      //   cardNotActivated: null,
      //   lastTransactionFrom: '',
      //   lastTransactionTo: '',
      // },
    };

    if (props.record) {
      modelData = { ...modelData, ...props.record };
    }

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new NotificationUserGroupModel(modelData)
    );
  }

  async createUserGroup(data) {
    const { userIds, assignedUsers } = this.formHandler.state.record;
    let assignedUsersIds = userIds
    if((!userIds || userIds.length === 0) && assignedUsers){
      assignedUsersIds = assignedUsers.map(au => au.id)
    }
    if (assignedUsersIds.length) {
      const result = await Api.call(
        `admin/user-groups/${data.data.id}/users`,
        'PATCH',
        {
          userIds: assignedUsersIds,
        }
      );
      if (result.success) {
        if(this.props.record){
          ToastHelper.show('You have successfully updated user group', 'success');
          this.props.history.goBack();
        }else{
          this.props.history.replace('./');
        }
      }
    } 
  }

  render() {
    const { formHandler } = this;

    return (
      <div
        className="add-new-group"
        style={{ width: '100%', paddingBottom: '40px' }}
      >
        <FormSubmitWrapper
          formHandler={formHandler}
          to="/user-groups"
          onSuccess={(data) => this.createUserGroup(data)}
        >
          <div className="row">
            <div className="col-md-5">
              <h3>Basic Info</h3>
              <hr />
              <InputField
                materialProps={{
                  fullWidth: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                customInput={TextField}
                label="Name"
                type="text"
                name="name"
                value={this.state.record.name}
                handler={formHandler}
              />
              {/* <InputField
                materialProps={{
                  fullWidth: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                customInput={TextField}
                label="Description"
                type="text"
                name="description"
                value={this.state.record.description}
                handler={formHandler}
              /> */}
            </div>

            <div className="col-md-6 col-md-offset-1 user-group-filters">
              {/* <AppSelect
                textFieldProps={{
                  label: 'Filter users',
                  disabled: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                options={[
                  {
                    id: 'specific_users',
                    name: 'Specific users',
                  },
                ]}
                name="filterType"
                value={this.state.record.filterType}
                handler={formHandler}
                placeholder="-filter type-"
              /> */}
              <SelectSpecificUsers
                selectedUsers={this.state.record.assignedUsers}
                userIds={this.state.record.assignedUsers?.map(au => au.id)}
                onChange={(userIds) =>
                  this.formHandler.handleInputChange('userIds', userIds)
                }
              />
            </div>
          </div>
        </FormSubmitWrapper>
      </div>
    );
  }
}

export default withRouter(UserGroupForm);
