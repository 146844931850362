import React, { useState } from 'react';
import ModalDialog from './ModalDialog';

const AppButtonModal = props => {
  const [show, setShow] = useState(false);

  return (
    <div style={{ flex: 1, ...props.style }}>
      <button
        type="button"
        onClick={() => setShow(true)}
        className={[
          props.noBlock ? '' : 'btn-block',
          props.buttonClassName
        ].join(' ')}
      >
        {props.buttonText}
      </button>
      <ModalDialog
        show={show}
        onClose={() => setShow(false)}
        test="test"
        customContentStyle={props.customContentStyle}
      >
        {React.cloneElement(props.children, { close: () => setShow(false) })}
      </ModalDialog>
    </div>
  );
};

export default AppButtonModal;
