import React from 'react';
import { withRouter } from 'react-router';
import BulkLoadUsersModel from './models/BulkLoadUsersModel';
import AppUploadField from '../../../../../@launchpad/components/crud/AppUploadField';
import { FormHandler } from '../../../../../@launchpad/components';

class NewBulkLoad extends React.Component {
  constructor(props) {
    super(props);

    this.formHandler = new FormHandler(
      this,
      new BulkLoadUsersModel({
        name: '',
        bulkFile: '',
      })
    );
  }

  _onSuccess(data) {
    const load = data.data;
    return this.props.history.push(`/tools/bulk-load/details/${load.id}`);
  }

  render() {
    const { formHandler } = this;

    return (
      <div>
        <div
          style={{
            padding: '0 5% 5% 5%',
            textAlign: 'center',
            border: '1px solid transparent',
            paddingTop: 20,
          }}
        >
          {/* <FormSubmitWrapper
            buttonText="Upload"
            formHandler={formHandler}
            to="/tools/bulk-load"
            // noCancelButton
            onSuccess={data => this._onSuccess(data)}
          > */}
          {/* <InputField
            materialProps={{
              fullWidth: true
            }}
            InputLabelProps={{
              shrink: true
            }}
            customInput={TextField}
            label="Name"
            type="text"
            name="name"
            value={this.state.record.name}
            errors={formHandler.getErrors()}
            handler={formHandler}
          /> */}

          <AppUploadField
            label="*Upload USERS CSV file"
            name="bulkFile"
            handler={formHandler}
            submitUrl="admin/tnbank/import-users"
            errors={formHandler.getErrors()}
            accept=".csv"
            value={this.state.record.bulkFile}
            onFileUpload={(file, response) => {
              this.props.onUpload(response);
            }}
            onFileRemove={() => {
              this.props.onUpload(null);
            }}
          />

          <p>
            Example file can be downloaded{' '}
            <a
              href={require('./examples/customers_example.csv')}
              style={{ fontWeight: 'bold' }}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              here
            </a>
          </p>
          {/* </FormSubmitWrapper> */}
        </div>
      </div>
    );
  }
}

export default withRouter(NewBulkLoad);
