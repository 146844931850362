import { fork, all, put, takeEvery } from 'redux-saga/effects';
import AuthSagas from '../../modules/auth/AuthSagas';
import { APP_MOUNTED } from './AppActions';
import { MEMBER_TOKEN_LOADED } from '../../modules/auth/AuthActions';
import ConfigModel from '../model/ConfigModel';
import OverrideService from '@launchpad/logic/services/overrides/OverrideService';

/**
 * Logout user
 *
 * @param {Object} action
 */
function* appMounted(action) {
  try {
    yield OverrideService.getOverrides()['no-config-model'] ||
      ConfigModel.load();

    const token = window.localStorage.getItem('AUTH_TOKEN');

    if (token) {
      yield put({ type: MEMBER_TOKEN_LOADED, payload: token });
    }
  } catch (e) {
    console.log('Error:', e);
  }
}

export function* watchAppMounted() {
  yield takeEvery(APP_MOUNTED, appMounted);
}

export default function* allSagas() {
  yield all([fork(watchAppMounted), fork(AuthSagas)]);
}
