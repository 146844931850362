/* eslint-disable import/no-cycle */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable max-classes-per-file */
import { InputField } from '@launchpad/index';
import _ from 'lodash';
import React from 'react';
import UserModel from '../../models/UserModel';

export default class SelectSpecificUsers extends React.Component {
  /**
   * Constructor
   *
   * @param {object} props Props
   */
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    // console.log("props", props);
    this.state = {
      term: '',
      results: [],
      selectedIds: props.userIds ? props.userIds : [],
      selectedUsers: props.selectedUsers ? props.selectedUsers : [],
    };
  }

  /**
   * Call on component mount
   */
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    // this._pullSelectedUsers();
  }

  /**
   * Call on component unmount
   */
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Handle click outside the wrapper
   *
   * @param {object} event
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        term: '',
        results: [],
      });
    }
  }

  /**
   * Show filtered users
   */
  _showFilteredUsers() {
    const { results } = this.state;

    if (!results || !results.length) {
      return null;
    }

    return (
      <div className="filtered-users-holder">
        <ul className="filtered-results">
          {_.difference(results, this.state.selectedUsers, 'id').map(
            (user, index) => {
              return (
                <UserFilterItem
                  user={user}
                  index={index}
                  selectedIds={this.state.selectedIds}
                  onAdd={(id) => this._addUser(id, user)}
                  onRemove={(id) => this._removeUser(id, user)}
                />
              );
            }
          )}
        </ul>
      </div>
    );
  }

  /**
   * Show selected users
   */
  _showSelectedUsers() {
    const { selectedUsers } = this.state;

    if (!selectedUsers || !selectedUsers.length) {
      return null;
    }

    return (
      <div className="selected-users-holder dark-well">
        <p>Selected users:</p>
        <ul className="filtered-results">
          {selectedUsers.map((user, index) => {
            if (this.state.selectedIds.filter((x) => x == user.id).length == 0)
              return null;
            return (
              <UserFilterItem
                user={user}
                index={index}
                selectedIds={this.state.selectedIds}
                onAdd={(id) => this._addUser(id)}
                onRemove={(id) => this._removeUser(id)}
              />
            );
          })}
        </ul>
      </div>
    );
  }

  /**
   * Add user id
   *
   * @param {integer} userId User id
   */
  _addUser(userId, user) {
    if (!this.state.selectedIds.filter((x) => x == userId).length) {
      this.setState(
        {
          selectedIds: [...this.state.selectedIds, userId],
          selectedUsers: [...this.state.selectedUsers, user],
        },
        () => {
          this.props.onChange(this.state.selectedIds);
          // this._pullSelectedUsers();
        }
      );
    }
  }

  /**
   * Remove user id
   *
   * @param {integer} userId User id
   */
  _removeUser(userId) {
    this.setState(
      {
        selectedIds: this.state.selectedIds.filter((x) => x !== userId),
        selectedUsers: this.state.selectedUsers.filter((x) => x.id !== userId),
      },
      () => {
        this.props.onChange(this.state.selectedIds);
        // this._pullSelectedUsers();
      }
    );
  }

  /**
   * Pull selected users
   */
  async _pullSelectedUsers() {
    const results = await new UserModel().fetch({
      limit: 10000,
      filter: [
        {
          name: 'ids',
          field: 'id',
          value: this.state.selectedIds,
        },
      ],
    });

    this.setState({
      selectedUsers: results.data,
    });
  }

  /**
   * Changed search value - trigger filter
   *
   * @param {string} value Term
   */
  _changedSearch(value) {
    this.setState(
      {
        term: value,
      },
      () => {
        this._debounceFilter();
      }
    );
  }

  /**
   * Trigger filter
   */
  async _triggerFilter() {
    const { term } = this.state;

    // Term limit is 3
    if (!term) {
      this.setState({
        results: [],
      });
      return;
    }

    // Fetch new users
    const result = await new UserModel().fetch({
      value: this.state.term,
      limit: 5,
    });

    this.setState({
      results: result.data,
    });
  }

  /**
   * Debounce filter function
   */
  _debounceFilter = _.debounce(() => this._triggerFilter(), 200);

  /**
   * Render
   */
  render() {
    return (
      <div
        className="search-users dark-well"
        ref={(ref) => (this.wrapperRef = ref)}
      >
        <em>Search for users</em>
        <div className="filter-section" style={{paddingBottom: 13}}>
          <InputField
            materialProps={{
              fullWidth: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            autoComplete="off"
            onChange={(e) => this._changedSearch(e.target.value)}
            label=""
            type="text"
            name="name"
            value={this.state.term}
          />
          {this._showFilteredUsers()}
        </div>
        {this._showSelectedUsers()}
      </div>
    );
  }
}

SelectSpecificUsers.defaultProps = {
  onChange: () => {},
};

class UserFilterItem extends React.Component {
  /**
   * Render user filter item
   */
  render() {
    const { index, user, selectedIds, onAdd, onRemove } = this.props;

    return (
      <li key={index}>
        <div className="user-info">
          <h5 style={{marginBottom: 0}}>{user.fullName || `${user.firstName} ${user.lastName}`}</h5>
          <span>E-mail: {user.email}</span>
          <br />
          <span>ID: {user.id}</span>
        </div>
        <div className="user-actions" style={{marginBottom: 4}}>
          {selectedIds.filter((x) => x == user.id).length == 0 ? (
            <a
              href="javascript:void(0)"
              className="button button-danger"
              onClick={() => onAdd(user.id)}
            >
              Add
            </a>
          ) : (
            <a
              href="javascript:void(0)"
              className="button button-danger"
              onClick={() => onRemove(user.id)}
            >
              Remove
            </a>
          )}
        </div>
      </li>
    );
  }
}
