import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppTable from '@launchpad/components/crud/AppTable';
import DeleteButton from '@launchpad/components/crud/DeleteButton';
import AccountOwnershipModel from '../../models/AccountOwnershipModel';
import RenderIfAllowed from '../../../../../../@launchpad/util/access-control/access-control.component';

export default class AccountOwnership extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onDeleteOwnership(userId) {
    new AccountOwnershipModel()
      .delete(this.props.match.params.id, userId)
      .then(() => {
        this.props.onRefresh();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  /**
   * Render
   *
   * @returns {XML}
   */
  render() {
    const { id } = this.props.match.params;

    const { account } = this.props;

    return (
      <div className="dashboard-management">
        <AppTable
          icon="users"
          title="Account Ownership (additional users on account)"
          params={{
            id,
          }}
          filter={[
            {
              name: 'term',
              type: 'term',
            },
          ]}
          headerActions={
            <RenderIfAllowed action="Admin:Account:Manage:*">
              <Link
                to={{
                  pathname: `${this.props.account.id}/add-new-ownership`,
                  state: { accountNumber: account.accountNumber },
                }}
                className="button width-240"
                style={{ marginRight: '10px' }}
              >
                <i className="fa fa-fw fa-plus" />
                Add new
              </Link>
            </RenderIfAllowed>
          }
          model={AccountOwnershipModel}
          renderCell={(column, item, index) => {
            switch (column.name) {
              case 'userId':
                return (
                  <Link
                    to={`/user-details/${item.userId}`}
                    className="userCardLink"
                  >
                    {item.userId}
                  </Link>
                );
              case 'accountId':
                return (
                  <Link
                    to={`/accounts/details/${item.accountId}`}
                    className="userCardLink"
                  >
                    {item.accountId}
                  </Link>
                );
            }
          }}
          getColumnActions={(member, index) => {
            return (
              <div>
                <DeleteButton
                  onConfirm={() => this.onDeleteOwnership(member.userId)}
                  text={`Are you sure you want to delete account ownership "${`${member.userId}`}"?`}
                />
              </div>
            );
          }}
        />
      </div>
    );
  }
}

// Inject router
AccountOwnership.contextTypes = {
  router: PropTypes.object,
};
