import BaseModel from '@launchpad/logic/model/BaseModel';

export default class EditUserModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  getSubmitMethod() {
    return 'PATCH';
  }

  /**
   * Get submit url
   */
  getSubmitUrl(record) {
    return `admin/users/${record.id}`;
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      title: {
        presence: {
          allowEmpty: false,
          message: '^Please enter title',
        },
      },
      firstName: {
        presence: {
          allowEmpty: false,
          message: '^Please enter first name',
        },
      },
      lastName: {
        presence: {
          allowEmpty: false,
          message: '^Please enter last name',
        },
      },
      email: {
        presence: {
          allowEmpty: false,
          message: '^Please enter an email address',
        },
        email: {
          message: '^Please enter a valid email address',
        },
      },
      // mobileCountryCode: {
      //   presence: {
      //     allowEmpty: false,
      //     message: '^Please select country code'
      //   }
      // },
      // mobileShort: {
      //   presence: {
      //     allowEmpty: false,
      //     message: '^Please enter mobile number'
      //   }
      // },
      birthDate: {
        presence: {
          allowEmpty: false,
          message: '^Please enter birth date',
        },
      },
      addressOne: {
        presence: {
          allowEmpty: false,
          message: '^Please enter address line',
        },
      },
      city: {
        presence: {
          allowEmpty: false,
          message: '^Please enter city',
        },
      },
      postCode: {
        presence: {
          allowEmpty: false,
          message: '^Please enter postcode',
        },
      },
      countryCode: {
        presence: {
          allowEmpty: false,
          message: '^Please select country',
        },
      },
      status: {
        presence: {
          allowEmpty: false,
          message: '^Please select status',
        },
      },
      customerReference: {
        presence: {
          allowEmpty: false,
          message: '^Please enter a customer reference',
        },
      },
    };
  }

  customValidation(record) {
    const errors = [];

    if (!record.additionalData.isCorrespondenceOneTimeAddress) return [];

    const mandatoryFields = [
      'correspondenceOneTimeAddressCountry',
      'correspondenceOneTimeAddressCity',
      'correspondenceOneTimeAddressLine',
      'correspondenceOneTimeAddressPostcode',
    ];

    mandatoryFields.forEach((mandatoryField) => {
      if (
        !record.additionalData[mandatoryField] ||
        record.additionalData[mandatoryField].trim().length === 0
      ) {
        errors.push({
          field: `additionalData.${mandatoryField}`,
          message:
            mandatoryField === 'correspondenceOneTimeAddressLine'
              ? `Please enter the ${mandatoryField
                  .replace('correspondenceOneTime', '')
                  .replace(/([a-z])([A-Z])/g, '$1 $2')
                  .toLowerCase()}`
              : `Please enter the ${mandatoryField
                  .replace('correspondenceOneTimeAddress', '')
                  .toLowerCase()}`,
        });
      }
    });

    return errors;
  }
}
