import Api from '@launchpad/logic/api/Api';
import React, { useState, useEffect } from 'react';
import CardModel from '../modules/cards/models/CardModel';

type TransactBalancesResponse = {
  request: Record<string, unknown> | null;
  response: {
    declinedReason: string;
    balances: { type: string; currencyCode: string; amount: string }[];
  } | null;
};

const TransactBalances: React.FC<any> = ({
  paymentDeviceId,
  accountId,
}: {
  paymentDeviceId?: string;
  accountId?: string;
}) => {
  const [balanceRequest, setBalanceRequest] =
    useState<TransactBalancesResponse>({ request: null, response: null });
  const [transactBalancesMessage, setTransactBalancesMessage] = useState<
    string | null
  >(null);

  useEffect(() => {
    const getBalanceData = async (pdId?: string, aId?: string) => {
      try {
        const finalPaymentDeviceId =
          !pdId && aId
            ? await new CardModel().getInitialisedPaymentDeviceIdForAccount(aId)
            : pdId;

        if (!finalPaymentDeviceId) {
          return;
        }

        const url = `admin/temenos/temenos-balance/${finalPaymentDeviceId}`;
        const data = await Api.call(url, 'GET');
        setBalanceRequest({
          request:
            (data as { data?: TransactBalancesResponse }).data?.request ?? null,
          response:
            (data as { data?: TransactBalancesResponse }).data?.response ??
            null,
        });
      } catch (error) {
        setTransactBalancesMessage(String(error));
      }
    };

    getBalanceData(paymentDeviceId, accountId).catch(console.error);
  }, [paymentDeviceId, accountId]);

  return (
    <div style={{ marginTop: 10 }}>
      <h3>Transact balances</h3>
      <p>{transactBalancesMessage}</p>
      {balanceRequest?.response?.declinedReason && ( // This is the new line
        <p>
          Error received through API. Response:{' '}
          <pre style={{ color: 'white', background: 'black' }}>
            {JSON.stringify(balanceRequest?.response, null, 2)}
          </pre>
        </p>
      )}
      <dl>
        {balanceRequest?.response?.balances?.map((reference) => (
          <>
            <dt>
              {reference.type} ({reference.currencyCode}):
            </dt>
            <dd>{reference.amount || '-'}</dd>
          </>
        ))}
        {!balanceRequest ||
          (!balanceRequest?.response && !transactBalancesMessage && (
            <p>No Transact balances</p>
          ))}
      </dl>
      <hr />
    </div>
  );
};
export default TransactBalances;
