import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'chat';

export default class ChatModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'chat.*';
  }

  getPolicy() {
    return 'Admin:Chat:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Chat',
        icon: 'comments-o',
        path: '/chat',
        permission: 'chat.*',
        policy: 'Admin:Chat:View:*',
        totalChatUnreadMessagesIndicator: true
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute path={`${currentUrl}chat`} load={() => import('./Chat')} />
    ];
  }
}
