import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { date } from '../../../../util/DateHelper';
import AppBadge from '../../../../components/elements/icon/AppBadge';

class NoteBasicInfo extends Component {

  redirectToUserDetails(userId) {
    this.props.history.push(`/user-details/${userId}`);
  }

  _redirectToEntity(item){
    let path = null;

    switch (item.entity) {
      case 'USER':
        path = '/user-details/'
        break;
      case 'ACCOUNT':
        path = '/accounts/details/'
        break;
      case 'PAYMENT_DEVICE':
        path = '/cards/details/'
        break;
      case 'TRANSACTION':
        path = '/transaction/details/'
        break;
      default:
    }

    if(!path){
      return <span>{item.entityId}</span>
    }
    return (
          <Link
          className="button"
            to={`${path}${item.entityId}`}
          >
            {item.entityId}
          </Link>
      );
  }

  _renderStatus(item) {
    switch (item.status.toLowerCase()) {
      case 'active':
      case 'ok':
        return <AppBadge text="OK" icon="check" />;
      case 'deleted':
        return <AppBadge text="Deleted" icon="ban" type="danger" />;
      case 'pending':
        return (
          <AppBadge text="Pending" icon="clock-o" type="info" />
        );
      default:
        return (
          <AppBadge text="Unknown" icon="clock-o" type="info" />
        );
    }
  }

  render() {
    const { note } = this.props;

    return (
      <div className="main">
        <div className="dark-blur" />
        <div className="customers-info">

          <div className="customer-details">
          <div className="box-header marginless">
          <h2>
            <i className="fa fa-files-o" /> Note Summary
          </h2>
        </div>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <Link 
                  to={{
                    pathname: `/notes/edit-note/${note.id}`,
                    state:{note, fromDetails: true}

                  }}
                  style={{width: 100, marginRight: 20}}
                  className="button-info">
                  Edit
              </Link>
            </div>
          </div>

          <div className="customers-main">
          <div style={{ margin: '0 20px' }}>
            <h3>Basic Info</h3>
            <hr />
          </div>
          <div className="row">
            <div className="col">
              <div className="padded">
                <div className="row text-white">
                  <div className="col-lg-8">
                    <div style={{ marginBottom: 20 }}>
                      <dl>
                        <dt>Id:</dt>
                        <dd>{note.id}</dd>
                        <dt>Entity:</dt>
                        <dd>{note.entity.replaceAll('_', ' ')}</dd>
                        <dt>Entity id:</dt>
                        <dd>{this._redirectToEntity(note)}</dd>
                        <dt>Created:</dt>
                        <dd>
                          {date(note.createdAt, 'DD / MM / YYYY HH:mm')}
                        </dd>
                        <dt>Modified:</dt>
                        <dd>
                          {date(note.updatedAt, 'DD / MM / YYYY HH:mm')}
                        </dd>
                        <dt>Reminder:</dt>
                        <dd>
                          {note.reminder ? date(note.reminder, 'DD / MM / YYYY') : 'None'}
                        </dd>
                        <dt>Message:</dt>
                        <dd>{note.message}</dd>
                        <dt>Status:</dt>
                        <dd>{this._renderStatus(note)}</dd>
                        <dt>Created by user:</dt>
                        <dd>{`${note.userFirstName} ${note.userLastName}`}</dd>
                        <dt>User id:</dt>
                        <dd>
                          <button
                            type="button"
                            className="button"
                            onClick={() => {
                              if (note.userId) {
                                this.redirectToUserDetails(note.userId);
                              }
                            }}
                          >
                            {note.userId || '-'}
                          </button>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}


export default withRouter(NoteBasicInfo);
