import React from 'react';
import { AppPage, AppWidget } from '../../../../../@launchpad/components';
import BulkLoadResult from './components/BulkLoadResult';
import NewBulkLoad from './NewBulkLoad';

export default class BulkLoadDashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      result: null,
    };
  }

  render() {
    return (
      <AppPage title="Bulk Load" icon="plus">
        <div className="row">
          <div className="col-sm-4">
            <AppWidget title="New Load" icon="plus">
              <NewBulkLoad
                onUpload={(result) => {
                  this.setState({
                    result,
                  });
                }}
              />
            </AppWidget>
          </div>
          {this.state.result ? (
            <div className="col-sm-8">
              <BulkLoadResult result={this.state.result} />
            </div>
          ) : null}
        </div>
      </AppPage>
    );
  }
}
