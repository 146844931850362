import React, { Component } from 'react';
import Api from '@launchpad/logic/api/Api';
import AccountOwnership from './AccountOwnership';
import { Link } from 'react-router-dom';
import OrganisationInfoWidget from '../../../../components/OrganisationInfoWidget';

export default class AccountUserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      owner: {},
    };
  }

  componentDidMount() {
    this._getAllUsers();
  }

  async getRemoteUser(id) {
    const getUrl = `admin/users/${id}`;

    try {
      const result = await Api.call(getUrl, 'GET');

      return result.data;
      // eslint-disable-next-line no-empty
    } catch (e) {}

    return null;
  }

  async _getAllUsers() {
    const { userId, ownerId } = this.props;

    if (userId) {
      const user = await this.getRemoteUser(userId);
      this.setState({
        user,
      });
    }
    if (ownerId) {
      const owner = await this.getRemoteUser(ownerId);
      this.setState({
        owner,
      });
    }
  }

  render() {
    const { user } = this.state;
    let { owner } = this.state;
    owner = owner.id ? owner : user;
    // const kycStatus = owner?.kycStatus || '';
    // const newKycStatus = kycStatus.replace(/#|_/g, ' ');

    return (
      <div className="main">
        <div className="customers-info">
          <div className="dark-blur" />
          <div className="box-header marginless">
            <h2>
              <i className="fa fa-info" /> Ownership information
            </h2>
          </div>

          <div className="customers-main" style={{ marginBottom: 10 }}>
            <div className="row">
              <div className="col">
                <div className="padded">
                  <div className="row text-white">
                    <div style={{ marginBottom: 20 }} className="col-lg-4">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="m-0">User information</h4>
                        <div style={{ maxWidth: '200px', marginRight: 5 }}>
                          <Link
                            to={`/user-details/${user.id}`}
                            className="button-info btn-block"
                          >
                            Full User details
                          </Link>
                        </div>
                      </div>
                      <hr />
                      <dl>
                        <dt>User Id:</dt>
                        <dd>{user.id}</dd>
                        <dt>First name:</dt>
                        <dd>{user.firstName ? user.firstName : '-'}</dd>
                        <dt>Last name:</dt>
                        <dd>{user.lastName ? user.lastName : '-'}</dd>
                        <dt>E-mail address:</dt>
                        <dd>{user.email ? user.email : '-'}</dd>
                        <dt>Customer reference:</dt>
                        <dd>
                          {user.customerReference
                            ? user.customerReference
                            : '-'}
                        </dd>
                      </dl>
                    </div>
                    <div style={{ marginBottom: 20 }} className="col-lg-4">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="m-0">Owner information</h4>
                        <div style={{ maxWidth: '200px', marginRight: 5 }}>
                          <Link
                            to={`/user-details/${user.id}`}
                            className="button-info btn-block"
                          >
                            Full Owner details
                          </Link>
                        </div>
                      </div>
                      <hr />
                      <dl>
                        <dt>Owner Id:</dt>
                        <dd>{owner.id}</dd>
                        <dl>
                          <dt>First name:</dt>
                          <dd>{owner.firstName ? owner.firstName : '-'}</dd>
                          <dt>Last name:</dt>
                          <dd>{owner.lastName ? owner.lastName : '-'}</dd>
                          <dt>E-mail address:</dt>
                          <dd>{owner.email ? owner.email : '-'}</dd>
                          <dt>Customer reference:</dt>
                          <dd>
                            {owner.customerReference
                              ? owner.customerReference
                              : '-'}
                          </dd>
                        </dl>
                      </dl>
                    </div>
                    <div style={{ marginBottom: 20 }} className="col-lg-4">
                      <OrganisationInfoWidget
                        organisationId={this.props.account.organisationUuid}
                      />
                    </div>
                    {/* <div style={{ marginBottom: 20 }} className="col-lg-6">
                      <h4>KYC</h4>
                      <hr />
                      <dl>
                        <dt>Status:</dt>
                        <dd>{newKycStatus.toUpperCase()}</dd>
                      </dl>
                    </div>  */}
                  </div>
                  {/* <div className="row text-white">
                    <div style={{ marginBottom: 20 }} className="col-lg-6">
                      <h4>Address Info</h4>
                      <hr />
                      <dl>
                        <dt>Address Line One:</dt>
                        <dd>{owner.addressOne}</dd>
                        <dt>Address Line Two:</dt>
                        <dd>{owner.addressTwo}</dd>
                        <dt>City:</dt>
                        <dd>{owner.city}</dd>
                        <dt>Postcode:</dt>
                        <dd>{owner.postCode}</dd>
                        <dt>Country code:</dt>
                        <dd>{owner.countryCode}</dd>
                        <dt>Mobile:</dt>
                        <dd>{owner.mobile}</dd>
                      </dl>
                    </div>
                    {owner.id !== user.id ? (
                      <div style={{ marginBottom: 20 }} className="col-lg-6">
                        <h4>Additional user</h4>
                        <hr />
                        <dl>
                          <dt>Additional user id:</dt>
                          <dd>
                            <Link
                              to={`/user-details/${user.id}`}
                              className="button"
                            >
                              {user.id || '-'}
                            </Link>
                          </dd>
                        </dl>
                      </div>
                    ) : null}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <AccountOwnership account={this.props.account} {...this.props} />
      </div>
    );
  }
}
