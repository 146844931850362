import BaseModel from '@launchpad/logic/model/BaseModel';

export default class VisitFileRowModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod(): string {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl(params: any): any {
    return `admin/tnbank/priority-pass/visits-files/${params.id}/rows`;
  }

  /**
   * Get default table columns
   */
  getTableColumns(): any {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: false,
        type: 'field',
      },
      {
        name: 'name',
        label: 'Name',
        sortable: false,
        type: 'field',
      },
      {
        name: 'reference',
        label: 'Reference',
        sortable: false,
        type: 'field',
      },
      {
        name: 'memberNumber',
        label: 'Membership number',
        sortable: false,
        type: 'field',
      },
      {
        name: 'token',
        label: 'Token',
        sortable: false,
        type: 'field',
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions',
      },
    ];
  }
}
