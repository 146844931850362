import React from 'react';
import { Provider } from 'react-redux';
import Modal from 'react-modal';
import { v4 as uuidv4 } from 'uuid';
import { OverrideService } from './logic/services';
import store from './logic/store/AppStore';
import AppToastAlert from './components/elements/AppToastAlert';
import LandingScreen from './LandingScreen';

import 'bootstrap/dist/css/bootstrap.css';
import './styles/main.scss';
import 'bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import GlobalDebug from './GlobalDebug';
import Auth from './logic/services/AuthService';

// https://github.com/reactjs/react-modal/issues/632
if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

export interface LaunchPadAdminProps {
  configuration: any;
}

export default class LaunchPadAdmin extends React.Component<any, any> {
  constructor(props) {
    super(props);
    OverrideService.storeConfiguration(props.configuration);
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== 'development') {
      GlobalDebug(false);
    }

    Auth.setAuthStorage(
      this.props.configuration?.config?.multipleTabsSession || false
    );
    const uuid = Auth.getAuthStorage().getItem('uuid');
    if (!uuid) {
      Auth.getAuthStorage().setItem('uuid', uuidv4());
    }
  }

  render() {
    // if (!OverrideService.configuration) return null;

    return (
      <Provider store={store}>
        <LandingScreen />
        <AppToastAlert />
      </Provider>
    );
  }
}
