import Api from '@launchpad/logic/api/Api';
import ToastHelper from '@launchpad/util/ToastHelper';
import React, { useState } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { AppButton } from '../crud/AppButton';
import { OverrideService } from '../../logic/services';
import 'react-datepicker/dist/react-datepicker.css';

const NoteAlarmWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  input[type='text'] {
    background: transparent;
    border: 1px solid white;
    text-align: center;
    color: white;
    outline-color: rgba(255, 255, 255, 0);
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    font-size: 15px;
    // transition: all .3s linear;
    transition-duration: 0.22s;
    transition-property: outline-color;
  }
  input[type='text']:focus {
    outline-style: ridge;
    outline-color: rgba(255, 255, 255, 0.8);
  }
  input[type='text']:blur {
    outline-style: ridge;
    outline-color: rgba(255, 255, 255, 0);
  }
  #calendar-icon {
    position: absolute;
    top: 7px;
    left: 10px;
  }
`;

const TextArea = styled.textarea`
  max-height: 400px;
  font-size: 14px;
  padding: 5px 10px 5px 10px;
`;

export default function AddNoteWidget(props) {
  const [noteText, setNoteText] = useState('');
  const [alarmDate, setAlarmDate] = useState('');

  async function saveNote() {
    if (!noteText) {
      ToastHelper.show('Please write your note.');
      return;
    }
    const result = await Api.call(`admin/notes`, 'POST', {
      entity: props.entity.type,
      entityId: props.entity.id,
      message: noteText,
      reminder: alarmDate || undefined
    });
    if (result.success) {
      setNoteText('');
      setAlarmDate('');
      props.onNoteAdded();
    }
  }

  return (
    <div className="add-note">
      <div className="add-note-header">
        <p>Add new note</p>
      </div>
      <div className="form-field" style={{ padding: 10 }}>
        <TextArea
          placeholder="Write your note here"
          style={{ width: '100%', height: '100px', zIndex: 9999, borderColor: 'white' }}
          onChange={e => setNoteText(e.target.value)}
          value={noteText}
          maxLength={OverrideService.getConfig().notesCharLength || '250'}
        />
      </div>
      <NoteAlarmWrapper>
        {/* <AppCheckboxInput /> */}
        <p>Select reminder date (optional)</p>
        <div className="relative mb-5 d-flex flex-row align-items-center">
          <i className="fa fa-calendar" id='calendar-icon' aria-hidden="true" />
          <DatePicker
            selected={alarmDate !== '' && moment(alarmDate).toDate()}
            onChange={date =>
              setAlarmDate(new Date(moment(date).format('YYYY-MM-DD')).toISOString())
            }
            style={{ width: '100%', background: 'transparent' }}
            dateFormat="dd/MM/yyyy HH:mm"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            type="text"
            minDate={new Date()}
            value={alarmDate !== '' && moment(alarmDate).format('DD-MM-YYYY')}
            name="alarmDate"
            autoComplete='off'
          />
          {alarmDate !== '' &&
          <i className="fa fa-times fa-lg" aria-hidden="true" onClick={()=> setAlarmDate('')} style={{padding: 5, cursor: 'pointer', color: '#b74343'}} />
          }
        </div>
        <AppButton style={{ maxWidth: 150 }} onClick={saveNote}>
          SAVE
        </AppButton>
      </NoteAlarmWrapper>
    </div>
  );
}
