export enum ResourceEntity {
  ACCOUNT = 'ACCOUNT',
  PAYMENT_DEVICE = 'PAYMENT_DEVICE',
  STATEMENT = 'STATEMENT'
}

export enum PolicyEffect {
  ALLOW = 'ALLOW',
  DENY = 'DENY'
}

export type PolicyAction =
  | 'Admin:*'
  | 'Admin:User:Manage:*'
  | 'Admin:Organisation:Manage:*'
  | 'Admin:Account:Manage:*'
  | 'Admin:PaymentDevice:Manage:*'
  | 'Admin:LimitControl:Manage:*'
  | 'Admin:Fee:Manage:*'
  | 'Admin:Product:Manage:*'
  | 'Front:*'
  | 'Front:User:Manage:*'
  | 'Front:PaymentDevice:*'
  | 'Front:PaymentDevice:Manage:*'
  | 'Front:PaymentDevice:Manage:Controls:*'
  | 'Front:PaymentDevice:Manage:Controls:Lock:*'
  | 'Front:PaymentDevice:Manage:Controls:Unlock:*'
  | 'Front:PaymentDevice:Manage:Controls:ReportLost:*'
  | 'Front:PaymentDevice:Manage:Controls:Activate:*';
