import { AppBadge, LoadingOverlay } from '@launchpad/components';
import React, { Component } from 'react';
import AppTable from '../../components/crud/AppTable';
import DeleteButton from '../../components/crud/DeleteButton';
import ModalDialog from '../../components/modal/ModalDialog';
import { OverrideService } from '../../logic/services';
import { date } from '../../util/DateHelper';
import { currency } from '../../util/NumberHelper';
import ToastHelper from '../../util/ToastHelper';
import RenderIfAllowed from '../../util/access-control/access-control.component';
import FeeCollectionDetailsModal from './FeeCollectionDetailsModal';
import ChargeFeeForm from './components/ChargeFeeForm';
import RevertFeeFormModal from './components/RevertFeeFormModal';
import FeeCollectionModel from './models/FeeCollectionModel';

export default class FeeCollection extends Component {
  _tableComponent;

  constructor(props) {
    super(props);
    this.state = {
      showFeeCollectionDetails: false,
      showChargeFee: false,
      fee: null,
    };
  }

  onFeeCollectionDetailsClose() {
    this.setState(
      {
        showFeeCollectionDetails: false,
      },
      () => {
        this._tableComponent._refresh();
      }
    );
  }

  onChargeFeeClose() {
    this.setState(
      {
        showChargeFee: false,
      },
      () => {
        this._tableComponent._refresh();
      }
    );
  }

  onRetryFee(id) {
    new FeeCollectionModel()
      .retryFee(id)
      .then(() => {
        this._tableComponent._refresh();
      })
      .catch((err) => {
        this.setState(
          {
            showFeeCollectionDetails: false,
          },
          () => {
            ToastHelper.show(err.errors[0].message);
          }
        );
      });
  }

  onRevertFee(id, amount = null) {
    new FeeCollectionModel()
      .revertFee(id, amount)
      .then(() => {
        this._tableComponent._refresh();
      })
      .catch((err) => {
        this.setState(
          {
            showFeeCollectionDetails: false,
          },
          () => {
            ToastHelper.show(err.errors[0].message);
          }
        );
      });
  }

  openChargeFeeModal() {
    this.setState({
      showChargeFee: true,
    });
  }

  render() {
    let entity = this.props.account;
    let entityName = 'ACCOUNT';
    let redirectRoute = '';
    let filterByEntityAndEntityId = true;
    let headerButton = (
      <RenderIfAllowed action="Admin:FeeControl:Manage:*">
        <button
          type="button"
          onClick={() => this.openChargeFeeModal()}
          className="button width-240"
          style={{ marginRight: '10px' }}
        >
          Charge Fee
        </button>
      </RenderIfAllowed>
    );

    if (this.props.account) {
      entity = this.props.account;
      entityName = 'ACCOUNT';
      redirectRoute = `/accounts/details/${this.props.account.id}`;
      filterByEntityAndEntityId = false;
    }
    if (this.props.card) {
      entity = this.props.card;
      entityName = 'PAYMENT_DEVICE';
      redirectRoute = `/cards/details/${this.props.card.id}`;
      filterByEntityAndEntityId = false;
    }
    if (this.props.user) {
      entity = this.props.user;
      entityName = 'USER';
      redirectRoute = '';
      headerButton = null;
      filterByEntityAndEntityId = false;
    }

    const accountCurrency =
      this.props.account?.currency ?? this.props.card?.account?.currency;

    const revertFeeButton = (item) =>
      OverrideService.getConfig().modules?.fee?.partialRefundEnabled ? (
        <RevertFeeFormModal
          fee={item}
          onUpdate={() => {
            this._tableComponent._refresh();
          }}
        />
      ) : (
        <DeleteButton
          onConfirm={() => this.onRevertFee(item.id)}
          text={`Are you sure you wish to revert fee "${item.title}"?`}
          btnText="Revert"
        />
      );

    return (
      <div className="dashboard-management">
        {!entity?.id ? (
          <LoadingOverlay />
        ) : (
          <AppTable
            icon="money"
            title="Fee Collection"
            onInit={(tableComponent) => {
              this._tableComponent = tableComponent;
            }}
            headerActions={headerButton}
            params={{
              ...(filterByEntityAndEntityId
                ? {
                    entity: entityName,
                    entityId: entity?.id || undefined,
                  }
                : {}),
              accountId: this.props.account ? this.props.account.id : undefined,
              paymentDeviceId: this.props.card ? this.props.card.id : undefined,
              userId: this.props.user ? this.props.user.id : undefined,
            }}
            filter={[
              {
                name: 'term',
                type: 'term',
              },
            ]}
            renderCell={(column, item, index) => {
              switch (column.name) {
                case 'totalAmount':
                  return currency(item.totalAmount, accountCurrency);
                case 'revertedAmount':
                  return currency(item.revertedAmount, accountCurrency);
                case 'originalTotalAmount':
                  return currency(
                    item.originalTotalAmount,
                    item.originalCurrency
                  );
                case 'entity':
                  return item.entity.replaceAll('_', ' ');
                case 'collectionDate':
                  return date(item.collectionDate, 'DD / MMM / YYYY') || '-';
                case 'status':
                  return <AppBadge text={item.status} />;
                default:
              }
            }}
            model={FeeCollectionModel}
            getColumnActions={(item, index) => {
              return (
                <div>
                  <RenderIfAllowed action="Admin:FeeControl:Manage:*">
                    {item.status === 'FAILED' ? (
                      <DeleteButton
                        onConfirm={() => this.onRetryFee(item.id)}
                        text={`Are you sure you wish to retry fee "${item.title}"?`}
                        btnText="Retry"
                      />
                    ) : null}
                    {item.status === 'COLLECTED' || item.status === 'PARTIALLY_MANUALLY_REVERTED' ? revertFeeButton(item) : null}
                  </RenderIfAllowed>
                  <a
                    href="javascript:void(0)"
                    className="button-info"
                    onClick={() =>
                      this.setState({
                        showFeeCollectionDetails: true,
                        fee: item,
                      })
                    }
                  >
                    Details
                  </a>
                </div>
              );
            }}
          />
        )}
        <ModalDialog
          show={this.state.showFeeCollectionDetails}
          onClose={() => this.onFeeCollectionDetailsClose()}
        >
          <FeeCollectionDetailsModal
            fee={this.state.fee}
            entityCurrency={accountCurrency}
          />
        </ModalDialog>

        <RenderIfAllowed action="Admin:FeeControl:Manage:*">
          <ModalDialog
            show={this.state.showChargeFee}
            onClose={() => this.onChargeFeeClose()}
          >
            <ChargeFeeForm
              entityId={entity?.id}
              entity={entityName}
              redirectRoute={redirectRoute}
              customCloseFunction={() => this.onChargeFeeClose()}
              currency={accountCurrency}
            />
          </ModalDialog>
        </RenderIfAllowed>
      </div>
    );
  }
}
