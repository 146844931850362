import React from 'react';

export default class BottomWaveForLoadingPage extends React.Component<
  any,
  any
> {
  render() {
    return (
      <div className="svg-container">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="40%">
              <stop
                offset="0%"
                style={{ stopColor: '#0F5EF6', stopOpacity: 1 }}
              />
              <stop
                offset="30%"
                style={{ stopColor: '#0F5EF6', stopOpacity: 0.95 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: '#11BEFE', stopOpacity: 0.9 }}
              />
            </linearGradient>
          </defs>
          <path
            fill="url(#gradient)"
            fillOpacity="1"
            d="M0,0L80,5.3C160,11,320,21,480,69.3C640,117,800,203,960,208C1120,213,1280,139,1360,101.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          />
        </svg>
      </div>
    );
  }
}
