/* eslint-disable no-global-assign */
/* eslint-disable @typescript-eslint/no-empty-function */
const GlobalDebug = (function() {
  const savedConsole = console;
  /**
   * @param {boolean} debugOn
   * @param {boolean} suppressAll
   */
  return function(debugOn, suppressAll = undefined) {
    const suppress = suppressAll || false;
    if (debugOn === false) {
      // supress the default console functionality
      console = {};
      console.log = function() {};
      // supress all type of consoles
      if (suppress) {
        console.info = function() {};
        console.warn = function() {};
        console.error = function() {};
      } else {
        console.info = savedConsole.info;
        console.warn = savedConsole.warn;
        console.error = savedConsole.error;
      }
    } else {
      console = savedConsole;
    }
  };
})();

export default GlobalDebug;
