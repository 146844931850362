import { requestPermission } from '@launchpad/util/access-control/access-control.utils';
import appStore from '../store/AppStore';

export default class PermissionService {
  static hasPolicy(policy?: string): boolean {
    const { policies } = appStore.getState().auth;
    const { profile } = appStore.getState().auth;
    if (!profile) return false;

    if (!policies || policies.length === 0) return true;

    if (!policy) return true;

    return requestPermission(policies, [policy], undefined);
  }

  static hasPermission(permission: string) {
    const { permissions } = appStore.getState().auth;
    const { profile } = appStore.getState().auth;
    if (!profile) return false;

    if (profile.isSuperAdmin) return true;
    return this.checkPermissionSet(permission, permissions);
  }

  static checkPermissionSet(permission, grantedPermissions) {
    // If not returned by backend, enable all
    if (!grantedPermissions) {
      return true;
    }

    // Check if denied
    if (grantedPermissions.deny && grantedPermissions.deny.length > 0) {
      const result = this.checkPermissionList(
        permission,
        grantedPermissions.deny
      );

      if (result) {
        return false;
      }
    }

    if (grantedPermissions.allow && grantedPermissions.allow.length > 0) {
      const result = this.checkPermissionList(
        permission,
        grantedPermissions.allow
      );

      return !!result;
    }

    return true;
  }

  static checkPermissionList(permission, permissionList) {
    for (let i = 0; i < permissionList.length; i++) {
      if (this.matchPermission(permission, permissionList[i])) {
        return true;
      }
    }

    return false;
  }

  /**
   * Check if needle passes the permission
   * TODO: This could be done using regular expressions
   * @param needle
   * @param permission
   */
  static matchPermission(needle, permission) {
    if (permission === '*') return true;

    if (permission === needle) return true;

    const splits = needle.split('.');

    for (let i = splits.length; i > 0; i--) {
      const permissionToCheck = splits.slice(0, i).join('.');
      if (
        permissionToCheck === permission ||
        `${permissionToCheck}.*` === permission
      ) {
        return true;
      }
    }
    return false;
  }
}
