import Api from '@launchpad/logic/api/Api';
import BaseModel from '@launchpad/logic/model/BaseModel';

export default class AccountAllAvailableLimitsModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl(params) {
    return `admin/accounts/${params.id}/applied-limits`;
  }

  getSubmitMethod() {
    return 'POST';
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'limitInfo.id',
        label: 'Id',
        sortable: false,
        type: 'uuid',
      },
      {
        name: 'limitType',
        label: 'Limit type',
        sortable: false,
        type: 'field',
      },
      {
        name: 'limitInfo.name',
        label: 'Name',
        sortable: false,
        type: 'field',
      },
      {
        name: 'item.limitInfo.limitSource.type',
        label: 'Configured on',
        sortable: false,
        type: 'field',
      },
      {
        name: 'item.limitInfo.limitSource.id',
        label: 'Entity Id',
        sortable: false,
        type: 'uuid',
      },
      {
        name: 'availableLimit',
        label: 'Available Limit',
        sortable: false,
        type: 'field',
      },
      {
        name: 'limit',
        label: 'Limit',
        sortable: false,
        type: 'field',
      },
      // {
      //   name: null,
      //   label: 'Actions',
      //   sortable: false,
      //   type: 'actions',
      // },
    ];
  }
}
