import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Api from '@launchpad/logic/api/Api';
import { LoadingOverlay } from '@launchpad';
import CustomerBasicInfo from './tabs/CustomerBasicInfo';
import UserAccounts from './tabs/UserAccounts';
import UserCards from './tabs/UserCards';
import BreadcrumbHelper from '../../../../../@launchpad/util/BreadcrumbHelper';
import AppTabs from '../../../../../@launchpad/components/crud/tabs/AppTabs';
import CustomerNotesAndAlerts from '../../../../../@launchpad/modules/users/all-users/pages/details/tabs/CustomerNotesAndAlerts';
import StatementsDownloadList from '../../../../../@launchpad/modules/statements-download/StatementsDownloadList';
import FeeCollection from '../../../../../@launchpad/modules/fee/FeeCollection';
import UsersDevicesList from '../../../../../@launchpad/modules/users/user-devices/pages/list/UsersDevicesList';

export default class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      activeTab: 'basic',
      loading: false,
    };
  }

  componentDidMount() {
    this._getUserDetails();
  }

  getTabs(user) {
    const basicInfo = (
      <CustomerBasicInfo onRefresh={() => this._getUserDetails()} user={user} />
    );
    const allTabs = [
      {
        id: 'basic',
        icon: 'info',
        title: 'Basic Info',
        component: basicInfo,
      },
      {
        id: 'accounts',
        icon: 'address-card',
        title: 'Accounts',
        component: <UserAccounts user={user} {...this.props} />,
      },
      {
        id: 'cards',
        icon: 'credit-card',
        title: 'Cards',
        component: <UserCards user={user} {...this.props} />,
      },
      {
        id: 'fee-collection',
        icon: 'money',
        title: 'Fee Collection',
        component: <FeeCollection user={user} {...this.props} />,
      },
      {
        id: 'notes',
        icon: 'files-o',
        title: 'Notes',
        component: (
          <CustomerNotesAndAlerts entity={user} type="USER" {...this.props} />
        ),
      },
      {
        id: 'devices',
        icon: 'mobile',
        title: 'Devices',
        component: <UsersDevicesList user={user} {...this.props} />,
      },
      {
        id: 'statements',
        icon: 'file',
        title: 'Statements',
        component: <StatementsDownloadList user={user} {...this.props} />,
      },
    ];
    return allTabs;
  }

  async _getUserDetails() {
    this.setState({
      loading: true,
    });
    const { id } = this.props.match.params;
    const getUrl = `admin/users/${id}`;

    try {
      const result = await Api.call(getUrl, 'GET');
      this.setState({
        user: result.data,
        loading: false,
      });
    } catch (e) {}
  }

  _renderContent(userDetails) {
    if (userDetails && userDetails.firstName) {
      BreadcrumbHelper.setCrumbs([
        {
          label: `${userDetails.firstName} ${userDetails.lastName}`,
        },
      ]);
    }
    return (
      <div>
        <h1>
          <i className="fa fa-info" aria-hidden="true" /> User Info:
          <span className="ml-2">
            {userDetails.firstName} {userDetails.lastName}
          </span>
        </h1>
        {this.state.loading ? (
          <LoadingOverlay />
        ) : (
          <AppTabs
            onTabChange={(activeTab) => this.setState({ activeTab })}
            activeTab={this.state.activeTab}
            dontRememberTabs
            tabs={this.getTabs(userDetails)}
          />
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="dashboard-management">
        {this._renderContent(this.state.user)}
      </div>
    );
  }
}

// Inject router
UserDetails.contextTypes = {
  router: PropTypes.object,
};
