import BaseModel from '@launchpad/logic/model/BaseModel';

export default class AssignUserToAccountModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  getSubmitMethod() {
    return 'PATCH';
  }

  /**
   * Get submit url
   */
  getSubmitUrl(record) {
    return `admin/accounts/${record.id}/assign`;
  }

  customValidation(record) {
    const errors = [];

    const { userId, ownerId, organisationId } = record;

    if (userId === '' && ownerId === '' && organisationId === '') {
      errors.push({
        field: 'userId',
        message: 'Please enter user id',
      });
    }

    return errors;
  }
}
