import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'vendor-calls-logs';

export default class VendorCallsLogsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'vendor-calls-logs.*';
  }

  getPolicy() {
    return 'Admin:VendorCallsLogs:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Vendor calls logs',
        icon: 'magnet',
        path: '/vendor-calls-logs',
        permission: 'vendor-calls-logs.*',
        policy: 'Admin:VendorCallsLogs:View:*'
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}vendor-calls-logs`}
        load={() => import('./VendorCallsLogsNavigation')}
      />
    ];
  }
}
