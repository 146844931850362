import AppTable from '@launchpad/components/crud/AppTable';
import DeleteButton from '@launchpad/components/crud/DeleteButton';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserGroupUserModel from './models/UserGroupUserModel';

export default class UserGroupsList extends Component {
  _delete(id) {
    new UserGroupUserModel()
      .delete(id)
      .then(() => {
        this._tableComponent._refresh();
      })
      .catch(() => {})
      .finally(() => {
        this._tableComponent._refresh();
      });
  }

  render() {
    return (
      <div className="inner-single-col">
        <div className="customer-groups">
          <h1>
            <i className="fa fa-users" aria-hidden="true" /> User Groups
          </h1>
          <AppTable
            title="User Groups"
            onInit={(tableComponent) => (this._tableComponent = tableComponent)}
            headerActions={
              <Link
                to="/user-groups/add"
                className="button width-240"
                style={{ marginRight: '10px' }}
              >
                <i className="fa fa-fw fa-plus" />
                Add new
              </Link>
            }
            // params={{ page: 0, limit: 20 }}
            filter={[
              {
                name: 'term',
                type: 'term',
              },
            ]}
            // order={[
            //   {
            //     field: 'id',
            //     direction: 'desc',
            //   },
            // ]}
            model={UserGroupUserModel}
            renderCell={(column, item, index) => {
              switch (column.name) {
                case 'kycStatus':
                  return this._renderStatus(item);
              }
            }}
            getColumnActions={(userGroup, index) => {
              return (
                <div>
                  <Link
                    to={`/user-groups/edit/${userGroup.id}`}
                    className="button-info"
                  >
                    Edit
                  </Link>
                  {/* <Link
                    to={`/user-groups/details/${userGroup.id}`}
                    className="button-info"
                  >
                    Details
                  </Link> */}
                  <DeleteButton
                    onConfirm={() => this._delete(userGroup.id)}
                    text={`Are you sure you wish to delete user group "${userGroup.name}"?`}
                  />
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  }
}
