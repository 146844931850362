import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import {
  AppCheckboxInput,
  InputField,
  LoadingOverlay,
  ModalDialog,
} from '../../..';
import ToastHelper from '../../../util/ToastHelper';
import FeeCollectionModel from '../models/FeeCollectionModel';

const RevertFeeFormModal = ({ fee, onUpdate }) => {
  const [showModal, setShowModal] = useState(false);

  const onFeeReverted = () => {
    setShowModal(false);
    if (onUpdate) {
      onUpdate();
    }
  };
  return (
    <>
      <button
        type="button"
        className="btn button-danger"
        onClick={() => setShowModal(true)}
      >
        Revert
      </button>
      <ModalDialog
        show={showModal}
        onClose={() => setShowModal(false)}
        customContentStyle="account-forced-limit-modal"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <RevertFeeForm
            fee={fee}
            onSave={() => onFeeReverted()}
            onCancel={() => setShowModal(false)}
          />
        </div>
      </ModalDialog>
    </>
  );
};

const RevertFeeForm = ({ fee, onCancel, onSave }) => {
  const remainingFeeAmount = Math.max(0, Number(fee.totalAmount) - Number(fee.revertedAmount));
  const [isLoading, setIsLoading] = useState(false);
  const [fullAmount, setFullAmount] = useState(true);
  const [amount, setAmount] = useState(remainingFeeAmount);

  const submitData = async () => {
    try {
      if (amount && Number(amount) > remainingFeeAmount) {
        throw new Error('Amount to revert cannot be greater than total amount or remaining unreverted amount');
      }

      setIsLoading(true);
      const numbericAmount = Number(amount);
      await new FeeCollectionModel().revertFee(
        fee.id,
        !fullAmount && numbericAmount !== 0 ? amount : null
      );
      onSave();
    } catch (e) {
      console.error(`Error while reverting fee: ${e.message}`);
      ToastHelper.show(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? <LoadingOverlay /> : null}
      <h3>Revert the fee</h3>
      <InputField
        materialProps={{
          fullWidth: true,
          disabled: fullAmount,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        customInput={TextField}
        label="Amount to revert"
        type="number"
        name="amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      <br />
      <AppCheckboxInput
        name="revertFullAmount"
        value={fullAmount}
        onChange={(e) => setFullAmount(e)}
        id="revertFullAmount"
        htmlFor="revertFullAmount"
        labelText="Revert full amount"
      />
      <div className="form-button-container">
        <button
          type="button"
          onClick={() => onCancel()}
          className="button-danger"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={() => submitData()}
          className="button-info"
        >
          Revert
        </button>
      </div>
    </>
  );
};

export default RevertFeeFormModal;
