import Api from '@launchpad/logic/api/Api';
import BaseModel from '@launchpad/logic/model/BaseModel';

export default class OrganizationsModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/organisations';
  }

  /**
   * Get details url
   */
  getDetailsUrl(id) {
    return `admin/organisations/${id}`;
  }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'admin/organisations';
  }

  /**
   * Get delete url
   */
  getDeleteUrl(id) {
    return `admin/organisations/${id}`;
  }

  async delete(id) {
    const result = await Api.call(this.getDeleteUrl(id), 'DELETE');
    return result;
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: false,
        type: 'field',
      },
      {
        name: 'name',
        label: 'Name',
        sortable: false,
        type: 'field',
      },
      {
        name: 'registrationNumber',
        label: 'Registration number',
        sortable: false,
        type: 'field',
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions',
      },
    ];
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      name: {
        presence: {
          allowEmpty: false,
          message: '^Please enter name',
        },
      },
      registrationNumber: {
        presence: {
          allowEmpty: false,
          message: '^Please enter registration number',
        },
      },
      taxNumber: {
        presence: {
          allowEmpty: false,
          message: '^Please enter tax number',
        },
      },
      addressLineOne: {
        presence: {
          allowEmpty: false,
          message: '^Please enter address line',
        },
      },
      city: {
        presence: {
          allowEmpty: false,
          message: '^Please enter city',
        },
      },
      postcode: {
        presence: {
          allowEmpty: false,
          message: '^Please enter postcode',
        },
      },
      country: {
        presence: {
          allowEmpty: false,
          message: '^Please select country',
        },
      },
    };
  }
}
