import Api from '@launchpad/logic/api/Api';
import BaseModel from '@launchpad/logic/model/BaseModel';

export default class UserGroupUserModel extends BaseModel {
  getDetailsUrl() {
    return 'notification-user-group/details';
  }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'admin/user-groups';
  }

  getMethod() {
    return 'GET';
  }

  getListUrl() {
    return 'admin/user-groups';
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: true,
        type: 'field',
      },
      {
        name: 'name',
        label: 'Name',
        sortable: true,
        type: 'field',
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions',
      },
    ];
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      name: {
        presence: {
          allowEmpty: false,
          message: '^Please enter name',
        },
      },
      // description: {
      //   presence: {
      //     allowEmpty: false,
      //     message: '^Please enter description',
      //   },
      // },
    };
  }

  getDeleteUrl(id) {
    return `admin/user-groups/${id}`;
  }

  async delete(id) {
    const result = await Api.call(this.getDeleteUrl(id), 'DELETE');
    return result;
  }
}
