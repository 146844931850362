import Api from '@launchpad/logic/api/Api';
import BaseModel from '@launchpad/logic/model/BaseModel';

export default class CardModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/payment-devices';
  }

  /**
   * Get lock card url
   */
  getLockCardUrl(id) {
    return `admin/payment-devices/${id}/lock`;
  }

  async lockCard(id) {
    const result = await Api.call(this.getLockCardUrl(id), 'POST');
    return result;
  }

  /**
   * Get unlock card url
   */
  getUnlockCardUrl(id) {
    return `admin/payment-devices/${id}/unlock`;
  }

  async unlockCard(id) {
    const result = await Api.call(this.getUnlockCardUrl(id), 'POST');
    return result;
  }

  /**
   * Change Card Status
   */

  async changeCardStatus(id, status) {
    const response = await Api.call(
      `admin/payment-devices/${id}/change-status`,
      'POST',
      {
        status,
      }
    );

    return response.success;
  }

  async updateAdditionalData(id, chInfo) {
    const response = await Api.call(
      `admin/payment-devices/${id}/additional-data`,
      'POST',
      {
        chInfo,
      }
    );

    return response.success;
  }

  async getPaymentDevicesForAccountId(accountId) {
    const response = await Api.call(
      `admin/payment-devices?accountId=${accountId}`,
      'GET',
      {
        accountId,
      }
    );

    return response.data;
  }

  async getInitialisedPaymentDeviceIdForAccount(accountId) {
    const paymentDevices = await this.getPaymentDevicesForAccountId(accountId);

    const filteredCards = paymentDevices.filter(
      (x) => x.status != 'pending_initialisation'
    );

    if (!filteredCards || filteredCards.length === 0) {
      throw new Error('No initialised payment devices found for account');
    }

    return filteredCards[0].id;
  }

  /**
   * Get details url
   */
  // getDetailsUrl() {
  //   return 'cards/details';
  // }

  /**
   * Get submit url
   */
  // getSubmitUrl() {
  //   return 'cards/order';
  // }

  /**
   * Get download url
   */
  // getDownloadUrl() {
  //   return 'cards/list/download';
  // }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: false,
        type: 'field',
      },
      {
        name: 'type',
        label: 'Type',
        sortable: false,
        type: 'field',
      },
      {
        name: 'userId',
        label: 'User',
        sortable: false,
        type: 'field',
      },
      {
        name: 'pan',
        label: 'PAN',
        sortable: false,
        type: 'field',
      },
      {
        name: 'availableLimit',
        label: 'Available spending limit',
        sortable: false,
        type: 'field',
      },
      {
        name: 'product.name',
        label: 'Limit group',
        sortable: false,
        type: 'field',
      },
      {
        name: 'expiryDate',
        label: 'Expires',
        sortable: false,
        type: 'field',
      },
      {
        name: 'status',
        label: 'Status',
        sortable: false,
        type: 'field',
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions',
      },
    ];
  }

  /**
   * Get transaction of the card
   *
   * @param {Integer} id Id of the card
   */
  async getTransaction(id) {
    const result = await Api.call('transaction/list', {
      id,
    });

    return result.data;
  }

  /**
   * Change freeze status
   *
   * @param {Object} card Card to change freeze status
   * @param {Bool} status Frozen or not
   */
  async changeFreezeStatus(card, status) {
    const response = await this.freezeCard(card.id, status);

    if (response.success) {
      return true;
    }

    return false;
  }

  async freezeCard(id, status) {
    const response = await Api.call('card/lock-unlock', {
      id,
      locked: !!status,
    });

    return response;
  }

  /**
   * Change stolen status
   *
   * @param {Object} card Card to change stolen status
   * @param {Bool} status Stolen or not
   */
  async changeStolenStatus(card, status) {
    const response = await this.stolenCard(card.id, status);

    if (response.success) {
      return true;
    }

    return false;
  }

  async regenerateCard(card, status) {
    const response = await Api.call('card/regenerate', {
      id: card.id,
      status,
    });

    if (response.success) {
      return response.data;
    }

    return response.data;
  }

  /**
   * Marks card as stolen
   *
   * @param {integer} id Id of the card
   */
  async stolenCard(id) {
    const response = await Api.call('card/report-stolen', {
      id,
    });

    return response;
  }

  // async update3DS(id) {
  //   const response = await Api.call('payment-device/update-3ds', { id });
  //   return response.success;
  // }

  async update3DS(id) {
    const response = await Api.call(
      `admin/payment-devices/${id}/3ds/configure`,
      'POST',
      {
        action: 'Update',
        credentials: [
          {
            type: 'OTPSMS',
          },
        ],
      }
    );

    return response.success;
  }

  async remove3DS(id) {
    const response = await Api.call(
      `admin/payment-devices/${id}/3ds/configure`,
      'POST',
      {
        action: 'Delete',
        credentials: [
          {
            type: 'OTPSMS',
          },
        ],
      }
    );

    return response.success;
  }

  async activateCard(id, secret) {
    const response = await Api.call(
      `admin/payment-devices/${id}/activate`,
      'POST',
      {
        secret,
      }
    );
    return response.success;
  }

  async sendPinReminder(id) {
    const response = await Api.call(
      `admin/payment-devices/${id}/pin-reminder`,
      'POST'
    );

    return response.success;
  }

  async getPin(id) {
    const response = await Api.call(`admin/payment-devices/${id}/pin`, 'GET');

    return response.data;
  }

  async initialise(cardId) {
    const response = await Api.call(
      `admin/payment-devices/${cardId}/initialise`,
      'POST'
    );

    return response.success;
  }

  // async remove3DS(id) {
  //   const response = await Api.call('payment-device/remove-3ds', { id });
  //   return response.success;
  // }

  static canBeActivated(card) {
    return (
      card &&
      !card.isLive &&
      ![
        'stolen',
        'voided',
        'destroyed',
        'refunded',
        'closed',
        'pending_initialisation',
      ].includes(card.status)
    );
  }

  static canBeReplaced(card) {
    // if (
    //   [
    //     'ok',
    //     'locked',
    //     'expired',
    //     'inactive',
    //     'pending_activation',
    //     'lost',
    //     'stolen',
    //     'restricted',
    //     'security_violation',
    //     'cardholder_to_contact_issuer',
    //     'closed',
    //     'refunded',
    //     'voided',
    //   ].includes(card.status)
    // )
    //   return null;

    if (card.status === 'pending_initialisation') {
      return null;
    }

    return card && !card.replacedById;
  }

  static canShowPin(card) {
    return card && card.status !== 'pending_initialisation';
  }

  static canBeInitialised(card) {
    return card && card.status === 'pending_initialisation';
  }

  static getStatusesInvalidForActivation() {}
}
