/* eslint-disable react/require-default-props */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { OverrideService } from '../../logic/services';

interface IProps {
  isLoading?: boolean;
  customstyle?: any;
  onClick?: () => void;
  disabled?: boolean;
}

export class AppButton extends React.Component<IProps> {
  _getButtonContent() {
    if (this.props.isLoading) {
      return <CircularProgress className="circle-progress" size={20} />;
    }

    return this.props.children;
  }

  render() {
    let styleClass = 'custom-btn';

    if (this.props.customstyle) {
      styleClass = this.props.customstyle;
    }

    // change cursor style if disabled
    if (this.props.disabled) {
      styleClass += ' disabled';
    }

    return (
      <Button variant="contained" className={styleClass} {...this.props}>
        {this._getButtonContent()}
      </Button>
    );
  }
}

// export default AppButton;
export default OverrideService.thisOrOverridenComponent(AppButton, 'ui.button');
