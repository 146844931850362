import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import ModalReducers from '../../modules/modals/ModalReducers';
import AuthReducers from '../../modules/auth/AuthReducers';
import {
  MEMBER_LOGIN_TRIGGER,
  MEMBER_TOKEN_LOADED
} from '../../modules/auth/AuthActions';
import { APP_MOUNTED, SHOULD_SHOW_IS_LANDING } from './AppActions';

function network(state = null, action) {
  let newState = {
    error: null
  };

  switch (action.type) {
    case 'NETWORK_ERROR':
      newState.error = action.payload.message;
      break;
    default:
  }
  return newState;
}
function ready(state = false, action) {
  switch (action.type) {
    case MEMBER_LOGIN_TRIGGER:
      return true;
    case MEMBER_TOKEN_LOADED:
      return true;
    case APP_MOUNTED:
      return true;
    default:
      return state;
  }
}

function isLanding(state = false, action) {
  switch (action.type) {
    case SHOULD_SHOW_IS_LANDING:
      return action.payload;
    default:
      return state;
  }
}

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    // ready: ready,
    // network: network,
    modals: ModalReducers,
    // auth: AuthReducers,
    // paymentDevices: CardReducers,
    // wallets: WalletReducers,
    ready,
    isLanding,
    network,
    auth: AuthReducers
  });

export default createRootReducer;
