import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';
import AnalyticsTotals from './pages/totals/AnalyticsTotals';
import AnalyticsAndroid from './pages/android/AnalyticsAndroid';
import AnalyticsApple from './pages/apple/AnalyticsApple';

export const NAME = 'contentManagement';

export default class AnalyticsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'analytics.*';
  }

  getPolicy() {
    return 'Admin:Analytics:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Analytics',
        icon: 'line-chart',
        path: null,
        policy: 'Admin:Analytics:View:*',
        children: [
          {
            label: 'Totals',
            path: '/analytics/totals',
            icon: 'pie-chart'
          },
          {
            label: 'Android',
            path: '/analytics/android',
            icon: 'android'
          },
          {
            label: 'Apple',
            path: '/analytics/apple',
            icon: 'apple'
          }
        ]
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute path="/analytics/totals" component={AnalyticsTotals} />,
      <AppRoute path="/analytics/android" component={AnalyticsAndroid} />,
      <AppRoute path="/analytics/apple" component={AnalyticsApple} />
    ];
  }
}
