import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import AppSelect from '@launchpad/components/crud/AppSelect';
import RolesModel from '@launchpad/modules/users/roles/models/RolesModel';
import { FormSubmitWrapper, FormHandler } from '@launchpad/index';
import OrganisationMembersModel from './models/OrganisationMembersModel';
import AppSelectWithSearch from '@launchpad/components/crud/AppSelectWithSearch';

export default class AddOrganisationMember extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.formHandler = new FormHandler(
      this,
      new OrganisationMembersModel({
        organisationId: props.match.params.id,
        userId: '',
        roleId: '',
      })
    );
  }

  // getSubmitData() {
  //   return {
  //     ...this.state.record
  //     // configuration: {
  //     //   amount: this.state.record.amount,
  //     //   currency:
  //     //     this.state.entity.currency || this.state.entity.account.currency
  //     // }
  //   };
  // }

  render() {
    const { formHandler } = this;

    return (
      <div>
        <h1>Add user to organisation</h1>
        <div
          className="element-with-blur"
          style={{ paddingTop: '2px', marginRight: 20 }}
        >
          <div className="dark-blur" />
          <div className="box-header">
            <h2>Add user to organisation</h2>
          </div>
          <div className="add-new-group" style={{ position: 'relative' }}>
            <div className="container">
              <FormSubmitWrapper
                formHandler={formHandler}
                to={`/organisations/${this.props.match.params.id}`}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-wrap">
                      <AppSelectWithSearch
                        url="admin/users"
                        label="User"
                        name="userId"
                        value={this.state.record.userId}
                        handler={formHandler}
                        placeholder="Search a user"
                        wrapperStyle={{ zIndex: 99999 }}
                        getOptionValue={(user) => user.id}
                        getOptionLabel={(user) =>
                          `${user.firstName} ${user.lastName} (${user.username}) (${user.customerReference})`
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-wrap">
                      <AppSelect
                        getOptionValue={(product) => product.id}
                        textFieldProps={{
                          label: 'Roles',
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                        model={RolesModel}
                        name="roleId"
                        value={this.state.record.roleId}
                        handler={formHandler}
                        placeholder="Search role"
                      />
                    </div>
                  </div>
                </div>
              </FormSubmitWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
