import Api from '@launchpad/logic/api/Api';
import BaseModel from '@launchpad/logic/model/BaseModel';

export default class LimitsAdjustmentsModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/limits';
  }

  getDetailsUrl(id) {
    return `admin/limits/${id}`;
  }

  async changeIsActive(limitId, isActive) {
    const result = await Api.call(`admin/limits/${limitId}`, 'PATCH', {
      id: limitId,
      isActive,
    });
    return result.data;
  }

  // POST request by default
  getSubmitUrl() {
    return 'admin/limits';
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: false,
        type: 'field',
      },
      {
        name: 'entityId',
        label: 'Entity Id',
        sortable: false,
        type: 'field',
      },
      {
        name: 'scope',
        label: 'Entity Type',
        sortable: false,
        type: 'field',
      },
      {
        name: 'name',
        label: 'Name',
        sortable: false,
        type: 'field',
      },
      {
        name: 'description',
        label: 'Description',
        sortable: false,
        type: 'field',
      },
      {
        name: 'activeFrom',
        label: 'Active from',
        sortable: false,
        type: 'field',
      },
      {
        name: 'activeTo',
        label: 'Active to',
        sortable: false,
        type: 'field',
      },
      {
        name: 'isActive',
        label: 'Is Active',
        sortable: false,
        type: 'field',
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions',
      },
    ];
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      name: {
        presence: {
          allowEmpty: false,
          message: '^Please enter name',
        },
      },
      description: {
        presence: {
          allowEmpty: false,
          message: '^Please enter description',
        },
      },
      limitTypeId: {
        presence: {
          allowEmpty: false,
          message: '^Please select limit type',
        },
      },
      amount: {
        presence: {
          allowEmpty: false,
          message: '^Please enter amount',
        },
      },
      activeFrom: {
        presence: {
          allowEmpty: false,
          message: '^Please select active from date',
        },
      },
    };
  }
}
