import { ModalDialog } from '@launchpad';
import AppUploadField from '@launchpad/components/crud/AppUploadField';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

type UploadNewFileModalProps = {
  visible: boolean;
  modalText: string;
  onClose: () => void;
  type: 'MEMBERS' | 'VISITS';
};

export const UploadNewFileModal = (props: UploadNewFileModalProps) => {
  const [cancelVisible, setCancelVisible] = useState<boolean>(true);
  const history = useHistory();

  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <ModalDialog
      show={props.visible}
      onClose={() => onClose()}
      customContentStyle="priority-pass-content"
    >
      <p style={{ textAlign: 'center', paddingBottom: 20 }}>
        {props.modalText}
      </p>
      <div
        style={{
          padding: '0 5% 5% 5%',
          textAlign: 'center',
          border: '1px solid white',
          borderRadius: 10,
          paddingTop: 20,
        }}
      >
        <AppUploadField
          label="*Upload the file"
          name="bulkFile"
          submitUrl={
            props.type === 'MEMBERS'
              ? 'admin/tnbank/priority-pass/members-files'
              : 'admin/tnbank/priority-pass/visits-files'
          }
          accept=".xlsx"
          onUploadStart={() => {
            setCancelVisible(false);
          }}
          onFileUpload={(file, response) => {
            if (response?.data?.id) {
              history.push(
                props.type === 'MEMBERS'
                  ? `/priority-pass/members-files/${response?.data?.id}`
                  : `/priority-pass/visits-files/${response?.data?.id}`
              );
            }
            setCancelVisible(true);
          }}
          onFileRemove={(file, response) => {
            // console.log('onremovefile', file);
            // console.log('onremoveresponse', response);
            setCancelVisible(true);
          }}
        />
      </div>
      <div
        className="form-button-container"
        style={{ justifyContent: 'center' }}
      >
        {cancelVisible ? (
          <button
            type="button"
            onClick={() => onClose()}
            className="button-danger"
          >
            CANCEL
          </button>
        ) : null}
      </div>
    </ModalDialog>
  );
};

export default UploadNewFileModal;
