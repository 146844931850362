import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Api from '@launchpad/logic/api/Api';
import AccountTransactions from '@launchpad/modules/accounts/account/tabs/AccountTransactions';
import CustomerNotesAndAlerts from '@launchpad/modules/users/all-users/pages/details/tabs/CustomerNotesAndAlerts';
import { LoadingOverlay } from '@launchpad';
import AccountBasicInfo from './tabs/AccountBasicInfo';
import AccountUserDetails from './tabs/AccountUserDetails';
import AccountCards from './tabs/AccountCards';
import BreadcrumbHelper from '../../../../../@launchpad/util/BreadcrumbHelper';
import AppTabs from '../../../../../@launchpad/components/crud/tabs/AppTabs';
import AccountLimitAdjustments from '../../limit-control/tabs/AccountLimitAdjustments';
import AccountAllAvailableLimits from './tabs/AccountAllAvailableLimits';
import StatementsDownloadList from '../../../../../@launchpad/modules/statements-download/StatementsDownloadList';
import FeeCollection from '../../../../../@launchpad/modules/fee/FeeCollection';

export default class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: {},
      activeTab: props.location?.query?.activeTab ?? 'basic',
      loading: false,
    };

    BreadcrumbHelper.setCrumbs([
      {
        label: 'Accounts',
        link: '/accounts',
      },
    ]);
  }

  componentDidMount() {
    this._getAccountDetails();
  }

  getTabs(account) {
    const basicInfo = (
      <AccountBasicInfo
        account={account}
        onRefresh={() => this._getAccountDetails()}
      />
    );
    const allTabs = [
      {
        id: 'basic',
        icon: 'info',
        title: 'Basic Info',
        component: basicInfo,
      },
      {
        id: 'user',
        icon: 'user',
        title: 'Ownership details',
        component: (
          <AccountUserDetails
            userId={account.userUuid}
            ownerId={account.ownerId}
            account={account}
            onRefresh={() => this._getAccountDetails()}
            {...this.props}
          />
        ),
      },
      {
        id: 'cards',
        icon: 'credit-card',
        title: 'Cards',
        component: <AccountCards account={account} {...this.props} />,
      },
      {
        id: 'available-limits',
        icon: 'hand-paper-o',
        title: 'Configured limits',
        component: (
          <AccountAllAvailableLimits account={account} {...this.props} />
        ),
      },
      {
        id: 'limit-adjustment',
        icon: 'list-ul',
        title: 'Limit adjustments',
        component: (
          <AccountLimitAdjustments
            account={account}
            {...this.props}
            onRefresh={() => this._getAccountDetails()}
          />
        ),
      },
      {
        id: 'fee-collection',
        icon: 'money',
        title: 'Fee Collection',
        component: <FeeCollection account={account} {...this.props} />,
      },
      {
        id: 'transactions',
        icon: 'exchange',
        title: 'Transactions',
        component: <AccountTransactions account={account} {...this.props} />,
      },

      {
        id: 'notes',
        icon: 'files-o',
        title: 'Notes',
        component: (
          <CustomerNotesAndAlerts
            entity={account}
            type="ACCOUNT"
            {...this.props}
          />
        ),
      },
      {
        id: 'statements',
        icon: 'file',
        title: 'Statements',
        component: <StatementsDownloadList account={account} {...this.props} />,
      },
    ];
    return allTabs;
  }

  async _getAccountDetails() {
    this.setState({
      loading: true,
    });
    const { id } = this.props.match.params;
    const getUrl = `admin/accounts/${id}`;
    try {
      const result = await Api.call(getUrl, 'GET');
      this.setState(
        {
          account: result.data,
          loading: false,
        },
        () => {
          BreadcrumbHelper.setCrumbs([
            {
              label: 'Accounts',
              link: '/accounts',
            },
          ]);
          if (this.state.account?.name) {
            BreadcrumbHelper.addCrumb(this.state.account.name);
          }
        }
      );
    } catch (e) {
      console.log('Unexpected error:', e);
    }
  }

  /**
   * Render
   *
   * @returns {XML}
   */
  _renderContent(accountDetails) {
    if (!accountDetails) {
      return <div />;
    }
    return (
      <div>
        <h1>
          <i className="fa fa-info" aria-hidden="true" /> Account Info:{' '}
          {accountDetails.name}
        </h1>
        {this.state.loading ? (
          <LoadingOverlay />
        ) : (
          <AppTabs
            onTabChange={(activeTab) => this.setState({ activeTab })}
            activeTab={this.state.activeTab}
            dontRememberTabs
            tabs={this.getTabs(accountDetails)}
          />
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="dashboard-management">
        {this._renderContent(this.state.account)}
      </div>
    );
  }
}

// Inject router
AccountDetails.contextTypes = {
  router: PropTypes.object,
};
