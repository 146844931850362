// import { IModuleType } from '../../@launchpad/modules/ModulesService';
// import LegalModule from '@launchpad/modules/legal/LegalModule';
import NotesModule from '@launchpad/modules/notes/NotesModule';
import NotificationsModule from '@launchpad/modules/push/NotificationsModule';
import AccessControlModule from './access-control/AccessControlModule';
// import NotificationsModule from './push/NotificationsModule';
// import ToolsModule from '@launchpad/modules/tools/ToolsModule';
import AccountsModule from './accounts/AccountsModule';
import CardsModule from './cards/CardsModule';
import LimitControlModule from './limit-control/LimitControlModule';
import PriorityPassModule from './priority-pass/PriorityPassModule';
import ReportsModule from './reports/ReportsModule';
import TransactionsModule from './transactions/TransactionsModule';
import UsersModule from './users/UsersModule';

const modules: any = [
  new UsersModule(),
  new AccountsModule(),
  new CardsModule(),
  new TransactionsModule(),
  new AccessControlModule(),
  new LimitControlModule(),
  new ReportsModule(),
  new NotesModule(),
  new PriorityPassModule(),
  new NotificationsModule(),
];

export default modules;
