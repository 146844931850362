import BaseModel from '@launchpad/logic/model/BaseModel';

export default class VisitFileModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod(): string {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl(): any {
    return 'admin/tnbank/priority-pass/visits-files';
  }

  /**
   * Get details url
   */
  getDetailsUrl(id: string): string | null {
    return `admin/tnbank/priority-pass/visits-files/${id}`;
  }

  /**
   * Get default table columns
   */
  getTableColumns(): any {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: false,
        type: 'field',
      },
      {
        name: 'createdAt',
        label: 'Created',
        sortable: false,
        type: 'field',
      },
      {
        name: 'name',
        label: 'Name',
        sortable: false,
        type: 'field',
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions',
      },
    ];
  }
}
