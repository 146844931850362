import React from 'react';
import _ from 'lodash';
import { OverrideService } from '../../index';

export default class LPOverridable extends React.Component<any, any> {
  render() {
    const overrides = OverrideService.getOverrides();

    const Override = _.get(overrides, this.props.name, null);

    if (Override) {
      return <Override {...this.props} />;
    }
    return this.props.children ? this.props.children : null;
  }
}
