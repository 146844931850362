import React, { Component } from 'react';
import { AccountProductTypeModel } from '@launchpad/logic/model/ProductTypeModel';
import Api from '@launchpad/logic/api/Api';
import TextField from '@material-ui/core/TextField';
import AppSelect from '@launchpad/components/crud/AppSelect';
import 'react-datepicker/dist/react-datepicker.css';
import { FormSubmitWrapper, FormHandler, InputField } from '@launchpad/index';
import UsersAccountModel from '../../models/UsersAccountsModel';

export default class UsersAddAccount extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.formHandler = new FormHandler(
      this,
      new UsersAccountModel({
        accountNumber: '',
        productId: '',
        userId: props.match.params.id,
      }),
      { user: { firstName: '', lastName: '' } }
    );
  }

  componentDidMount() {
    this._getUserDetails();
  }

  async _getUserDetails() {
    const { id } = this.props.match.params;
    const getUrl = `admin/users/${id}`;

    try {
      const result = await Api.call(getUrl, 'GET');
      this.setState({
        user: result.data,
      });
    } catch (e) {}
  }

  render() {
    const { formHandler } = this;
    return (
      <div>
        <h1>Add new account</h1>
        <div
          className="element-with-blur"
          style={{ paddingTop: '2px', marginRight: 20 }}
        >
          <div className="dark-blur" />
          <div className="box-header">
            <h2>Add new account</h2>
          </div>
          <div className="add-new-group" style={{ position: 'relative' }}>
            <div className="container">
              <FormSubmitWrapper
                formHandler={formHandler}
                to={`/user-details/${this.props.match.params.id}`}
              >
                <div className="row">
                  <div className="col-md-6">
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      customInput={TextField}
                      label="Account number"
                      type="text"
                      name="accountNumber"
                      value={this.state.record.accountNumber}
                      handler={formHandler}
                    />
                    <div style={{ paddingTop: 14, zIndex: 99 }}>
                      <AppSelect
                        getOptionValue={(product) => product.id}
                        textFieldProps={{
                          label: 'Product',
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                        model={AccountProductTypeModel}
                        name="productId"
                        value={this.state.record.productId}
                        handler={formHandler}
                        placeholder="Search a product"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <InputField
                      materialProps={{
                        fullWidth: true,
                        disabled: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="User"
                      type="text"
                      name="user"
                      value={`${this.state.user.firstName} ${this.state.user.lastName}`}
                      handler={formHandler}
                    />
                  </div>
                </div>
              </FormSubmitWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
