/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Api from '@launchpad/logic/api/Api';
import 'react-datepicker/dist/react-datepicker.css';
import AppSelect from '@launchpad/components/crud/AppSelect';
import { FormSubmitWrapper, FormHandler, InputField } from '@launchpad/index';
import LimitTypesModel from '@launchpad/logic/model/LimitTypesModel';
import { AppCheckboxInput } from '@launchpad/components';
import LimitsAdjustmentsModel from '../models/LimitsAdjustmentsModel';

export default class AddLimitAdjustment extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.formHandler = new FormHandler(
      this,
      new LimitsAdjustmentsModel(
        {
          id: props.match.params.limitId,
          entityId: props.match.params.id,
          // TEMP Solution
          scope: window.location.pathname.includes('accounts')
            ? 'ACCOUNT'
            : 'PAYMENT_DEVICE',
          name: '',
          description: '',
          limitTypeId: '',
          activeFrom: '',
          activeTo: '',
          amount: '',
          overridesForcedAvailableLimit: false,
        },
        {
          entity: {},
          permanentLimitAdjustment: false,
        }
      )
    );
  }

  componentDidMount() {
    const { id, limitId } = this.props.match.params;

    if (id) {
      try {
        if (window.location.pathname.includes('accounts')) {
          Api.call(`admin/accounts/${id}`, 'GET').then((result) => {
            this.setState(
              {
                entity: result.data,
              },
              () => {
                this.pullLimitData(limitId);
              }
            );
          });
        } else {
          Api.call(`admin/payment-devices/${id}`, 'GET').then((result) => {
            this.setState(
              {
                entity: result.data,
              },
              () => {
                this.pullLimitData(limitId);
              }
            );
          });
        }
      } catch (error) {
        console.log('er:', error);
      }
    } else if (limitId) {
      this.pullLimitData(limitId);
    }
  }

  getSubmitData() {
    return {
      ...this.state.record,
      configuration: {
        items: [
          {
            amount: this.state.record.amount,
            currency:
              this.state.entity?.currency ||
              this.state.entity?.account?.currency,
          },
        ],
      },
    };
  }

  pullLimitData(limitId) {
    if (!limitId) return;

    try {
      Api.call(new LimitsAdjustmentsModel().getDetailsUrl(limitId), 'GET').then(
        (result) => {
          // Figure out currency
          const currency =
            this.state.entity?.currency ?? this.state.entity?.account?.currency;

          // Extract amount
          const amount =
            result.data.configuration?.amount ??
            (currency
              ? result.data.configuration?.items?.find(
                  (x) => x.currency === currency
                )?.amount ?? result.data.configuration?.items[0].amount
              : result.data.configuration?.items[0].amount);

          this.setState(
            {
              record: {
                ...result.data,
                amount,
                currency,
              },
            },
            () => {
              if (result.data.activeTo === null) {
                this.setState({
                  permanentLimitAdjustment: true,
                });
              }
            }
          );
        }
      );
    } catch (error) {
      console.log('Err:', error);
    }
  }

  // TEMP SOLUTION
  parentDirectory(dir) {
    const lastSlash = dir.lastIndexOf('/');
    if (lastSlash === -1) {
      return dir;
    }
    if (lastSlash === 0) {
      return '/';
    }
    return dir.substring(0, lastSlash);
  }

  _setPermanentLimitAdjustment() {
    this.setState(
      {
        permanentLimitAdjustment: !this.state.permanentLimitAdjustment,
      },
      () => {
        if (this.state.permanentLimitAdjustment) {
          this.setState({
            record: {
              ...this.state.record,
              activeTo: null,
            },
          });
        } else {
          this.setState({
            record: {
              ...this.state.record,
              activeTo: moment(new Date()).format('YYYY-MM-DD'),
            },
          });
        }
      }
    );
  }

  render() {
    const { formHandler } = this;
    const { limitId } = this.props.match.params;

    const yesterdaysDate = new Date();
    yesterdaysDate.setDate(yesterdaysDate.getDate() - 1);

    return (
      <div>
        <h1>{limitId ? 'Edit' : 'Add new'} limit adjustment</h1>
        <div
          className="element-with-blur"
          style={{ paddingTop: '2px', marginRight: 20 }}
        >
          <div className="dark-blur" />
          <div className="box-header">
            <h2>{limitId ? 'Edit' : 'Add new'} limit adjustment</h2>
          </div>
          <div className="add-new-group" style={{ position: 'relative' }}>
            <div className="container">
              <FormSubmitWrapper
                formHandler={formHandler}
                to={
                  window.location.pathname.includes('add-new-limit-adjustment')
                    ? this.parentDirectory(window.location.pathname)
                    : this.parentDirectory(
                        this.parentDirectory(window.location.pathname)
                      )
                }
              >
                <div className="row">
                  <div className="col-md-6">
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      customInput={TextField}
                      label="Name"
                      type="text"
                      name="name"
                      value={this.state.record.name}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                        disabled: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      customInput={TextField}
                      label="Entity type"
                      type="text"
                      name="scope"
                      value={
                        this.state.record.scope === 'PAYMENT_DEVICE'
                          ? 'CARD'
                          : 'ACCOUNT'
                      }
                      handler={formHandler}
                    />

                    <div className="input-wrap" style={{ zIndex: 99 }}>
                      <AppSelect
                        getOptionValue={(product) => product.id}
                        textFieldProps={{
                          label: 'Limit Type',
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                        model={LimitTypesModel}
                        name="limitTypeId"
                        value={this.state.record.limitTypeId}
                        handler={formHandler}
                        placeholder="Search a limit"
                      />
                    </div>
                    <DatePicker
                      selected={
                        this.state.record.activeFrom
                          ? moment(this.state.record.activeFrom).toDate()
                          : ''
                      }
                      onChange={(date) => {
                        if (date) {
                          this.formHandler.handleInputChange(
                            'activeFrom',
                            moment(date).format('YYYY-MM-DD')
                          );
                        }
                      }}
                      minDate={yesterdaysDate}
                      style={{ width: '100%', zIndex: 9999999999999 }}
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={
                        <InputField
                          materialProps={{
                            fullWidth: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            name: 'activeFrom',
                          }}
                          inputProps={{}}
                          label="Active From"
                          type="text"
                          value=""
                          name="activeFrom"
                          handler={formHandler}
                        />
                      }
                    />
                    <div className="pt-4">
                      <AppCheckboxInput
                        name="permanentLimitAdjustment"
                        value={this.state.permanentLimitAdjustment}
                        onChange={() => this._setPermanentLimitAdjustment()}
                        id="permanentLimitAdjustment"
                        htmlFor="permanentLimitAdjustment"
                        labelText="Permanent Limit Adjustment"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      customInput={TextField}
                      label="Description"
                      type="text"
                      name="description"
                      value={this.state.record.description}
                      handler={formHandler}
                    />
                    <InputField
                      key={this.state.record.entityId}
                      materialProps={{
                        fullWidth: true,
                        disabled: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Entity id"
                      type="text"
                      name="entityId"
                      value={this.state.record.entityId}
                      handler={formHandler}
                    />

                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Amount"
                      type="text"
                      name="amount"
                      value={this.state.record.amount}
                      handler={formHandler}
                    />
                    <div className="pt-3">
                      <DatePicker
                        selected={
                          this.state.record.activeTo
                            ? moment(this.state.record.activeTo).toDate()
                            : ''
                        }
                        onChange={(date) => {
                          if (date) {
                            this.formHandler.handleInputChange(
                              'activeTo',
                              moment(date).format('YYYY-MM-DD')
                            );
                          }
                        }}
                        minDate={new Date()}
                        style={{ width: '100%', zIndex: 9999999999999 }}
                        dateFormat="dd/MM/yyyy"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        customInput={
                          <InputField
                            materialProps={{
                              fullWidth: true,
                              disabled: this.state.record.activeTo === null,
                            }}
                            InputLabelProps={{
                              shrink: true,
                              name: 'activeTo',
                            }}
                            inputProps={{}}
                            label="Active To"
                            type="text"
                            value=""
                            name="activeTo"
                            handler={formHandler}
                          />
                        }
                      />
                    </div>
                    <div className="pt-4">
                      <AppCheckboxInput
                        name="overridesForcedAvailableLimit"
                        value={this.state.record.overridesForcedAvailableLimit}
                        onChange={(value) =>
                          this.formHandler.handleInputChange(
                            'overridesForcedAvailableLimit',
                            value
                          )
                        }
                        id="overridesForcedAvailableLimit"
                        htmlFor="overridesForcedAvailableLimit"
                        labelText="Overrides forced available limit"
                      />
                    </div>
                  </div>
                </div>
              </FormSubmitWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
