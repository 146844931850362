import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppTable, AppWidget } from '../../../../../../@launchpad';
import AppActiveSign from '../../../../../../@launchpad/components/elements/AppActiveSign';

const getInnerElement = (props, visibleItems, setVisibleItems) => {
  const { result } = props;

  if (!result.success) {
    const errorStrings = ['An error occurred, please check the file.'];
    if (result.errors) {
      result.errors.map((x) => errorStrings.push(x.message));
    }
    return (
      <div className="padded">
        {errorStrings.map((x) => (
          <p>{x}</p>
        ))}
      </div>
    );
  }

  const { records } = result.data;

  const totalCount = records.length;
  const successCount = records.filter((x) => x.success).length;
  const failCount = records.filter((x) => !x.success).length;

  const summaryElement = (
    <div className="text-center padded">
      <h2>Total records: {totalCount}</h2>
      <h3>Successful records: {successCount}</h3>
      <h3>Failed records: {failCount}</h3>
    </div>
  );

  const toggleItem = (index) => {
    if (visibleItems.includes(index)) {
      setVisibleItems(visibleItems.filter((x) => x !== index));
    } else {
      setVisibleItems([...visibleItems, index]);
    }
  };

  return (
    <>
      {summaryElement}
      <AppTable
        model={null}
        data={records}
        renderCell={(column, item, index) => {
          switch (column.name) {
            case 'success':
              return <AppActiveSign value={item.success} />;
            case 'index':
              return `#${item.index}`;
            case 'userId':
              return item.userId ? (
                <Link
                  to={`/user-details/${item.userId}`}
                  target="_blank"
                  className="userCardLink"
                >
                  {item.importData
                    ? `${item.importData.firstName} ${item.importData.lastName}`
                    : item.userId}
                </Link>
              ) : (
                '-'
              );
            case 'error':
              return item.error ? item.error : '-';
            case 'importData':
              return (
                <div>
                  <a
                    href="javascript:void(0)"
                    onClick={() => toggleItem(item.index)}
                    className="userCardLink"
                  >
                    <i
                      className={`fa fa-${
                        visibleItems.includes(item.index)
                          ? 'caret-down'
                          : 'caret-right'
                      }`}
                    >
                      &nbsp;
                    </i>{' '}
                    {visibleItems.includes(item.index) ? 'hide' : 'show'}
                  </a>
                  <pre
                    style={{
                      color: 'white',
                      display: visibleItems.includes(item.index)
                        ? 'block'
                        : 'none',
                    }}
                  >
                    {JSON.stringify(item.importData, null, 2)}
                  </pre>
                </div>
              );
            default:
              return undefined;
          }
        }}
        getTableColumns={() => [
          {
            name: 'index',
            label: 'Row',
            sortable: false,
            type: 'field',
          },
          {
            name: 'success',
            label: 'Success',
            sortable: false,
            type: 'field',
          },
          {
            name: 'userId',
            label: 'User ID',
            sortable: false,
            type: 'field',
          },
          {
            name: 'error',
            label: 'Error',
            sortable: false,
            type: 'field',
          },
          {
            name: 'importData',
            label: 'Import data',
            sortable: false,
            type: 'field',
          },
        ]}
      />
    </>
  );
};

export const BulkLoadResult = (props) => {
  const [visibleItems, setVisibleItems] = useState([]);

  const innerElement = getInnerElement(props, visibleItems, setVisibleItems);

  return (
    <AppWidget title="Result" icon="plus">
      {innerElement}
    </AppWidget>
  );
};

export default BulkLoadResult;
