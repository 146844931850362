import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';
import React from 'react';
import NotesNavigation from './NotesNavigation';

export const NAME = 'notes';

export default class NotesModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'notes.*';
  }

  getPolicy() {
    return 'Admin:Notes:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Notes',
        icon: 'files-o',
        path: '/notes',
        permission: 'notes.*',
        policy: 'Admin:Notes:View:*',
      },
    ];
  }

  getRoutes() {
    return [
      <AppRoute
        permission="notes.*"
        policy="Admin:Notes:View:*"
        path="/notes"
        component={NotesNavigation}
      />,
    ];
  }
}
