import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';
import Dashboard from './Dashboard';

export const NAME = 'dashboard';

export default class DashboardModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'dashboard.*';
  }

  getPolicy() {
    return 'Admin:Dashboard:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Dashboard',
        icon: 'tachometer',
        path: '/',
        permission: 'dashboard.*',
        policy: 'Admin:Dashboard:View:*'
      }
    ];
  }

  getRoutes() {
    return [<AppRoute exact path="/" component={Dashboard} />];
  }
}
