import Api from '@launchpad/logic/api/Api';
import BaseModel from '@launchpad/logic/model/BaseModel';

export default class OrganisationMembersModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/organisation-members';
  }

  // /**
  //  * Get details url
  //  */
  // getDetailsUrl() {
  //   return 'admin/organisation-members/{id}';
  // }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'admin/organisation-members';
  }

  /**
   * Removing user from organisation
   */
  getDeleteUrl(id) {
    return `admin/organisation-members/${id}`;
  }

  async delete(id) {
    const result = await Api.call(this.getDeleteUrl(id), 'DELETE');
    return result;
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: false,
        type: 'field',
      },
      {
        name: 'user.firstName',
        label: 'First Name',
        sortable: false,
        type: 'field',
      },
      {
        name: 'user.lastName',
        label: 'Last Name',
        sortable: false,
        type: 'field',
      },
      {
        name: 'user.username',
        label: 'Username',
        sortable: false,
        type: 'field',
      },
      {
        name: 'role.name',
        label: 'Role',
        sortable: false,
        type: 'field',
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions',
      },
    ];
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      userId: {
        presence: {
          allowEmpty: false,
          message: '^Please select user',
        },
      },
      roleId: {
        presence: {
          allowEmpty: false,
          message: '^Please select role',
        },
      },
    };
  }
}
