import React from 'react';
import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';

export const NAME = 'dashboard';

export default class ReportsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'reports.*';
  }

  getPolicy() {
    return 'Admin:Report:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Reports',
        icon: 'file',
        path: '/reports',
        permission: 'reports.*',
        policy: 'Admin:Report:View:*',
      },
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        permission="reports.*"
        policy="Admin:Report:View:*"
        path={`${currentUrl}reports`}
        load={() => import('./ReportsNavigation')}
      />,
    ];
  }
}
