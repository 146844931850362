import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'kyc';

export default class KycModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'kyc';
  }

  getPolicy() {
    return 'Admin:KYC:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'KYC',
        slug: 'kyc',
        icon: 'tachometer',
        path: null,
        permission: 'kyc.*',
        policy: 'Admin:KYC:View:*',
        children: [
          {
            label: 'Dashboard',
            icon: 'tachometer',
            path: '/kyc',
            permission: 'kyc.*',
            policy: 'Admin:KYC:View:*',
          },
          {
            label: 'Requests',
            icon: 'list',
            path: '/kyc/requests',
            permission: 'kyc.*',
            policy: 'Admin:KYC:View:*',
          }
        ]
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute path={`${currentUrl}kyc`} load={() => import('./KYC')} />
    ];
  }
}
