import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { LoadingOverlay } from '@launchpad';
import Api from '@launchpad/logic/api/Api';

enum EntityType {
  ACCOUNTS = 'accounts',
  CARDS = 'cards',
  USERS = 'users',
}
const ALLOWED_TYPES = [EntityType.ACCOUNTS, EntityType.CARDS, EntityType.USERS];
const REFERENCE_PREFIX =
  process.env.REACT_APP_REFERENCE_PREFIX || 'tReference:';

const extractReferenceAndTypeFromParams = (params) => {
  const { reference, type } = params;
  if (!reference.includes(REFERENCE_PREFIX)) {
    return null;
  }

  if (!ALLOWED_TYPES.includes(type)) {
    return null;
  }

  return {
    reference: reference.replace(REFERENCE_PREFIX, ''),
    type,
  };
};

const resolveUrl = (type, reference) => {
  return `admin/temenos/${type}/admin-url-by-reference/${reference}`;
};

const fetchData = async (type, reference) => {
  try {
    const response: any = await Api.call(resolveUrl(type, reference), 'GET');
    if (response && response.data) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
    throw e;
  }

  return null;
};

const getLocalUrl = (type, id) => {
  switch (type) {
    case EntityType.ACCOUNTS:
      return `/accounts/details/${id}`;
    case EntityType.CARDS:
      return `/cards/details/${id}`;
    case EntityType.USERS:
      return `/user-details/${id}`;
    default:
      return null;
  }
};

const extractRedirectionFromData = (type, data) => {
  if (data.url) {
    return data.url;
  }

  if (data.id) {
    return getLocalUrl(type, data.id);
  }

  return null;
};

const ExternalDetailsComponent: React.FC<any> = (props) => {
  const referenceAndType = extractReferenceAndTypeFromParams(
    props.match.params
  );

  const [status, setStatus] = useState(referenceAndType ? 'loading' : 'error');

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (!referenceAndType) return;

    fetchData(referenceAndType.type, referenceAndType.reference)
      .then((data) => {
        if (!data) {
          setStatus('error');
        }
        const redirection = extractRedirectionFromData(
          referenceAndType.type,
          data
        );
        if (redirection) {
          window.location.href = redirection;
          setStatus('redirecting');
        } else {
          setStatus('error');
        }
      })
      .catch(() => setStatus('error'));
  });

  if (status === 'error') {
    return (
      <CenteredContent>
        <h1>Invalid URL</h1>
        <p>
          The URL you are trying to access may be invalid. Please check the URL
          and references in it.
          <p />
          <p>
            Reference: {referenceAndType?.reference}
            <br />
            Type: {referenceAndType?.type}
          </p>
        </p>
      </CenteredContent>
    );
  }
  return (
    <>
      <LoadingOverlay />
    </>
  );
};

const CenteredContent = (props) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          padding: 20,
          margin: 20,
          background: 'rgba(0,0,0,0.7)',
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default withRouter(ExternalDetailsComponent);
