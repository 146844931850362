import { PolicyEffect, ResourceEntity } from './access-control.enums';
import {
  AccessControlResource,
  Policy,
  PolicyStatement,
  ResourceEntityCandidate,
} from './access-control.types';

export const createAccessControlResource = (
  entity: ResourceEntity,
  candidate: unknown
): AccessControlResource | undefined => {
  if (!candidate) return undefined;

  const resource = candidate as ResourceEntityCandidate;
  return {
    entity,
    entityId: resource.id,
    userId: extractUserId(resource),
    organisationId: extractOrganisationId(resource),
    ownerId: extractOwnerId(resource),
  };
};

export const requestPermission = (
  policies: Policy[],
  actions: string[],
  resource: AccessControlResource | undefined
): boolean => {
  if (!allowedToAccessResource(actions, resource, policies)) {
    return false;
  }

  return true;
};

export const requestPermissionForMultipleResources = (
  policies: Policy[],
  actions: string[],
  resources: AccessControlResource[]
): boolean => {
  for (let i = 0; i < resources.length; i++) {
    const allowed = allowedToAccessResource(actions, resources[i], policies);

    if (!allowed) {
      return false;
    }
  }

  return true;
};

export const allowedToAccessResource = (
  actions: string[],
  resource: AccessControlResource | undefined,
  policies: Policy[]
): boolean => {
  const resourcePaths = buildResourcePathsFromAResource(resource);

  return checkIfAllActionsAllowedOnAnyResource(
    actions,
    resourcePaths,
    policies
  );
};

export const checkIfAllActionsAllowedOnAnyResource = (
  actions: string[],
  resourcePaths: string[],
  policies: Policy[]
): boolean => {
  return (
    actions
      .map((x) => checkIfActionAllowedOnAnyResource(x, resourcePaths, policies))
      .filter((x) => !x).length === 0
  );
};

export const checkIfActionAllowedOnAnyResource = (
  action: string,
  resourcePaths: string[],
  policies: Policy[]
): boolean => {
  const responses = resourcePaths
    .map((resource) =>
      checkIfActionAllowedOnResource(action, resource, policies)
    )
    .filter((x) => x !== null);

  // If any check returned false - prevent access
  if (responses.some((x) => x === false)) return false;

  // If any check returned true, a none false, give access
  if (responses.some((x) => x === true)) return true;

  // By default, prevent access
  return false;
};

// Custom sorting function
const customSort = (a, b) => {
  // Sort by weight in descending order
  const aWeight = a.weight || 0;
  const bWeight = b.weight || 0;
  if (aWeight !== bWeight) {
    return bWeight - aWeight;
  }

  // Sort by effect ('DENY' first, 'ALLOW' last)
  if (a.effect === 'DENY' && b.effect === 'ALLOW') {
    return -1;
  }
  if (a.effect === 'ALLOW' && b.effect === 'DENY') {
    return 1;
  }

  // If weights are equal or effects are the same, maintain the original order
  return 0;
};

/**
 * This function will compare both allow and deny policies and return true if an action is allowed
 *
 * @param action
 * @param resource
 * @param policies
 * @returns
 */
export const checkIfActionAllowedOnResource = (
  action: string,
  resource: string,
  policies: Policy[]
): boolean | null => {
  const actionCandidates = convertActionToPossibleActionCandidates(
    action.split(':')
  );

  const listOfStatements = policies.map((policy) => policy.statements).flat();
  const sortedStatements = listOfStatements.sort(customSort);

  return checkIfMatchingPolicyExistsForAResource(
    sortedStatements,
    actionCandidates,
    resource
  );
};

export const convertActionToPossibleActionCandidates = (
  items: string[],
  prefix = ''
): string[] => {
  if (items.length === 0) return ['*'];

  if (items[0] === '*') return [];

  const itemWithPrefix = prefix + items[0];

  const currentItems = [itemWithPrefix, `${itemWithPrefix}:*`];

  if (items.length === 1) return currentItems;

  return [
    ...(!prefix ? ['*'] : []),
    ...currentItems,
    ...convertActionToPossibleActionCandidates(
      items.splice(1),
      `${itemWithPrefix}:`
    ),
  ];
};

/**
 * This function will return null if there are no policies/statements that
 * are not related to passed actionCandidates and resource
 *
 * @param policies
 * @param actionCandidates
 * @param resource
 * @returns
 */
const checkIfMatchingPolicyExistsForAResource = (
  statements: PolicyStatement[],
  actionCandidates: string[],
  resource: string
): boolean | null => {
  const actionStatuses = statements
    .map((statement) => {
      const actionFound =
        statement.actions.filter((x) => {
          return actionCandidates.includes(x);
        }).length > 0;
      const resourceFound =
        statement.resources.filter((x) => {
          return resource.includes(x);
        }).length > 0;

      if (!actionFound || !resourceFound) {
        return null;
      }

      return (
        statement.effect.toLowerCase() === PolicyEffect.ALLOW.toLowerCase()
      );
    })
    .filter((x) => x !== null);
  return actionStatuses.length === 0 ? null : actionStatuses[0];
};

const buildResourcePathsFromAResource = (
  resource: AccessControlResource | undefined
): string[] => {
  const paths: string[] = [];

  if (resource) {
    if (resource.organisationId) {
      paths.push(`organisations/${resource.organisationId}`);
    }

    if (resource.userId) {
      paths.push(`users/${resource.userId}`);
    }

    if (resource.ownerId) {
      paths.push(`users/${resource.ownerId}`);
    }

    if (resource.entity) {
      // TODO: Handle plural better
      // paths.push(`${camelize(resource.entity)}s/${resource.entityId}`);
      // paths.push(`${camelize(resource.entity)}s/*`);
    }
  }

  paths.push('*');

  return paths;
};

const extractUserId = (
  candidate: ResourceEntityCandidate
): string | undefined => {
  return candidate.userId ?? candidate.user ?? candidate.userUuid;
};

const extractOrganisationId = (
  candidate: ResourceEntityCandidate
): string | undefined => {
  return (
    candidate.organisationId ??
    candidate.organisationUuid ??
    candidate.organizationId
  );
};

const extractOwnerId = (
  candidate: ResourceEntityCandidate
): string | undefined => {
  return candidate.ownerId ?? candidate.owner;
};
