import numeral from 'numeral';
import CurrencyHelper from './CurrencyHelper';

export function currency(value = 0, symbol = false, skipDecimal = false) {
  const negative = value < 0;
  const val = skipDecimal
    ? numeral(Math.abs(value)).format('0,0')
    : numeral(Math.abs(value)).format('0,0.00');

  let returnValue = val;

  // if(!value % 1 == 0) {
  //   returnValue = parseFloat(Math.round(value * 100) / 100).toFixed(2);
  // } else {

  // }
  // if(value % 1 == 0) {
  //   returnValue = parseInt(value, 10);
  // }

  if (symbol) {
    if (symbol === true) {
      returnValue = CurrencyHelper.getCurrencySymbol() + returnValue;
    } else {
      returnValue = CurrencyHelper.getCurrencySymbol(symbol) + returnValue;
    }
  }

  if (negative) {
    returnValue = '-' + returnValue;
  }

  return returnValue;
}

export function numberFormat(value = 0, format = '0,0.00') {
  return numeral(value).format(format);
}

export default {
  currency,
  numberFormat
};
