import BaseModel from '../../../../../../@launchpad/logic/model/BaseModel';

export default class BulkLoadUsersModel extends BaseModel {
  /**
   * Get list url
   */
  getListUrl() {
    return 'bulk-load/list';
  }

  /**
   * Get details url
   */
  getDetailsUrl() {
    return 'bulk-load/details';
  }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'auth/registration/import-users';
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: true,
        type: 'field',
      },
      {
        name: 'name',
        label: 'Name',
        sortable: false,
        type: 'field',
      },
      {
        name: 'created',
        label: 'Created',
        sortable: false,
        type: 'field',
      },
      {
        name: 'status',
        label: 'Status',
        sortable: false,
        type: 'field',
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions',
      },
    ];
  }

  getValidationRules() {
    return {
      name: {
        presence: {
          allowEmpty: false,
          message: '^Please enter name',
        },
      },
      bulkFile: {
        presence: {
          allowEmpty: false,
          message: '^Please select file',
        },
      },
    };
  }
}
