import React, { useState } from 'react';
import Api from '@launchpad/logic/api/Api';
import ToastHelper from '@launchpad/util/ToastHelper';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { AppButton } from "../../../components/crud/AppButton";
import { OverrideService } from '../../../logic/services';
import 'react-datepicker/dist/react-datepicker.css';

const NoteAlarmWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  input[type='text'] {
    background: transparent;
    border: 1px solid #85898a;
    text-align: center;
    color: white;
    outline-color: rgba(255, 255, 255, 0);
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    font-size: 15px;
    // transition: all .3s linear;
    transition-duration: 0.22s;
    transition-property: outline-color;
  }
  input[type='text']:focus {
    outline-style: ridge;
    outline-color: rgba(255, 255, 255, 0.8);
  }
  input[type='text']:blur {
    outline-style: ridge;
    outline-color: rgba(255, 255, 255, 0);
  }
  #calendar-icon {
    position: absolute;
    top: 7px;
    left: 10px;
  }
`;

const TextArea = styled.textarea`
  max-height: 400px;
  font-size: 14px;
  padding: 5px 10px 5px 10px;
`;

export default function NoteForm(props) {
  const [noteText, setNoteText] = useState(props.note.message);

  const propsReminder = props.note.reminder && new Date(moment(props.note.reminder).format('YYYY-MM-DD')).toISOString();
  const [alarmDate, setAlarmDate] = useState(propsReminder || '');
  const [loading, setLoading] = useState(false);

  async function saveNote() {
    setLoading(true);
    if (!noteText) {
      ToastHelper.show('Please write your note.');
      setLoading(false);
      return;
    }

    try {
      const result = await Api.call(`admin/notes/${props.note.id}`, 'PATCH', {
        entity: props.note.entity,
        entityId: props.note.entityId,
        message: noteText,
        reminder: alarmDate || undefined
      });
      if (result.success) {
        setTimeout(() => {
          setLoading(false);
          props.onNoteEdit();
        }, 1000);
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <div className="dashboard-management">
    <h1>
      <i className="fa fa-pencil-square-o" aria-hidden="true" /> Edit note
    </h1>
      <div>
        <div className="row">
          <div className="col-lg-8">
            <div className="add-note" style={{ backgroundColor: '#3e444a' }}>
              <div className="add-note-header">
                <p>Edit note {props.note.id}</p>
              </div>
              <div className="form-field" style={{ padding: 10 }}>
                <TextArea
                  placeholder="Write your note here"
                  style={{ width: '100%', height: '100px' }}
                  onChange={e => setNoteText(e.target.value)}
                  value={noteText}
                  maxLength={OverrideService.getConfig().notesCharLength || '250'}
                />
              </div>
              <NoteAlarmWrapper>
                {/* <AppCheckboxInput /> */}
                <p>Select reminder date (optional)</p>
                <div className="relative mb-5 d-flex flex-row align-items-center">
                  <i className="fa fa-calendar" id='calendar-icon' aria-hidden="true" />
                  <DatePicker
                    selected={alarmDate !== '' && moment(alarmDate).toDate()}
                    onChange={date =>
                      setAlarmDate(new Date(moment(date).format('YYYY-MM-DD')).toISOString())
                    }
                    style={{ width: '100%', background: 'transparent' }}
                    dateFormat="dd/MM/yyyy HH:mm"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    type="text"
                    minDate={new Date()}
                    value={alarmDate !== '' && moment(alarmDate).format('DD-MM-YYYY')}
                    name="alarmDate"
                    autoComplete='off'
                  />
                  {alarmDate !== '' &&
                  <i className="fa fa-times fa-lg" aria-hidden="true" onClick={()=> setAlarmDate('')} style={{padding: 5, cursor: 'pointer', color: '#b74343'}} />
                  }
                </div>
                <div className='d-flex w-75'>
                <AppButton
                    type="button"
                    onClick={() => props.onNoteEdit()}
                    style={{
                      marginRight: 5,
                      backgroundColor: '#b74343 !important'
                    }}
                    className="custom-danger-btn"
                  >
                    Cancel
                </AppButton>
                <AppButton type='button' onClick={saveNote} isLoading={loading}>
                  SAVE
                </AppButton>
                </div>
              </NoteAlarmWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
