import React, { Component } from 'react';
import AppSelect from '../../../components/crud/AppSelect';
import { FormHandler, FormSubmitWrapper, InputField } from '../../../index';
import { getCurrencySymbol } from '../../../util/CurrencyHelper';
import ChargeFeeFormModel from '../models/ChargeFeeFormModel';
import FeeModel from '../models/FeeModel';

export default class ChargeFeeForm extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new ChargeFeeFormModel({
        entityId: this.props.entityId,
        entity: this.props.entity,
        title: '',
        feeId: '',
        amount: '',
        currency: this.props.currency ?? 'GBP'
      })
    );
  }

  onSubmitSuccess() {
    this.props.customCloseFunction();
  }

  _getModalMessage(data) {
    if (!data.success) {
      return data.message;
    }
    if (data.success) {
      return 'Charge fee successfully!';
    }
    return null;
  }

  render() {
    const { formHandler } = this;

    return (
      <div>
        <h3>Charge Fee</h3>
        <div
          className="element-with-blur"
          style={{ paddingTop: '2px', marginRight: 20 }}
        >
          <div className="add-new-group" style={{ position: 'relative' }}>
            <div className="container">
              <FormSubmitWrapper
                formHandler={formHandler}
                to={this.props.redirectRoute}
                buttonText={this.props.buttonText}
                customCloseFunction={this.props.customCloseFunction}
                getModalMessage={data => this._getModalMessage(data)}
              >
                <InputField
                  materialProps={{
                    fullWidth: true
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  label="Fee Title*"
                  type="text"
                  name="title"
                  value={this.state.record.title}
                  handler={formHandler}
                />

                <AppSelect
                  getOptionValue={product => product.id}
                  wrapperStyle={{ marginBottom: 10, marginTop: 10,  zIndex: 99999 }}
                  textFieldProps={{
                    label: 'Fee Type*',
                    InputLabelProps: {
                      shrink: true
                    }
                  }}
                  params={{
                    collectionMethod: 'MANUAL',
                    limit: 1000,
                    page: 1
                  }}
                  model={FeeModel}
                  name="feeId"
                  value={this.state.record.feeId}
                  handler={formHandler}
                  placeholder="Select Fee Type"
                />
                <InputField
                  materialProps={{
                    fullWidth: true
                  }}
                  type="number"
                  label="Fee Amount*"
                  name="amount"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={this.state.record.amount}
                  handler={formHandler}
                  prefix={getCurrencySymbol(this.state.record.currency)}
                />
                <AppSelect
                  getOptionValue={currency => currency.id}
                  wrapperStyle={{ marginBottom: 10, marginTop: 10, zIndex: 999 }}
                  textFieldProps={{
                    label: 'Fee Currency',
                    InputLabelProps: {
                      shrink: true
                    }
                  }}
                  options={[
                    {
                      id: 'GBP',
                      name: 'GBP'
                    },
                    {
                      id: 'EUR',
                      name: 'EUR'
                    },
                    {
                      id: 'USD',
                      name: 'USD'
                    }
                  ]}
                  name="currency"
                  value={this.state.record.currency}
                  handler={formHandler}
                  placeholder="Select currency (optional)"
                />
              </FormSubmitWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ChargeFeeForm.defaultProps = {
  entityId: null,
  entity: null
};
