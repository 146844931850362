import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'events';

export default class EventsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'events.*';
  }

  getPolicy() {
    return 'Admin:Event:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Events',
        icon: 'file-text',
        path: '/events',
        permission: 'events.*',
        policy: 'Admin:Event:View:*'
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute path={`${currentUrl}events`} load={() => import('./Events')} />
    ];
  }
}
