/* eslint-disable @typescript-eslint/no-var-requires */
import React, { ComponentType } from 'react';
import ExternalDetails from '../components/ExternalDetails';

type AppRouteProps = {
  path: string;
  component: ComponentType;
  authenticatedOnly?: boolean;
  permission?: string;
  policy?: string;
};

const routes: () => JSX.Element[] = () => {
  // The reason for using require instead of import is because we would like for AppRoute to load a bit later
  // once the redux is ready. This is a workaround for the issue where state is not available in the AppRoute
  const AppRoute: ComponentType<AppRouteProps> =
    require('@launchpad/components/routing/AppRoute').default;

  return [
    <AppRoute
      path="/external/:type/details/:reference"
      component={ExternalDetails}
      authenticatedOnly
    />,
  ];
};

export default routes;
