import Api from '@launchpad/logic/api/Api';
import React, { useState, useEffect } from 'react';

const AdditionalReferences: React.FC<any> = ({
  entity,
  entityId,
}: {
  entity: string;
  entityId: string;
}) => {
  const [references, setReferences] = useState<any[]>([]);

  useEffect(() => {
    const url =
      entity === 'USER' ? 'admin/as/references' : 'admin/cs/references';
    Api.call(url, 'GET', { entity, entityId }).then((data) => {
      if (data) {
        const actualReferences = (data as { data?: any[] })?.data;
        if (actualReferences) {
          setReferences(actualReferences);
        }
      }
    });
  }, [entity, entityId]);

  return (
    <div style={{ marginTop: 10 }}>
      <h4>Additional references</h4>
      <hr />
      <dl>
        {references.map((reference) => (
          <>
            <dt>{reference.source}:</dt>
            <dd>{reference.value}</dd>
          </>
        ))}
        {references.length === 0 && <p>No additional references</p>}
      </dl>
    </div>
  );
};
export default AdditionalReferences;
