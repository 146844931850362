import Api from '@launchpad/logic/api/Api';
import BaseModel from '@launchpad/logic/model/BaseModel';

export default class AccountOwnershipModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl(params) {
    return `admin/accounts/${params.id}/account-ownership`;
  }

  getSubmitMethod() {
    return 'POST';
  }

  /**
   * Get submit url
   */
  getSubmitUrl(record) {
    return `admin/accounts/${record.id}/assign-ownership`;
  }

  /**
   * Get delete url
   */
  getDeleteUrl(id) {
    return `admin/accounts/${id}/assign-ownership`;
  }

  async delete(id, userId) {
    const result = await Api.call(this.getDeleteUrl(id), 'DELETE', {
      userId,
    });

    return result;
  }

  static getOwnershipTypeOptions() {
    return [
      {
        id: 'DEFAULT',
        name: 'Default',
      },
      {
        id: 'USER',
        name: 'User',
      },
      {
        id: 'ORGANISATION',
        name: 'Organisation',
      },
      {
        id: 'JOINT',
        name: 'Joint',
      },
    ];
  }

  static getPermissionLevelOptions() {
    return [
      {
        id: 'READ',
        name: 'Read',
      },
      {
        id: 'WRITE',
        name: 'Write',
      },
    ];
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: false,
        type: 'field',
      },
      {
        name: 'userId',
        label: 'User Id',
        sortable: false,
        type: 'field',
      },
      {
        name: 'userName',
        label: 'User',
        sortable: false,
        type: 'field',
      },
      {
        name: 'accountId',
        label: 'Account Id',
        sortable: false,
        type: 'field',
      },
      {
        name: 'accountNumber',
        label: 'Account number',
        sortable: false,
        type: 'field',
      },
      // {
      //   name: 'organisationId',
      //   label: 'Organisation Id',
      //   sortable: false,
      //   type: 'field',
      // },
      {
        name: 'ownershipType',
        label: 'Ownership type',
        sortable: false,
        type: 'field',
      },
      // {
      //   name: 'permissionLevel',
      //   label: 'Permission Level',
      //   sortable: false,
      //   type: 'field',
      // },

      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions',
      },
    ];
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      userId: {
        presence: {
          allowEmpty: false,
          message: '^Please select user',
        },
      },
    };
  }
}
