import React from 'react';
import Pagination from 'react-js-pagination';

const AppTablePagination = props => {
  if (!props.paginate) return null;

  if (!props.pagination || props.pagination.totalPages === 0) return null;

  const pages = props.pagination;

  if (pages.totalPages === 1) return null;

  const totalItems = pages.total_items || pages.totalItems;

  return (
    <div className="pull-right">
      <span>Total items: {totalItems}</span>
      <Pagination
        hideFirstLastPages
        innerClass="pagination pull-right"
        itemClass="page-item"
        itemClassFirst="page-item"
        itemClassNext="page-item"
        itemClassPrev="page-item"
        linkClass="page-link"
        linkClassFirst="page-link"
        activePage={props.pagination.page}
        itemsCountPerPage={pages.limit}
        totalItemsCount={totalItems || 0}
        pageRangeDisplayed={10}
        onChange={pageNumber => props.onPageChanged(pageNumber)}
      />
    </div>
  );
};

AppTablePagination.defaultProps = {
  onPageChanged: page => console.log(`changing page to ${page}`),
  paginate: true
};

export default AppTablePagination;
