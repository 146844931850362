import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'shop';

export default class ShopModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'shop.*';
  }

  getPolicy() {
    return 'Admin:Shop:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Shop',
        icon: 'shopping-basket',
        path: '/shop',
        permission: 'shop.*',
        policy: 'Admin:Shop:View:*',
        children: [
          {
            label: 'Products',
            path: '/shop/products',
            icon: 'product-hunt',
            permission: 'shop.products.*',
            policy: 'Admin:Shop:View:*',
          },
          {
            label: 'Categories',
            path: '/shop/categories',
            icon: 'list-alt',
            permission: 'shop.view',
            policy: 'Admin:Shop:View:*',
          },
          {
            label: 'Collections',
            path: '/shop/collections',
            icon: 'list',
            permission: 'user.collections.*',
            policy: 'Admin:Shop:View:*',
          },
          {
            label: ' Purchased Products',
            path: '/shop/purchase-products',
            icon: 'cart-arrow-down',
            permission: 'shop.purchase.*',
            policy: 'Admin:Shop:View:*',
          }
        ]
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute path={`${currentUrl}shop`} load={() => import('./ShopPage')} />
    ];
  }
}
