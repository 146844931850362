import DeleteButton from '@launchpad/components/crud/DeleteButton';
import { currency } from '@launchpad/util/NumberHelper';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import TransactBalances from '../../../../components/TransactBalances';
import ModalDialog from '../../../../../../@launchpad/components/modal/ModalDialog';
import {
  LoadingOverlay,
  OverrideService,
} from '../../../../../../@launchpad/index';
import ChargeFeeForm from '../../../../../../@launchpad/modules/fee/components/ChargeFeeForm';
import RenderIfAllowed from '../../../../../../@launchpad/util/access-control/access-control.component';
import { date } from '../../../../../../@launchpad/util/DateHelper';
import AssignUserToAccountForm from '../../components/AssignUserToAccountForm';
import ChangeAccountForm from '../../components/ChangeAccountForm';
import ChangeForcedAvailableLimit from '../../components/ChangeForcedAvailableLimit';
import AccountsModel from '../../models/AccountsModel';

class AccountBasicInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showChargeFee: false,
      showChangeAccountDetails: false,
      showAssignUserToAccountDetails: false,
    };
  }

  onChargeFeeClose() {
    this.setState(
      {
        showChargeFee: false,
      },
      () => {
        this.props.onRefresh();
      }
    );
  }

  onChangeAccountDetails() {
    this.setState(
      {
        showChangeAccountDetails: false,
      },
      () => {
        this.props.onRefresh();
      }
    );
  }

  onAssignUserToAccountDetails() {
    this.setState(
      {
        showAssignUserToAccountDetails: false,
      },
      () => {
        this.props.onRefresh();
      }
    );
  }

  onCloseAccount(id) {
    new AccountsModel()
      .closeAccount(id, 'closed')
      .then(() => {
        this.props.onRefresh();
      })
      .catch(() => {});
  }

  openChargeFeeModal() {
    this.setState({
      showChargeFee: true,
    });
  }

  openChangeAccountModal() {
    this.setState({
      showChangeAccountDetails: true,
    });
  }

  openAssignUserToAccountModal() {
    this.setState({
      showAssignUserToAccountDetails: true,
    });
  }

  render() {
    const { account } = this.props;

    let status = 'ACTIVE';
    switch (account.status) {
      case 'pending_activation':
        status = 'PENDING ACTIVATION';
        break;
      case 'active':
      case 'ok':
        status = 'ACTIVE';
        break;
      case 'locked':
        status = 'LOCKED';
        break;
      case 'stolen':
        status = 'STOLEN';
        break;
      default:
        status = account.status ? account.status.toUpperCase() : 'UNKNOWN';
    }

    if (account.locked) {
      status = 'LOCKED';
    }

    return (
      <div>
        <div className="row">
          {this.state.loading === true ? <LoadingOverlay /> : null}

          <div className="col-xl-12">
            <div className="element-with-blur">
              <div className="dark-blur" />
              <div className="box-header marginless">
                <h2>
                  <i className="fa fa-address-card" /> Account Summary
                </h2>
              </div>

              <div className="box-body text-white padded">
                <div className="row">
                  <div className="col-sm-6">
                    {!account.name ? (
                      <LoadingOverlay />
                    ) : (
                      <>
                        {OverrideService.getConfig().flags
                          ?.showTransactBalances &&
                        account.product.internalReference.includes('DEBIT') ? (
                          <TransactBalances accountId={account.id} />
                        ) : null}
                        <dl>
                          <dt>
                            <h3>Available spending limit:</h3>
                          </dt>
                          <dd>
                            <h3>
                              {currency(
                                account.availableLimit,
                                account.currency
                              )}
                            </h3>
                          </dd>
                          <dt>Total limit:</dt>
                          <dd> {currency(account.limit, account.currency)}</dd>
                          <dt>Forced available limit:</dt>
                          <dd>
                            <RenderIfAllowed action="Admin:LimitControl:Manage:*">
                              <ChangeForcedAvailableLimit
                                account={account}
                                onUpdate={() => this.props.onRefresh()}
                              />{' '}
                            </RenderIfAllowed>
                            {account.forcedAvailableLimit === null ||
                            account.forcedAvailableLimit === undefined
                              ? '- not set -'
                              : currency(
                                  account.forcedAvailableLimit,
                                  account.currency
                                )}
                          </dd>
                          <dt>Name:</dt>
                          <dd>{account.name}</dd>
                          {account.product?.name ? (
                            <>
                              <dt>Product:</dt>
                              <dd>{account.product.name}</dd>
                            </>
                          ) : null}
                          <dt>Account number:</dt>
                          <dd>{account.accountNumber}</dd>
                          <dt>IBAN:</dt>
                          <dd>{account.iban}</dd>
                          <dt>Sort code:</dt>
                          <dd>{account.sortCode}</dd>
                          <dt>Swift Bic:</dt>
                          <dd>{account.swiftBic}</dd>
                          <dt>Currency:</dt>
                          <dd>{account.currency}</dd>
                          <dt>Balance created:</dt>
                          <dd>{date(account.createdAt, 'D / MMMM / Y')}</dd>
                          <dt>Status:</dt>
                          <dd>{status}</dd>
                          <dt>Account id:</dt>
                          <dd>{account.id}</dd>
                        </dl>
                      </>
                    )}
                  </div>
                  <div className="col-sm-6">
                    {account.status === 'closed' ? (
                      <h3
                        style={{
                          textAlign: 'center',
                          backgroundColor: '#d9534f',
                        }}
                      >
                        This account is closed!
                      </h3>
                    ) : (
                      <>
                        <RenderIfAllowed action="Admin:Account:Manage:*">
                          <h4>Account actions</h4>
                          <hr />
                          <dl>
                            <div style={{ maxWidth: '100%', paddingRight: 5 }}>
                              <dt>Limit Group:</dt>
                              <dd>
                                {account.product?.limitGroup ? (
                                  <Link
                                    // to={`/limits/groups/edit/${account.product.limitGroup.id}`}
                                    to={() => ({
                                      state: { fromAccount: account.id },
                                      pathname: `/limits/groups/edit/${account.product.limitGroup.id}`,
                                    })}
                                    className="button-info btn-block"
                                  >
                                    {account.product?.limitGroup.name || '-'}
                                  </Link>
                                ) : (
                                  '-'
                                )}
                              </dd>
                              <dt>Change account details:</dt>
                              <dd>
                                <button
                                  type="button"
                                  onClick={() => this.openChangeAccountModal()}
                                  className="button-info btn-block"
                                >
                                  Change account details
                                </button>
                              </dd>
                              <dt>Assign account to user or organisation:</dt>
                              <dd>
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.openAssignUserToAccountModal()
                                  }
                                  className="button-info btn-block"
                                >
                                  Assign account
                                </button>
                              </dd>
                              <dt>Charge Fee:</dt>
                              <dd>
                                <button
                                  type="button"
                                  onClick={() => this.openChargeFeeModal()}
                                  className="button-info btn-block"
                                >
                                  Charge Fee
                                </button>
                              </dd>
                              <dt>Close Account:</dt>
                              <dd>
                                <DeleteButton
                                  block
                                  btnText="Close Account"
                                  onConfirm={() =>
                                    this.onCloseAccount(account.id)
                                  }
                                  text={`Are you sure you want to close the account "${`${account.name}`}"?`}
                                />
                              </dd>
                            </div>
                          </dl>
                        </RenderIfAllowed>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <ModalDialog
                show={this.state.showChargeFee}
                onClose={() => this.onChargeFeeClose()}
              >
                <ChargeFeeForm
                  entityId={account.id}
                  entity="ACCOUNT"
                  redirectRoute={`/accounts/details/${account.id}`}
                  customCloseFunction={() => this.onChargeFeeClose()}
                  currency={account.currency}
                />
              </ModalDialog>
              <ModalDialog
                show={this.state.showChangeAccountDetails}
                onClose={() => this.onChangeAccountDetails()}
                customContentStyle="remove-overflow"
              >
                <ChangeAccountForm
                  id={account.id}
                  accountNumber={account.accountNumber}
                  productId={account.productId}
                  redirectRoute={`/accounts/details/${account.id}`}
                  customCloseFunction={() => this.onChangeAccountDetails()}
                />
              </ModalDialog>
              <ModalDialog
                show={this.state.showAssignUserToAccountDetails}
                onClose={() => this.onAssignUserToAccountDetails()}
                customContentStyle="remove-overflow"
              >
                <AssignUserToAccountForm
                  id={account.id}
                  userId={account.userUuid}
                  ownerId={account.owner}
                  organisationId={account.organisationUuid}
                  redirectRoute={`/accounts/details/${account.id}`}
                  customCloseFunction={() =>
                    this.onAssignUserToAccountDetails()
                  }
                />
              </ModalDialog>
            </div>

            <div className="row" style={{ marginTop: '20px' }}>
              <div className="col-xl-12">
                {/* <LastFiveTransactions accountId={this.props.account.id} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AccountBasicInfo);
