import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'legal';

export default class LegalModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'legal.*';
  }

  getPolicy() {
    return 'Admin:Legal:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Legal',
        icon: 'balance-scale',
        path: null,
        permission: 'legal.*',
        policy: 'Admin:Legal:View:*',
        children: [
          {
            label: 'Terms and Conditions',
            path: '/terms',
            icon: 'balance-scale',
            permission: 'legal.terms.*',
            policy: 'Admin:Legal:View:*'
          },
          {
            label: 'Privacy Policy',
            path: '/privacy',
            icon: 'balance-scale',
            permission: 'legal.privacy.*',
            policy: 'Admin:Legal:View:*'
          }
        ]
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}terms`}
        load={() => import('../terms/TermsAndConditions')}
      />,
      <AppRoute
        path={`${currentUrl}privacy`}
        load={() => import('../privacy/Privacy')}
      />
    ];
  }
}
