import React from 'react';
import { AppRoute } from '@launchpad';
import { Switch } from 'react-router-dom';
import NotesList from './NotesList';
import NotesDetails from './details/NotesDetails';
import EditNote from './edit/EditNote';

export default class NotesNavigation extends React.PureComponent {
  render() {
    return (
      <Switch>
        <AppRoute path="/notes/edit-note/:id" component={EditNote} />
        <AppRoute path="/notes/details/:id" component={NotesDetails} />
        <AppRoute path="/notes" component={NotesList} />
      </Switch>
    );
  }
}

