import { AppPage, AppWidget } from '@launchpad/components';
import React, { Component } from 'react';
import UserGroupForm from './components/UserGroupForm';

export default class AddUserGroup extends Component {
  render() {
    return (
      <AppPage title="Notification groups" icon="plus">
        <AppWidget title="Add group" icon={null}>
          <UserGroupForm />
        </AppWidget>
      </AppPage>
    );
  }
}
