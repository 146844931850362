import React from 'react';
import { AppButton, FormHandler, FormSubmitWrapper } from '@launchpad';
import AppSelectWithSearch from '@launchpad/components/crud/AppSelectWithSearch';
import BaseModel from '@launchpad/logic/model/BaseModel';
import { CircularProgress } from '@material-ui/core';
import ToastHelper from '@launchpad/util/ToastHelper';

export default class EntitySelectionForm extends React.Component {
  constructor(props) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new BaseModel({
        value: '',
      }),
      {
        isLoading: false,
      }
    );
  }

  onSubmitSuccess() {
    this.props.customCloseFunction();
  }

  getSubmitMethod() {
    return 'PATCH';
  }

  getSubmitData() {
    return {
      ...this.state.record,
      ownerId: this.state.record.userId,
    };
  }

  _loadingOn() {
    this.setState({
      isLoading: true,
    });
  }

  _loadingOff() {
    this.setState({
      isLoading: false,
    });
  }

  _getModalMessage(data) {
    if (!data.success) {
      return data.message;
    }
    if (data.success) {
      return 'Successfully assigned user to account!';
    }
    return null;
  }

  _getButtonContent() {
    if (this.state.isLoading) {
      return <CircularProgress className="circle-progress" size={30} />;
    }
    return <span>{this.props.submitButtonText ?? 'Save'}</span>;
  }

  async _onDone() {
    if (this.props.onDone) {
      await this.props.onDone();
    }
  }

  async _triggerAction() {
    this._loadingOn();
    try {
      if (this.props.onSubmit) {
        await this.props.onSubmit(this.state.record.value);
        await this._onDone();
      }
    } catch (e) {
      ToastHelper.show(e.message);
    } finally {
      this._loadingOff();
    }
  }

  render() {
    const { formHandler } = this;
    return (
      <div>
        <h3 className="text-center">{this.props.title}</h3>
        <div
          className="element-with-blur"
          style={{ paddingTop: '2px', marginRight: 20 }}
        >
          <div className="add-new-group" style={{ position: 'relative' }}>
            <div className="container">
              <AppSelectWithSearch
                url={this.props.apiPath}
                params={this.props.params}
                label={this.props.label}
                name="value"
                value={this.state.record.value}
                handler={formHandler}
                placeholder={this.props.placeholder}
                wrapperStyle={{ zIndex: 99999 }}
                getOptionValue={this.props.getOptionValue}
                getOptionLabel={this.props.getOptionLabel}
              />

              <div
                className={`form-button-container ${this.props.buttonContainer}`}
              >
                <AppButton
                  type="button"
                  onClick={() => this._onDone()}
                  style={{
                    marginRight: 5,
                    backgroundColor: '#b74343 !important',
                  }}
                  customstyle="custom-danger-btn"
                >
                  {this.props.cancelButtonText ?? 'Cancel'}
                </AppButton>
                <AppButton
                  onClick={() => this._triggerAction()}
                  type="submit"
                  style={{ marginLeft: 5 }}
                >
                  {this._getButtonContent()}
                </AppButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
