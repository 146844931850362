import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'transactions';

export default class TransactionsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'transaction.*';
  }

  getPolicy() {
    return 'Admin:Transaction:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Transactions',
        icon: 'exchange',
        path: '/transaction',
        permission: 'transactions.*',
        policy: 'Admin:Transaction:View:*',
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}transaction`}
        load={() => import('./Transactions')}
      />
    ];
  }
}
