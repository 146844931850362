import BaseModel from '../../../logic/model/BaseModel';

export default class TransactionModel extends BaseModel {
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/transactions';
  }

  /**
   * Get details url
   */
  getDetailsUrl() {
    return 'transaction/details';
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: true,
        type: 'field'
      },
      {
        name: 'note',
        label: 'Note',
        sortable: false,
        type: 'field'
      },
      {
        name: 'amount',
        label: 'Amount',
        sortable: false,
        type: 'field'
      },
      {
        name: 'postDate',
        label: 'Transaction Date',
        sortable: false,
        type: 'field'
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions'
      }
    ];
  }
}
