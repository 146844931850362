import { AppFetcher, AppInfoList, AppPage, AppWidget } from '@launchpad';
import { AppPadding } from '@launchpad/components/widgets/AppPadding';
import moment from 'moment';
import React from 'react';
import VisitsFileRows from '../../components/visits/VisitsFileRows';
import VisitFileModel from '../../models/VisitFileModel';

const VisitsFileDetails = (props) => {
  return (
    <AppFetcher
      model={VisitFileModel}
      id={props.match.params.id}
      isIdInUrl
      renderContent={(record) => {
        const details = [
          {
            label: 'Name',
            value: record.name,
          },
          {
            label: 'Created',
            value: moment(record.created).format('DD/MM/YYYY HH:mm:ss'),
          },
        ];

        const stats = [
          {
            label: 'Row count',
            value: record?.stats?.totalRowCount ?? '-',
          },
        ];
        return (
          <AppPage title="Details of the visits file" icon="list">
            <div className="row">
              <div className="col-sm-6">
                <AppWidget title="Basic info">
                  <AppPadding>
                    <AppInfoList items={details} />
                  </AppPadding>
                </AppWidget>
              </div>
              <div className="col-sm-6" style={{ display: 'none' }}>
                <AppWidget title="Stats">
                  <AppPadding>
                    <AppInfoList items={stats} />
                  </AppPadding>
                </AppWidget>
              </div>
            </div>
            <div style={{ marginTop: 15 }} />
            <div className="row">
              <div className="col-sm-12">
                <VisitsFileRows file={record} />
              </div>
            </div>
          </AppPage>
        );
      }}
    />
  );
};

export default VisitsFileDetails;
