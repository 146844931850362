import { LoadingOverlay } from '@launchpad';
import React, { Component } from 'react';

class OrganisationBasicInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  render() {
    const { customer } = this.props;
    return (
      <div>
        <div className="row">
          {!customer.id ? (
            <LoadingOverlay />
          ) : (
            <div className="col-xl-12">
              <div className="element-with-blur">
                <div className="dark-blur" />
                <div className="box-header marginless">
                  <h2>
                    <i className="fa fa-address-card" /> Account Summary
                  </h2>
                </div>
                <div className="box-body text-white padded">
                  <div className="row">
                    <div className="col-sm-6">
                      <dl>
                        <dt>
                          <h3>Name:</h3>
                        </dt>
                        <dd>
                          <h3>{customer.name}</h3>
                        </dd>
                        <dt>Registration number:</dt>
                        <dd>{customer.registrationNumber}</dd>
                        <dt>Tax number:</dt>
                        <dd>{customer.taxNumber}</dd>
                        <dt>City:</dt>
                        <dd>{customer.address?.city}</dd>
                        <dt>Postcode:</dt>
                        <dd>{customer.address?.postcode}</dd>
                        <dt>Address:</dt>
                        <dd>{customer.address?.addressOne}</dd>
                        <dt>Address Two:</dt>
                        <dd>{customer.address?.addressTwo}</dd>
                      </dl>
                    </div>

                    <div className="col-sm-4 text-center">
                      <img src="/img/card-orders.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default OrganisationBasicInfo;
