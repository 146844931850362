const BreadcrumbHelper = {
  historyPaths: [],
  crumbs: [],
  callables: [],

  addCrumb(label, link = null) {
    this.crumbs.push({
      label,
      link
    });
    this.updated();
  },

  setLastCrumb(label) {
    if (this.crumbs.length > 0) {
      this.crumbs[this.crumbs.length - 1].label = label;
      this.updated();
    }
  },

  setCrumbs(crumbs) {
    this.crumbs = crumbs;
    this.updated();
  },

  clearCrumbs() {
    if (this.crumbs.length > 0) {
      this.setHistoryCrumbs([...this.crumbs]);
    }
    this.setCrumbs([]);
  },

  getHistoryCrumbs() {
    const historyCrumbs = window.localStorage.getItem('APP_HISTORY_CRUMBS');
    let parsedCrumbs;
    if (historyCrumbs) {
      parsedCrumbs = JSON.parse(historyCrumbs);
    }
    return parsedCrumbs || this.historyPaths;
  },

  setHistoryCrumbs(crumbs) {
    window.localStorage.setItem('APP_HISTORY_CRUMBS', JSON.stringify(crumbs));
    this.historyPaths = crumbs;
  },

  getCrumbs() {
    return this.crumbs;
  },

  onUpdate(callable) {
    this.callables.push(callable);
  },

  updated() {
    for (let i = 0; i < this.callables.length; i++) {
      const callable = this.callables[i];
      callable(this.crumbs);
    }
  }
};

export default BreadcrumbHelper;
