import BaseModel from '../../../logic/model/BaseModel';
import Api from '../../../logic/api/Api';

export default class NotesModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/notes';
  }

  /**
   * Get details url
   */
   getDetailsUrl(id) {
    return `admin/notes/${id}`;
  }

  getSubmitMethod() {
    return 'PATCH';
  }

  /**
   * Get submit url
   */
  getSubmitUrl(record) {
    return `admin/notes/${record.id}`;
  }

  /**
   * Get delete url
   */
  getDeleteUrl(id) {
    return `admin/notes/${id}`;
  }

  async delete(id) {
    const result = await Api.call(this.getDeleteUrl(id), 'DELETE');
    return result;
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: false,
        type: 'field',
      },
      {
        name: 'entity',
        label: 'Entity',
        sortable: false,
        type: 'field',
      },
      {
        name: 'entityId',
        label: 'Entity id',
        sortable: false,
        type: 'field'
      },
      {
        name: 'createdUser',
        label: 'Created by user',
        sortable: false,
        type: 'field',
      },
      {
        name: 'message',
        label: 'Message',
        sortable: false,
        type: 'field',
      },
      {
        name: 'createdAt',
        label: 'Created',
        sortable: false,
        type: 'field',
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions',
      },
    ];
  }
}
