import React, { Component } from 'react';
import StatementsDownloadModel from './models/StatementsDownloadModel';
import AppTable from '../../components/crud/AppTable';
import Api from '../../logic/api/Api';

export default class StatementsDownloadList extends Component {
  _tableComponent;

  constructor(props) {
    super(props);
    this.state = {};
  }

  async _triggerDownload(id) {
    const downloadLink = (new StatementsDownloadModel()).getDownloadUrl(id);
    // eslint-disable-next-line no-console
    Api.downloadFileFromUrl(downloadLink, 'GET').catch(console.error);
  }


  render() {

    let param = {};
  
    if(this.props.user){
      param = {
        userId: this.props.user ? this.props.user.id : undefined
      }
    }
    if(this.props.account){
      param = {
        accountId: this.props.account ? this.props.account.id : undefined
      }
    }
    if(this.props.card){
      param = {
        paymentDeviceId: this.props.card ? this.props.card.id : undefined
      }
    }

    return (
      <div className="dashboard-management">
      <AppTable
        icon="file"
        title="Statements"
        onInit={(tableComponent) => (this._tableComponent = tableComponent)}
        params={param}
        filter={[
          {
            name: 'term',
            type: 'term',
          },
        ]}
        model={StatementsDownloadModel}
        getColumnActions={(item, index) => {
          return (
            <div>
              <a
              onClick={() => this._triggerDownload(item.id)}
              className="button-info"
              href="javascript:void(0)"
              >
               <i className="fa fa-fw fa-download" />{' '}
                Download
              </a>
            </div>
          );
        }}
      />
    </div>
    );
  }
}
