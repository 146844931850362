import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Api from '@launchpad/logic/api/Api';
import NoteForm from '../note-form/NoteForm';
import BreadcrumbHelper from '../../../util/BreadcrumbHelper';
import { LoadingOverlay } from '../../../components';

class EditNote extends Component {

  constructor(props) {
    super(props);
    this.state = {};

    if(props.location.state.fromDetails){
      BreadcrumbHelper.setCrumbs([
        {
          label: 'Notes',
          link: '/notes'
        },
        {
          label: `${props.location.state.note.id}`,
          link: `/notes/details/${props.location.state.note.id}`
        }
      ]);
    }else{
      BreadcrumbHelper.setCrumbs([
        {
          label: 'Notes',
          link: '/notes'
        }
      ]);
    }
    BreadcrumbHelper.addCrumb('Edit note');
  }

  render() {
    const {note, fromDetails} = this.props.location.state;
    let backRoute = '/notes'

    if(fromDetails){
      backRoute=`/notes/details/${note.id}`
    }

    return (
      <div>
        <NoteForm title='Edit Note' note={{...note}}
          onNoteEdit={() => this.props.history.push(backRoute)}
        />
      </div>
    );
  }
}


export default withRouter(EditNote);
