/* eslint-disable import/prefer-default-export */
export function getTransactionColor(status) {
  if (status) {
    switch (true) {
      case status.toLowerCase() === 'failed':
      case status.toLowerCase() === 'fail':
      case status.toLowerCase() === 'declined':
      case status.toLowerCase() === 'decline':
      case status.toLowerCase() === 'voided':
      case status.toLowerCase() === 'unsuccsessful':
      case status.toLowerCase() === 'canceled':
        return 'declined';
      case status.toLowerCase() === 'pending':
      case status.toLowerCase() === 'unknown':
      case status.toLowerCase() === 'refund_pending':
        return 'pending';
      case status.toLowerCase() === 'accepted':
      case status.toLowerCase() === 'success':
      case status.toLowerCase() === 'successful':
      case status.toLowerCase() === 'authorized':
      case status.toLowerCase() === 'settled':
      case status.toLowerCase() === 'authorization_reversed':
      case status.toLowerCase() === 'paid':
      case status.toLowerCase() === 'refunded':
        return 'load';
      default:
        return 'load';
    }
  }
  return 'load';
}
