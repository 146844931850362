import React, { ReactNode } from 'react';
import { OverrideService } from '@launchpad/logic/services';
import { useCheckPermission } from '../CustomHooks';
import { PolicyAction, ResourceEntity } from './access-control.enums';
import { ResourceEntityCandidate } from './access-control.types';
import { createAccessControlResource } from './access-control.utils';

interface Props {
  action: PolicyAction;
  resource?: unknown;
  entity?: ResourceEntity;
  renderOnForbidden?: React.ReactNode;
  children: ReactNode;
}

const RenderIfAllowed: React.FC<Props> = ({
  action,
  resource,
  entity,
  children,
  renderOnForbidden
}: Props) => {
  const allowed = useCheckPermission(
    action,
    entity
      ? createAccessControlResource(entity, resource as ResourceEntityCandidate)
      : undefined
  );

  if (!allowed && OverrideService.getConfig().policiesEnabled === true) {
    return <div>{renderOnForbidden || null}</div>;
  }

  return <>{children}</>;
};

RenderIfAllowed.defaultProps = {
  resource: undefined,
  entity: undefined,
  renderOnForbidden: undefined
};

export default RenderIfAllowed;
