import AppTable from '@launchpad/components/crud/AppTable';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import VisitFileModel from '../models/VisitFileModel';
import { UploadNewFileModal } from './UploadNewFileModal';

type UploadNewFileModalProps = {};

export const VisitsFileTable = (props: UploadNewFileModalProps) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  return (
    <>
      <AppTable
        parseUrl={false}
        icon="list"
        title="Visits files"
        filter={[
          {
            name: 'term',
            type: 'term',
          },
        ]}
        order={[
          {
            field: 'id',
            direction: 'desc',
          },
        ]}
        model={VisitFileModel}
        headerActions={
          <button
            type="button"
            className="button width-240"
            style={{ marginRight: '10px' }}
            onClick={() => setModalVisible(true)}
          >
            <i className="fa fa-fw fa-plus" />
            Add new
          </button>
        }
        renderCell={(column, item, index) => {}}
        getColumnActions={(file, index) => {
          return (
            <div>
              <Link
                to={`/priority-pass/visits-files/${file.id}`}
                className="button-info"
              >
                Details
              </Link>
            </div>
          );
        }}
      />
      <UploadNewFileModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        modalText="Please upload visits file!"
        type="VISITS"
      />
    </>
  );
};

export default VisitsFileTable;
