import React from 'react';
import AppBadge from './AppBadge';

const ActiveInactiveAppBadge = (props) => {
    const { active } = props;
    return <AppBadge
      text={active ? 'Active' : 'Inactive'}
      icon={active ? 'check' : 'times'}
      type={active ? 'success' : 'danger'}
    />
}

export default ActiveInactiveAppBadge;
