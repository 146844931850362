import BaseModel from '../../../logic/model/BaseModel';

export default class ChangePasswordModel extends BaseModel {
  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'auth/user/set-password';
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      password: {
        presence: {
          message: '^Please enter a password'
        },
        length: {
          minimum: 5,
          message: '^Your password must be at least 5 characters long'
        }
      },
      repeatedNewPassword: {
        presence: {
          allowEmpty: false,
          message: '^Please repeat the password'
        },
        equality: {
          attribute: "password",
          message: "Passwords don't match"
        }
      }
    };
  }
}
