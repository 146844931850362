let preventScroll = false;

export default class RoutingService {
  static preventScrollTimeout: any;

  static preventScroll() {
    if (this.preventScrollTimeout) {
      try {
        clearTimeout(this.preventScrollTimeout);
      } catch (e) {}
    }

    preventScroll = true;

    this.preventScrollTimeout = setTimeout(() => {
      preventScroll = false;
    }, 500);
  }

  static toTop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }

  static isScrollPrevented() {
    return preventScroll;
  }
}
