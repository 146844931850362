import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'tools';

export default class ToolsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'tools.1.2.3.*';
  }

  getPolicy() {
    return 'Admin:Tool:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Tools',
        icon: 'cog',
        path: null,
        permission: 'tools.*',
        policy: 'Admin:Tool:View:*',
        children: [
          {
            label: 'Bulk Load',
            icon: 'plus',
            path: '/tools/bulk-load',
            permission: 'tools.bulkLoad.*',
            policy: 'Admin:Tool:BulkLoad:View:*',
          }
        ]
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [<AppRoute path={`${currentUrl}tools`} load={() => import('.')} />];
  }
}
