import { AppPage } from '@launchpad';
import React from 'react';
import { MembersFileTable, VisitsFileTable } from '../components';

const PriorityPassDashboard = (props) => {
  return (
    <AppPage title="Priority pass dashboard" icon="list">
      <div className="row">
        <div className="col-sm-6">
          <MembersFileTable />
        </div>
        <div className="col-sm-6">
          <VisitsFileTable />
        </div>
      </div>
    </AppPage>
  );
};

export default PriorityPassDashboard;
