import BaseModel from '../../../../logic/model/BaseModel';
import Api from '../../../../logic/api/Api';
// import PermissionSlugValidate from '../../permissions/services/PermissionSlugValidate';

export default class RolesModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/roles';
  }

  /**
   * Get details url
   */
  getDetailsUrl(id) {
    return `admin/roles/${id}`;
  }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'admin/roles';
  }

  /**
   * Get delete url
   */
  getDeleteUrl(id) {
    return `admin/roles/${id}`;
  }

  async delete(id) {
    const result = await Api.call(this.getDeleteUrl(id), 'DELETE');
    return result;
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'name',
        label: 'Role name',
        sortable: true,
        type: 'field'
      },
      {
        name: 'slug',
        label: 'Slug',
        sortable: false,
        type: 'field'
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions'
      }
    ];
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      name: {
        presence: {
          allowEmpty: false,
          message: '^Please enter role name'
        }
      },
      slug: {
        presence: {
          allowEmpty: false,
          message: '^Please enter permission slug'
        }
      },
      description: {
        presence: {
          allowEmpty: false,
          message: '^Please enter permission description'
        }
      }
    };
  }

  // customValidation(record) {
  //   let text = record.slug;

  //   try {
  //     PermissionSlugValidate.validateSlug(text);
  //   } catch (e) {
  //     return [
  //       {
  //         field: 'slug',
  //         message: e.message
  //       }
  //     ];
  //   }

  //   return [];
  // }
}
