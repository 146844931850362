import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';
import React from 'react';
import PriorityPassNavigation from './PriorityPassNavigation';

export const NAME = 'priority-pass';

export default class PriorityPassModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'priority-pass.*';
  }

  getPolicy() {
    return 'Admin:PriorityPass:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Priority pass',
        icon: 'star',
        path: null,
        permission: 'custom.priority-pass.*',
        policy: 'Admin:PriorityPass:View:*',
        children: [
          {
            label: 'Dashboard',
            icon: 'list',
            path: '/priority-pass',
            permission: 'custom.priority-pass.dashboard.*',
            policy: 'Admin:PriorityPass:View:*',
          },
          {
            label: 'Member files',
            icon: 'list',
            path: '/priority-pass/members-files',
            permission: 'custom.priority-pass.dashboard.*',
            policy: 'Admin:PriorityPass:View:*',
          },
          {
            label: 'Visits files',
            icon: 'list',
            path: '/priority-pass/visits-files',
            permission: 'custom.priority-pass.dashboard.*',
            policy: 'Admin:PriorityPass:View:*',
          },
        ],
      },
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        permission="custom.priority-pass.*"
        path="/priority-pass"
        component={PriorityPassNavigation}
      />,
    ];
  }
}
