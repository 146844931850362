import React, { Component } from 'react';
import { FormSubmitWrapper, FormHandler, InputField } from '@launchpad/index';
import AccountsModel from '../models/AccountsModel';
import { AccountProductTypeModel } from '@launchpad/logic/model/ProductTypeModel';
import AppSelect from '@launchpad/components/crud/AppSelect';

export default class ChangeAccountForm extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new AccountsModel({
        id: props.id,
        accountNumber: props.accountNumber || '',
        productId: props.productId || '',
      })
    );
  }

  onSubmitSuccess() {
    this.props.customCloseFunction();
  }

  getSubmitMethod() {
    return 'PATCH';
  }

  _getModalMessage(data) {
    if (!data.success) {
      return data.message;
    }
    if (data.success) {
      return 'Successfully changed account details!';
    }
    return null;
  }

  render() {
    const { formHandler } = this;

    return (
      <div>
        <h3>Change account details</h3>
        <div
          className="element-with-blur"
          style={{ paddingTop: '2px', marginRight: 20 }}
        >
          <div className="add-new-group" style={{ position: 'relative' }}>
            <div className="container">
              <FormSubmitWrapper
                formHandler={formHandler}
                to={this.props.redirectRoute}
                buttonText={this.props.buttonText}
                customCloseFunction={this.props.customCloseFunction}
                getModalMessage={(data) => this._getModalMessage(data)}
              >
                <InputField
                  materialProps={{
                    fullWidth: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Change account number*"
                  type="text"
                  name="accountNumber"
                  value={this.state.record.accountNumber}
                  handler={formHandler}
                />
                <div style={{ paddingTop: 14, zIndex: 999999999 }}>
                  <AppSelect
                    getOptionValue={(product) => product.id}
                    wrapperStyle={{ marginBottom: 5, zIndex: 999999999 }}
                    textFieldProps={{
                      label: 'Product*',
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                    model={AccountProductTypeModel}
                    name="productId"
                    value={this.state.record.productId}
                    handler={formHandler}
                    placeholder="Search a product"
                  />
                </div>
              </FormSubmitWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ChangeAccountForm.defaultProps = {
  id: null,
  accountNumber: null,
  productId: null,
};
