import React from 'react';
import AddNoteWidget from '../../../../../../components/widgets/AddNoteWidget';
import FetchNotesWidget from '../../../../../../components/widgets/FetchNotesWidget';

export default class CustomerNotesAndAlerts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reloader: false
    };
  }

  render() {
    return (
      <div className="row mardginless">
          <div className="col-sm-5">
            <div className="box-body text-white">
              <div className="dark-blur" />
              <AddNoteWidget
                entity={{ ...this.props.entity, type: this.props.type }}
                onNoteAdded={() =>
                  this.setState(prevState => ({
                    ...prevState,
                    reloader: !prevState.reloader
                  }))
                }
              />
            </div>
          </div>
          <div className="col-sm-7">
            <div className="box-body text-white">
              <div className="dark-blur" />
              <FetchNotesWidget
                key={this.state.reloader}
                headerTitle="Notes"
                entity={{ ...this.props.entity, type: this.props.type }}
              />
            </div>
          </div>
        {/* <div className="element-with-blur" style={{ width: '100%' }}>
          <div className="dark-blur" />
          <div className="box-header">
            <h2>Notes</h2>
          </div>
        </div> */}
      </div>
    );
  }
}
