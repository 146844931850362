import Api from '@launchpad/logic/api/Api';
import BaseModel from '@launchpad/logic/model/BaseModel';

export default class AccountsModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/accounts';
  }

  getSubmitMethod() {
    return 'PATCH';
  }

  /**
   * Get submit url
   */
  getSubmitUrl(record) {
    return `admin/accounts/${record.id}`;
  }

  /**
   * Get delete url
   */
  // getDeleteUrl(id) {
  //   return `admin/account/${id}`;
  // }

  // async delete(id) {
  //   const result = await Api.call(this.getDeleteUrl(id), 'DELETE');
  //   return result;
  // }

  /**
   * Get close account url
   */
  getCloseAccountUrl(id) {
    return `admin/accounts/${id}/status`;
  }

  async updateForcedAvailableLimit(id, forcedAvailableLimit) {
    return Api.call(`admin/accounts/${id}`, 'PATCH', {
      forcedAvailableLimit,
    });
  }

  async closeAccount(id, status) {
    const response = await Api.call(this.getCloseAccountUrl(id), 'POST', {
      status,
    });

    return response.success;
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'accountNumber',
        label: 'Account number',
        sortable: false,
        type: 'field',
      },
      {
        name: 'name',
        label: 'Name',
        sortable: false,
        type: 'field',
      },
      // {
      //   name: 'iban',
      //   label: 'IBAN',
      //   sortable: false,
      //   type: 'field'
      // },
      {
        name: 'availableLimit',
        label: 'Available spending limit',
        sortable: false,
        type: 'field',
      },
      {
        name: 'currency',
        label: 'Currency',
        sortable: false,
        type: 'field',
      },
      {
        name: 'product.name',
        label: 'Limit group',
        sortable: false,
        type: 'field',
      },
      {
        name: 'status',
        label: 'Status',
        sortable: false,
        type: 'field',
      },
      {
        name: 'userUuid',
        label: 'User',
        sortable: false,
        type: 'field',
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions',
      },
    ];
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      accountNumber: {
        presence: {
          allowEmpty: false,
          message: '^Please enter account number',
        },
      },
    };
  }
}
