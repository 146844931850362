import { ModalDialog } from '@launchpad';
import AppTable from '@launchpad/components/crud/AppTable';
import DeleteButton from '@launchpad/components/crud/DeleteButton';
import BreadcrumbHelper from '@launchpad/util/BreadcrumbHelper';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Api from '../../../../../../@launchpad/logic/api/Api';
import RenderIfAllowed from '../../../../../../@launchpad/util/access-control/access-control.component';
import EntitySelectionForm from '../components/EntitySelectionForm';
import OrganisationAccountsModel from '../models/OrganisationAccountsModel';

export default class OrganisationAccounts extends Component {
  constructor(props) {
    super();
    this.state = {
      showInfoModal: false,
      showAssignModal: false,
      infoModalContent: '',
    };
    BreadcrumbHelper.setCrumbs([
      {
        label: 'All organisation accounts',
      },
    ]);
  }

  async onDeleteItem(id) {
    await this.setOrganisationForAccount(id, '');
    this._tableComponent._refresh();
  }

  async setOrganisationForAccount(accountId, organisationId) {
    return Api.call(`admin/accounts/${accountId}/assign`, 'PATCH', {
      organisationId,
    });
  }

  _showAssignModal() {
    this.setState({
      showAssignModal: true,
    });
  }

  _closeAssignModal() {
    this.setState({
      showAssignModal: false,
    });
  }

  async _assignAccountToOrganisation(accountId) {
    const result = await this.setOrganisationForAccount(
      accountId,
      this.props.organisation.id
    );

    if (!result?.success) {
      throw new Error(result.message);
    }

    this._tableComponent._refresh();
  }

  render() {
    const inner = (
      <>
        <AppTable
          title="Accounts"
          onInit={(tableComponent) => (this._tableComponent = tableComponent)}
          headerActions={
            <RenderIfAllowed action="Admin:Organisation:Manage:*">
              <button
                type="button"
                className="button width-240"
                onClick={() => this._showAssignModal()}
              >
                <i className="fa fa-fw fa-plus" />
                Add new
              </button>
            </RenderIfAllowed>
          }
          filter={[{ name: 'term', type: 'term' }]}
          order={[{ field: 'id', direction: 'desc' }]}
          params={{
            organisationId: this.props.organisation
              ? this.props.organisation.id
              : undefined,
          }}
          model={OrganisationAccountsModel}
          getColumnActions={(account, index) => {
            return (
              <div>
                <Link
                  to={`/accounts/details/${account.id}`}
                  className="button-info"
                >
                  Details
                </Link>
                <RenderIfAllowed action="Admin:Organisation:Manage:*">
                  <DeleteButton
                    btnText="Remove"
                    onConfirm={() => this.onDeleteItem(account.id)}
                    text={`Are you sure you wish to remove account "${account.accountNumber}" from this organisation?`}
                  />
                </RenderIfAllowed>
              </div>
            );
          }}
        />
        <ModalDialog
          show={this.state.showInfoModal}
          onClose={() => this.setState({ showInfoModal: false })}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <p style={{ textAlign: 'center', paddingBottom: 20 }}>
              {this.state.infoModalContent}
            </p>
            <button
              type="button"
              onClick={() => this.setState({ showInfoModal: false })}
              className="button-info"
            >
              OK
            </button>
          </div>
        </ModalDialog>

        <ModalDialog
          show={this.state.showAssignModal}
          onClose={() => this._closeAssignModal()}
          customContentStyle="remove-overflow"
        >
          <EntitySelectionForm
            apiPath="admin/accounts"
            title="Assign account to the organisation"
            label="Account"
            params={{
              skipUserValueFilter: true,
            }}
            placeholder="Please select an account"
            onDone={() => this._closeAssignModal()}
            getOptionValue={(account) => account.id}
            getOptionLabel={(account) =>
              `${account.name} (${account.accountNumber})`
            }
            onSubmit={async (selectedId) => {
              await this._assignAccountToOrganisation(selectedId);
            }}
            submitButtonText="Add"
          />
        </ModalDialog>
      </>
    );

    if (this.props.justTable) {
      return inner;
    }

    return (
      <div className="dashboard-management">
        <h1>
          <i className="fa fa-address-card" aria-hidden="true" /> Accounts
        </h1>
        {inner}
      </div>
    );
  }
}
