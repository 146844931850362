import React, { Component } from 'react';
import BreadcrumbHelper from '@launchpad/util/BreadcrumbHelper';
import AppTabs from '@launchpad/components/crud/tabs/AppTabs';
import Api from '@launchpad/logic/api/Api';
import OrganisationMembers from './tabs/OrganisationMembers';
import OrganisationBasicInfo from './tabs/OrganisationBasicInfo';
import OrganisationAccounts from './tabs/OrganisationAccounts';
import OrganisationPaymentDevices from './tabs/OrganisationPaymentDevices';

export default class OrganisationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        id: props.match.params.id,
      },
      activeTab: 'basic',
    };
  }

  componentDidMount() {
    this._getOrganizationDetails();
  }

  getTabs(user) {
    const basicInfo = (
      <OrganisationBasicInfo
        onRefresh={() => this._getOrganizationDetails()}
        customer={user}
      />
    );
    const allTabs = [
      {
        id: 'basic',
        icon: 'info',
        title: 'Basic Info',
        component: basicInfo,
      },
      {
        id: 'users',
        icon: 'users',
        title: 'Users',
        component: <OrganisationMembers organisation={user} justTable />,
      },
      {
        id: 'accounts',
        icon: 'money',
        title: 'Accounts',
        component: <OrganisationAccounts organisation={user} justTable />,
      },
      {
        id: 'paymentDevices',
        icon: 'card',
        title: 'Payment Devices',
        component: <OrganisationPaymentDevices organisation={user} justTable />,
      },
    ];
    return allTabs;
  }

  async _getOrganizationDetails() {
    const { id } = this.props.match.params;
    const getUrl = `admin/organisations/${id}`;

    try {
      const result = await Api.call(getUrl, 'GET');
      this.setState({
        user: result.data,
      });
    } catch (e) {
      console.log('Catch:', e);
    }
  }

  _renderContent(userDetails) {
    if (userDetails.firstName) {
      BreadcrumbHelper.setCrumbs([
        {
          label: `${userDetails.firstName} ${userDetails.lastName}`,
        },
      ]);
    }
    return (
      <div>
        <h1>
          <i className="fa fa-info" aria-hidden="true" /> Organisation Info:
          <span className="ml-2">
            {userDetails.firstName} {userDetails.lastName}
          </span>
        </h1>
        <AppTabs
          onTabChange={(activeTab) => this.setState({ activeTab })}
          activeTab={this.state.activeTab}
          tabs={this.getTabs(userDetails)}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="dashboard-management">
        {this._renderContent(this.state.user)}
      </div>
    );
  }
}
