import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppContainer from './AppContainer';
import AppSpinner from './components/elements/AppSpinner';
import {
  appMountedAction,
  shouldShowIsLandingAction
} from './logic/store/AppActions';
import { refreshAuthAction } from './modules/auth/AuthActions';

class LandingScreen extends Component<any, any> {
  componentDidMount() {
    this.props.setIsLanding(true);
    this.props.appMounted();
    this.props.refreshAuth();
  }

  render() {
    if (this.props.isLanding) {
      return (
        <div>
          <div className="container-fluid login-page">
            <AppSpinner />
          </div>
        </div>
      );
    }
    return <AppContainer />;
  }
}

const mapDispatchToProps = (dispatch: {
  (arg0: { type: string; payload: null }): void;
  (arg0: { type: string }): void;
}) => {
  return {
    appMounted: () => {
      return dispatch(appMountedAction());
    },
    refreshAuth: () => {
      return dispatch(refreshAuthAction());
    },
    setIsLanding: isLanding => {
      return dispatch(shouldShowIsLandingAction(isLanding));
    }
  };
};

const mapStateToProps = (state: { isLanding: any }) => {
  return {
    isLanding: state.isLanding
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingScreen);
