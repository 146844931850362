import React, { Component } from 'react';

export default class AppCheckboxInput extends Component<any, any> {
  static defaultProps: { onChange: (value: any) => void; style: {} };

  constructor(props: any) {
    super(props);

    this.state = {
      value: props.value
    };
  }

  /**
   * Toggle checkbox value
   */
  _toggleValue() {
    this.setState(
      {
        value: !this.props.value
      },
      () => {
        if (this.props.handler) {
          this.props.handler.handleInputChange(
            this.props.name,
            this.state.value
          );
          if (this.props.validateOnChange) {
            this.props.handler.validateField(this.props.name, this.state.value);
          }
        }

        this.props.onChange(this.state.value);
      }
    );
  }

  getErrors() {
    if (this.props.hideValidationErrors) return null;

    const messages: any[] = [];

    this.props.handler
      .getErrors()
      .filter((x: { field: any }) => x.field === this.props.name)
      .forEach((error: { message: any }) => messages.push(error.message));
    if (messages.length === 0) {
      return null;
    }
    return (
      <div
        className="validation-message checkbox-error"
        key={`${this.props.name}-error`}
      >
        {messages.join('<br/>')}
      </div>
    );
  }

  render() {
    return (
      <div
        style={{
          ...{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative'
          },
          ...this.props.style
        }}
      >
        <div
          className="squaredTwo"
          style={{ margin: 0, minWidth: 20, maxWidth: 20, height: 20 }}
        >
          <input
            type="checkbox"
            id={this.props.id}
            name={this.props.name}
            checked={this.props.value}
            onChange={() => this._toggleValue()}
          />
          <label
            htmlFor={this.props.htmlFor}
            style={{ width: 12, height: 12, top: 0, left: 0, margin: 0 }}
          />
        </div>
        <label
          htmlFor={this.props.htmlFor}
          style={{
            cursor: 'pointer',
            color: 'white',
            fontWeight: 'lighter',
            fontSize: 14,
            paddingLeft: 10,
            marginBottom: 0
          }}
        >
          {this.props.labelText}
        </label>
        {/* {errors} */}
      </div>
    );
  }
}

AppCheckboxInput.defaultProps = {
  onChange: value => {},
  style: {}
};
