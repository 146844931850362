import React, { Component } from 'react';
import _ from 'lodash';
import AppSelect from './AppSelect';
import Api from '../../logic/api/Api';

class AppSelectWithSearch extends Component {
  _debounceGetItems = _.debounce(() => this.getItems(), 200);

  /**
   * Constructor
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      limit: 20,
      searchTerm: '',
      items: [],
      totalItems: 0
    };
  }

  componentDidMount() {
    this.getItems();
  }

  async getItems() {
    let { limit } = this.state;

    if (this.state.totalItems > 0) {
      limit = limit > this.state.totalItems ? this.state.totalItems + 5 : limit;
    }

    try {
      let url = `${this.props.url}?page=1&limit=${limit}&value=${this.state.searchTerm}`;

      if (this.props.params) {
        url = `${url}&${new URLSearchParams(this.props.params).toString()}`;
      }

      const result = await Api.call(url, 'GET');

      if (result.data) {
        this.setState({
          items: result.data ? result.data : result,
          // eslint-disable-next-line no-nested-ternary
          totalItems: result.meta
            ? result.meta.totalItems
            : result.pagination
            ? result.pagination.totalItems
            : 0
        });
      }
    } catch (error) {
      // Handled by Api.ts
      console.log('Unexpected error:', error);
    }
  }

  loadItemsOnScroll() {
    // if (this.state.searchTerm !== '') {
    //   return;
    // }
    this.setState(
      prevState => ({
        ...prevState,
        limit: prevState.limit + 20
      }),
      () => {
        this._debounceGetItems();
      }
    );
  }

  searchItem(value) {
    if (value === '') {
      this.setState({
        limit: 20,
        totalItems: 0
      });
    }

    this.setState(
      {
        searchTerm: value
      },
      () => {
        this._debounceGetItems();
      }
    );
  }

  render() {
    return (
      <AppSelect
        wrapperStyle={this.props.wrapperStyle}
        getOptionValue={option => this.props.getOptionValue(option)}
        getOptionLabel={option => this.props.getOptionLabel(option)}
        textFieldProps={{
          label: this.props.label,
          InputLabelProps: {
            shrink: true
          }
        }}
        options={this.state.items}
        name={this.props.name}
        value={this.props.value}
        handler={this.props.handler}
        placeholder={this.props.placeholder}
        {...this.props}
        onMenuScrollToBottom={() => this.loadItemsOnScroll()}
        onInputChange={val => this.searchItem(val)}
      />
    );
  }
}

AppSelectWithSearch.defaultProps = {
  getOptionValue: option => option.id,
  getOptionLabel: option => option.name,
  label: 'User',
  name: 'userId',
  placeholder: 'Search a user',
  wrapperStyle: {},
  value: '',
  url: 'admin/users'
};

export default AppSelectWithSearch;
