import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import 'react-datepicker/dist/react-datepicker.css';
import AppSelect from '@launchpad/components/crud/AppSelect';
import { FormSubmitWrapper, FormHandler, InputField } from '@launchpad/index';
import CountryModel from '@launchpad/logic/model/CountryModel';
import OrganizationsModel from '../models/OrganizationsModel';

export default class OrganisationForm extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new OrganizationsModel({
        name: '',
        registrationNumber: '',
        taxNumber: '',
        addressLineOne: '',
        addressLineTwo: '',
        city: '',
        postcode: '',
        country: '',
      })
    );
  }

  componentDidMount() {
    const { id } = this.props;
    if (id) {
      new OrganizationsModel().get(id).then((result) => {
        this.setState({
          record: result,
        });
      });
    }
  }

  getSubmitData() {
    return {
      ...this.state.record,
      address: {
        addressOne: this.state.record.addressLineOne,
        addressTwo: this.state.record.addressLineTwo,
        city: this.state.record.city,
        postcode: this.state.record.postcode,
        country: this.state.record.country,
      },
    };
  }

  render() {
    const { formHandler } = this;

    return (
      <div>
        <h1>Add new organisation</h1>
        <div
          className="element-with-blur"
          style={{ paddingTop: '2px', marginRight: 20 }}
        >
          <div className="dark-blur" />
          <div className="box-header">
            <h2>Add new organisation</h2>
          </div>
          <div className="add-new-group" style={{ position: 'relative' }}>
            <div className="container">
              <FormSubmitWrapper formHandler={formHandler} to="/organisations">
                <div className="row">
                  <div className="col-md-6">
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      customInput={TextField}
                      label="Name"
                      type="text"
                      name="name"
                      value={this.state.record.name}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Tax Number"
                      type="text"
                      name="taxNumber"
                      value={this.state.record.taxNumber}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="City"
                      type="text"
                      name="city"
                      value={this.state.record.city}
                      handler={formHandler}
                    />

                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Address Line"
                      type="text"
                      name="addressLineOne"
                      value={this.state.record.addressLineOne}
                      handler={formHandler}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      customInput={TextField}
                      label="Registration Number"
                      type="text"
                      name="registrationNumber"
                      value={this.state.record.registrationNumber}
                      handler={formHandler}
                    />
                    <div style={{ paddingTop: 14, zIndex: 99 }}>
                      <AppSelect
                        getOptionValue={(country) => country.iso3}
                        wrapperStyle={{ marginBottom: 5 }}
                        textFieldProps={{
                          label: 'Country',
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                        model={CountryModel}
                        name="country"
                        value={this.state.record.country}
                        handler={formHandler}
                        placeholder="Search a Country"
                      />
                    </div>
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Postcode"
                      type="text"
                      name="postcode"
                      value={this.state.record.postcode}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Address Line 2"
                      type="text"
                      name="addressLineTwo"
                      value={this.state.record.addressLineTwo}
                      handler={formHandler}
                    />
                  </div>
                </div>
              </FormSubmitWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OrganisationForm.defaultProps = {
  title: '',
  id: null,
};
