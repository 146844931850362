import React from 'react';

function CountdownTimer(props: { time: any; timerEnd: () => void }) {
  const [seconds, setSeconds] = React.useState(props.time);

  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      props.timerEnd();
    }
  });

  return <p>{seconds}</p>;
}

export default CountdownTimer;
