import { AppRoute, OverrideService } from '@launchpad';
import React from 'react';
import { Switch } from 'react-router-dom';
import UsersAddAccount from './details/tabs/UsersAddAccount';
import UserDetails from './details/UserDetails';
import EditUser from './edit/EditUser';

export default class UsersNavigation extends React.PureComponent {
  render() {
    return (
      <Switch>
        {OverrideService.getConfig().modules?.user?.editDisabled ? null : (
          <AppRoute path="/user-details/edit-user/:id" component={EditUser} />
        )}
        <AppRoute
          path="/user-details/:id/add-new-account"
          component={UsersAddAccount}
        />
        <AppRoute path="/user-details/:id" component={UserDetails} />
      </Switch>
    );
  }
}
