import BaseModel from './BaseModel';
import Api from '../api/Api';

export default class CountryModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'countries';
  }

  /**
   * Get details url
   */
  getDetailsUrl() {
    return 'country/details';
  }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'country/save';
  }

  /**
   * Get delete url
   */
  getDeleteUrl(id) {
    return `country/delete/${id}`;
  }

  async delete(id) {
    const result = await Api.call(this.getDeleteUrl(id));
    return result;
  }
}
