import { AppPage, AppWidget } from '@launchpad/components';
import Api from '@launchpad/logic/api/Api';
import React, { useEffect, useState } from 'react';
import UserGroupForm from './components/UserGroupForm';

const UserGroupEdit = (props) => {
  const [record, setRecord] = useState()

  useEffect(() => {
      getUserGroupDetails()
  }, [])

  const getUserGroupDetails = async () => {
      const result = await Api.call(
        `admin/user-groups/${props.match.params.id}`,
        'GET',
      );
      if (result.success) {
        setRecord(result.data)
      }
  }
    return (
      <AppPage title="Notification groups" icon="plus">
      <AppWidget title="Edit group" icon={null}>
        {record ? (
        <UserGroupForm record={record} />
        ) : (
          <div />
        )}
      </AppWidget>
    </AppPage>
    );
}

export default UserGroupEdit
