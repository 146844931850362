import React from 'react';
import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';

export const NAME = 'limitcontrol';

export default class LimitControlModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'limitcontrol.*';
  }

  getPolicy() {
    return 'Admin:LimitControl:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Limit Controls',
        icon: 'chain',
        path: null,
        permission: 'limitcontrol.*',
        policy: 'Admin:LimitControl:View:*',
        children: [
          {
            label: 'Limit Groups',
            path: '/limits/groups',
            icon: 'list-ul',
            permission: 'limitcontrol.groups.*',
            policy: 'Admin:LimitControl:View:*',
          },
          {
            label: 'Limit Adjustments',
            path: '/limits/limits',
            icon: 'lock',
            permission: 'limitcontrol.limits.*',
            policy: 'Admin:LimitControl:View:*',
          },
          {
            label: 'Products',
            path: '/products',
            icon: 'cart-arrow-down',
            permission: 'limitcontrol.products.*',
            policy: 'Admin:LimitControl:View:*',
          },
        ],
      },
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}limits`}
        load={() => import('./LimitControlPage')}
      />,
      <AppRoute
        permission="limitcontrol.products.*"
        policy="Admin:LimitControl:Product:View:*"
        path={`${currentUrl}products`}
        load={() => import('./LimitProductPage')}
      />,
    ];
  }
}
