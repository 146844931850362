import { ModalDialog } from '@launchpad';
import AppTable from '@launchpad/components/crud/AppTable';
import DeleteButton from '@launchpad/components/crud/DeleteButton';
import Api from '@launchpad/logic/api/Api';
import BreadcrumbHelper from '@launchpad/util/BreadcrumbHelper';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import RenderIfAllowed from '../../../../../../@launchpad/util/access-control/access-control.component';
import EntitySelectionForm from '../components/EntitySelectionForm';
import OrganisationPaymentDevicesModel from '../models/OrganisationPaymentDevicesModel';

export default class OrganisationPaymentDevices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoModal: false,
      showAssignModal: false,
      infoModalContent: '',
    };
    BreadcrumbHelper.setCrumbs([
      {
        label: 'All organisation members',
      },
    ]);
  }

  async onDeleteItem(id) {
    await this.setOrganisationForPaymentDevice(id, '');
    this._tableComponent._refresh();
  }

  async setOrganisationForPaymentDevice(paymentDeviceId, organisationId) {
    return Api.call(
      `admin/payment-devices/${paymentDeviceId}/reassign`,
      'POST',
      {
        organisationId,
      }
    );
  }

  _showAssignModal() {
    this.setState({
      showAssignModal: true,
    });
  }

  _closeAssignModal() {
    this.setState({
      showAssignModal: false,
    });
  }

  async _assignPaymentDeviceToOrganisation(paymentDeviceId) {
    const result = await this.setOrganisationForPaymentDevice(
      paymentDeviceId,
      this.props.organisation.id
    );

    if (!result?.success) {
      throw new Error(result.message);
    }

    this._tableComponent._refresh();
  }

  render() {
    const inner = (
      <>
        <AppTable
          title="Payment devices"
          onInit={(tableComponent) => {
            this._tableComponent = tableComponent;
          }}
          headerActions={
            <RenderIfAllowed action="Admin:Organisation:Manage:*">
              <button
                type="button"
                className="button width-240"
                onClick={() => this._showAssignModal()}
              >
                <i className="fa fa-fw fa-plus" />
                Add new
              </button>
            </RenderIfAllowed>
          }
          filter={[{ name: 'term', type: 'term' }]}
          order={[{ field: 'id', direction: 'desc' }]}
          params={{
            organisationId: this.props.organisation
              ? this.props.organisation.id
              : undefined,
          }}
          model={OrganisationPaymentDevicesModel}
          renderCell={(column, item, index) => {
            switch (column.name) {
              case 'userId':
                return (
                  <Link
                    className="userCardLink"
                    to={`/user-details/${item.userId}`}
                  >
                    {item.user
                      ? `${item.user.firstName} ${item.user.lastName}`
                      : item.userId}
                  </Link>
                );
              default:
            }
          }}
          getColumnActions={(paymentDevice, index) => {
            return (
              <div>
                <Link
                  to={`/cards/details/${paymentDevice.id}`}
                  className="button-info"
                >
                  Details
                </Link>
                <RenderIfAllowed action="Admin:Organisation:Manage:*">
                  <DeleteButton
                    btnText="Remove"
                    onConfirm={() => this.onDeleteItem(paymentDevice.id)}
                    text={`Are you sure you wish to remove payment device "${paymentDevice.name} (${paymentDevice.vendorReference})" from this organisation?`}
                  />
                </RenderIfAllowed>
              </div>
            );
          }}
        />
        <ModalDialog
          show={this.state.showInfoModal}
          onClose={() => this.setState({ showInfoModal: false })}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <p style={{ textAlign: 'center', paddingBottom: 20 }}>
              {this.state.infoModalContent}
            </p>
            <button
              type="button"
              onClick={() => this.setState({ showInfoModal: false })}
              className="button-info"
            >
              OK
            </button>
          </div>
        </ModalDialog>
        <ModalDialog
          show={this.state.showAssignModal}
          onClose={() => this._closeAssignModal()}
          customContentStyle="remove-overflow"
        >
          <EntitySelectionForm
            apiPath="admin/payment-devices"
            title="Assign payment device to the organisation"
            label="Payment device"
            params={{
              skipUserValueFilter: true,
            }}
            placeholder="Please select a payment device"
            onDone={() => this._closeAssignModal()}
            getOptionValue={(paymentDevice) => paymentDevice.id}
            getOptionLabel={(paymentDevice) =>
              `${paymentDevice.name} (${paymentDevice.pan}, ${paymentDevice.vendorReference})`
            }
            onSubmit={async (selectedId) => {
              await this._assignPaymentDeviceToOrganisation(selectedId);
            }}
            submitButtonText="Add"
          />
        </ModalDialog>
      </>
    );

    if (this.props.justTable) {
      return inner;
    }

    return (
      <div className="dashboard-management">
        <h1>
          <i className="fa fa-users" aria-hidden="true" /> Users
        </h1>
        {inner}
      </div>
    );
  }
}
