import { AppBadge } from '@launchpad';
import AppTable from '@launchpad/components/crud/AppTable';
import { date } from '@launchpad/util/DateHelper';
import { currency } from '@launchpad/util/NumberHelper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toHumanString } from '@launchpad/util/StringHelper';
import RenderIfAllowed from '../../../../../../@launchpad/util/access-control/access-control.component';
import CardModel from '../../../cards/models/CardModel';

export default class AccountCards extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _renderStatus(item) {
    switch (item.status) {
      case 'active':
      case 'ok':
        return <AppBadge text="OK" icon="check" />;
      case 'locked':
        return <AppBadge text="Locked" icon="lock" type="danger" />;
      case 'stolen':
        return <AppBadge text="Stolen" icon="ban" type="danger" />;
      case 'inactive':
        return <AppBadge text="Inactive" icon="ban" type="danger" />;
      case 'expired':
        return <AppBadge text="Expired" icon="ban" type="danger" />;
      case 'destroyed':
        return <AppBadge text="Destroyed" icon="ban" type="danger" />;
      case 'restricted':
        return <AppBadge text="Restricted" icon="ban" type="danger" />;
      case 'voided':
        return <AppBadge text="Voided" icon="ban" type="danger" />;
      case 'capture':
        return <AppBadge text="Capture" icon="ban" type="danger" />;
      case 'closed':
        return <AppBadge text="Closed" icon="ban" type="danger" />;
      case 'do_not_honour':
        return <AppBadge text="Do Not Honour" icon="ban" type="danger" />;
      case 'lost':
        return <AppBadge text="Lost" icon="ban" type="danger" />;
      case 'security_violation':
        return <AppBadge text="Security violation" icon="ban" type="danger" />;
      case 'refunded':
        return <AppBadge text="Refunded" icon="ban" type="danger" />;
      case 'pending_activation':
        return (
          <AppBadge text="Pending activation" icon="clock-o" type="info" />
        );
      case 'pending_initialisation':
        return (
          <AppBadge
            text="Pending initialisation"
            icon="clock-o"
            type="warning"
          />
        );
      case 'cardholder_to_contact_issuer':
        return (
          <AppBadge
            text="Cardholder to contact issuer"
            icon="clock-o"
            type="info"
          />
        );
      default:
        return (
          <AppBadge
            text={toHumanString(item.status)}
            icon="clock-o"
            type="info"
          />
        );
    }
  }

  /**
   * Render
   *
   * @returns {XML}
   */
  render() {
    return (
      <div className="dashboard-management">
        <AppTable
          icon="credit-card-alt"
          title="Cards"
          params={{
            accountId: this.props.account ? this.props.account.id : undefined,
          }}
          filter={[
            {
              name: 'term',
              type: 'term',
            },
          ]}
          headerActions={
            <RenderIfAllowed action="Admin:PaymentDevice:Manage:*">
              <Link
                to={`${this.props.account.id}/add-new-card`}
                className="button width-240"
                style={{ marginRight: '10px' }}
              >
                <i className="fa fa-fw fa-plus" />
                Add new
              </Link>
            </RenderIfAllowed>
          }
          renderCell={(column, item, index) => {
            switch (column.name) {
              case 'user.fullName':
                const fullName = [item.user.firstName, item.user.lastName]
                  .filter(Boolean)
                  .join(' ');

                return (
                  <Link
                    className="userCardLink"
                    to={`/user-details/${item.user.id}`}
                  >
                    {fullName}
                  </Link>
                );
              case 'availableLimit':
                return item.account
                  ? currency(item.availableLimit, item.account.currency)
                  : null;
              case 'status':
                return this._renderStatus(item);
              case 'balance':
                return item.account
                  ? currency(
                      item.account.availableBalance,
                      item.account.currency
                    )
                  : null;
              case 'expiryDate':
                return date(item.expiryDate, 'MM / YY');
              case 'type':
                return item.userId === item.ownerId
                  ? 'Personal Card'
                  : 'Additional Card';
              case 'product.name':
                return `${item.product?.name} (${item.product?.currency})`;
              default:
            }
          }}
          model={CardModel}
          getTableColumns={() => [
            {
              name: 'id',
              label: 'Id',
              sortable: false,
              type: 'field',
            },
            {
              name: 'user.fullName',
              label: 'User',
              sortable: false,
              type: 'field',
            },
            {
              name: 'product.name',
              label: 'Product name',
              sortable: false,
              type: 'field',
            },
            {
              name: 'availableLimit',
              label: 'Available spending limit',
              sortable: false,
              type: 'field',
            },
            {
              name: 'pan',
              label: 'PAN',
              sortable: false,
              type: 'field',
            },
            {
              name: 'expiryDate',
              label: 'Expires',
              sortable: false,
              type: 'field',
            },
            {
              name: 'status',
              label: 'Status',
              sortable: false,
              type: 'field',
            },
            {
              name: null,
              label: 'Actions',
              sortable: false,
              type: 'actions',
            },
          ]}
          getColumnActions={(member, index) => {
            return (
              <div>
                <Link
                  to={`/cards/details/${member.id}`}
                  className="button-info"
                >
                  Details
                </Link>
              </div>
            );
          }}
        />
      </div>
    );
  }
}

// Inject router
AccountCards.contextTypes = {
  router: PropTypes.object,
};
