import AppActiveSign from '@launchpad/components/elements/AppActiveSign';
import { AppInfoList } from '@launchpad/components/widgets';
import moment from 'moment';
import React from 'react';

export default class GroupInfo extends React.Component {
  render() {
    const { group } = this.props;

    const details = [
      {
        label: 'Name',
        value: group.name,
      },
      {
        label: 'Created',
        value: moment(group.created).format('DD/MM/YYYY HH:mm:ss'),
      },
      {
        label: 'User count',
        value: group.userCount || 0,
      },
      {
        label: 'Is test group',
        value: <AppActiveSign value={group.isTestGroup} />,
      },
    ];

    return <AppInfoList items={details} />;
  }
}
