import React, { Component } from 'react';
import AccountAllAvailableLimitsModel from '../../models/AccountAllAvailableLimitsModel';
import ConfiguredLimits from '../../../limit-control/components/ConfiguredLimits';

export default class AccountAllAvailableLimits extends Component {
  _tableComponent;

  render() {
    const { id } = this.props.match.params;
    const { account } = this.props;

    return (
      <div className="dashboard-management">
        <ConfiguredLimits
          id={id}
          entity={account}
          model={AccountAllAvailableLimitsModel}
        />
      </div>
    );
  }
}
