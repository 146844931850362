import BaseModel from '../../../logic/model/BaseModel';

export default class ScaCodeModel extends BaseModel {
  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'auth/authorization/confirm';
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      secret: {
        presence: {
          message: '^Please enter the code'
        }
      }
    };
  }
}
