import BaseModel from '../../../logic/model/BaseModel';

export default class StatementsDownloadModel extends BaseModel {
  /**
   * Get Method
   */
   getMethod() {
    return 'GET';
  }

  getListUrl() {
    return 'admin/statements/list';
  }

  getDownloadUrl(id) {
    return `admin/statements/${id}/download`;
  }

  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: true,
        type: 'field'
      },
      {
        name: 'title',
        label: 'Title',
        sortable: false,
        type: 'field'
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions'
      }
    ]
  }
}
