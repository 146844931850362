import React from 'react';
import { Link } from 'react-router-dom';
import Api from '@launchpad/logic/api/Api';

export default class OrganisationInfoWidget extends React.Component {
  constructor(props) {
    super();
    this.state = {
      organisation: null,
    };
  }

  componentDidMount() {
    this._getOrganisation(this.props.organisationId);
  }

  async _getOrganisation(organisationId) {
    if (!organisationId) return;

    const getUrl = `admin/organisations/${organisationId}`;

    try {
      const result = await Api.call(getUrl, 'GET');

      this.setState({
        organisation: result.data,
      });
    } catch (e) {
      console.log(e);
    }

    return null;
  }

  render() {
    const { organisation } = this.state;

    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="m-0">Organisation</h4>
          <div style={{ maxWidth: '200px', marginRight: 5 }}>
            {organisation && (
              <Link
                to={`/organisations/${organisation.id}`}
                className="button-info btn-block"
              >
                Full details
              </Link>
            )}
          </div>
        </div>
        <hr />
        {!organisation && (
          <center>
            <em>- not attached to any organisations -</em>
          </center>
        )}
        {organisation && (
          <dl>
            <dt>Name:</dt>
            <dd>{organisation.name}</dd>
            <dl>
              <dt>Address line one:</dt>
              <dd>{organisation?.address?.addressOne ?? '-'}</dd>
              <dt>Address line two:</dt>
              <dd>{organisation?.address?.addressTwo ?? '-'}</dd>
              <dt>Postcode:</dt>
              <dd>{organisation?.address?.postcode ?? '-'}</dd>
              <dt>City:</dt>
              <dd>{organisation?.address?.city ?? '-'}</dd>
              <dt>Country:</dt>
              <dd>{organisation?.address?.country ?? '-'}</dd>
            </dl>
          </dl>
        )}
      </>
    );
  }
}
