import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'configuration';

export default class ConfigurationModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'configuration.*';
  }

  getPolicy() {
    return 'Admin:Configuration:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Configuration',
        icon: 'cog',
        path: null,
        permission: 'configuration.*',
        policy: 'Admin:Configuration:View:*',
        children: [
            {
              label: 'Billing Packages',
              path: '/configuration/billing-packages',
              icon: 'money',
              permission: 'configuration.billing-packages',
              policy: 'Admin:Configuration:View:*',
            },
            {
              label: 'Billing Types',
              path: '/configuration/billing-types',
              icon: 'sitemap',
              permission: 'configuration.billing-types',
              policy: 'Admin:Configuration:View:*',
            },
        ]
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}configuration`}
        load={() => import('./ConfigurationPage')}
      />
    ];
  }
}
