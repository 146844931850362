import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotesModel from './models/NotesModel';
import AppTable  from "../../components/crud/AppTable";
import DeleteButton from '../../components/crud/DeleteButton';
import ModalDialog from '../../components/modal/ModalDialog';
import BreadcrumbHelper from '../../util/BreadcrumbHelper';
import { date } from "../../util/DateHelper";

export default class NotesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoModal: false
    };

    BreadcrumbHelper.setCrumbs([
      {
        label: 'Notes'
      }
    ]);
  }

  onClose() {
    this.setState({
      showInfoModal: false
    });
  }

  onDeleteNote(id) {
    new NotesModel()
      .delete(id)
      .then(() => {
        this._tableComponent._refresh();
      })
      .catch(() => {
        this.setState({
          showInfoModal: true
        });
      });
  }

  _renderEntityId(item){
    let path = null;

    switch (item.entity) {
      case 'USER':
        path = '/user-details/'
        break;
      case 'ACCOUNT':
        path = '/accounts/details/'
        break;
      case 'PAYMENT_DEVICE':
        path = '/cards/details/'
        break;
      case 'TRANSACTION':
        path = '/transaction/details/'
        break;
      default:
    }

    if(!path){
      return <span>{item.entityId}</span>
    }
    return (
        <Link
          className="userCardLink"
          to={`${path}${item.entityId}`}
        >
          {item.entityId}
        </Link>
      );
  }

  /**
   * Render
   *
   * @returns {XML}
   */
  render() {
    return (
      <div className="dashboard-management">
        <h1>
          <i className="fa fa-files-o" aria-hidden="true" /> Notes
        </h1>
        <AppTable
          icon="files-o"
          title="Notes"
          onInit={tableComponent => (this._tableComponent = tableComponent)}
          filter={[
            {
              name: 'newTerm',
              type: 'term',
              label: 'Message'  
            },
            {
              name: 'user',
              type: 'term',
              label: 'User'
            },
            {
              name: 'entity',
              type: 'select',
              field: 'entity',
              label: 'Entity',
              // style: { minWidth: 250 },
              options: {
                empty: '',
                placeholder: 'Select entity',
                disableUnderline: false,
                options: [
                  {
                    id: 'USER',
                    name: 'USER'
                  },
                  {
                    id: 'ACCOUNT',
                    name: 'ACCOUNT'
                  },
                  {
                    id: 'PAYMENT_DEVICE',
                    name: 'PAYMENT DEVICE'
                  },
                  {
                    id: 'TRANSACTION',
                    name: 'TRANSACTION'
                  }
                ]
              }
            },
            {
              name: 'entityId',
              type: 'term',
              label: 'Entity id'
            }
          ]}
          model={NotesModel}
              // eslint-disable-next-line consistent-return
              renderCell={(column, item, index) => {
                switch (column.name) {
                  case 'entity':
                    return item.entity.replaceAll('_', ' ');
                  case 'createdAt':
                    return date(item.createdAt, 'DD / MM / YYYY HH:mm');
                  case 'entityId':
                    return this._renderEntityId(item);
                  case 'createdUser':
                    return `${item.userFirstName} ${item.userLastName}`;
                  default:
                }
              }}
          getColumnActions={(note, index) => {
            return (
              <div>
                <Link to={`/notes/details/${note.id}`} className="button-info">
                  Details
                </Link>
                <Link 
                  to={{
                    pathname: `/notes/edit-note/${note.id}`,
                    state:{note, fromDetails: false}
                  }}
                  className="button-info">
                  Edit
                </Link>
                <DeleteButton
                  onConfirm={() => this.onDeleteNote(note.id)}
                  text={`Are you sure you wish to delete Note "${note.id}"?`}
                />
              </div>
            );
          }}
        />
        <ModalDialog
          show={this.state.showInfoModal}
          onClose={this.onClose.bind(this)}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <p style={{ textAlign: 'center', paddingBottom: 20 }}>
              You can not delete this Note!
            </p>
            <button type='button' onClick={this.onClose.bind(this)} className="button-info">
              OK
            </button>
          </div>
        </ModalDialog>
      </div>
    );
  }
}

// Inject router
NotesList.contextTypes = {
  router: PropTypes.object
};
