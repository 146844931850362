import { ModalDialog } from '@launchpad';
import AppTable from '@launchpad/components/crud/AppTable';
import DeleteButton from '@launchpad/components/crud/DeleteButton';
import BreadcrumbHelper from '@launchpad/util/BreadcrumbHelper';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import RenderIfAllowed from '../../../../../../@launchpad/util/access-control/access-control.component';
import OrganisationMembersModel from '../models/OrganisationMembersModel';

export default class OrganisationMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoModal: false,
      infoModalContent: '',
    };
    BreadcrumbHelper.setCrumbs([
      {
        label: 'All organisation members',
      },
    ]);
  }

  onDeleteItem(id) {
    new OrganisationMembersModel()
      .delete(id)
      .then(() => {
        this._tableComponent._refresh();
      })
      .catch(() => {
        this.setState({
          showInfoModal: true,
          infoModalContent: 'You can not remove this user from organisation!',
        });
      });
  }

  render() {
    const inner = (
      <>
        <AppTable
          title="Users"
          onInit={(tableComponent) => {
            this._tableComponent = tableComponent;
          }}
          headerActions={
            <RenderIfAllowed action="Admin:Organisation:Manage:*">
              <Link
                to={`/organisation-member/${this.props.organisation.id}/add-new`}
                className="button width-240"
                style={{ marginRight: '10px' }}
              >
                <i className="fa fa-fw fa-plus" />
                Add new
              </Link>
            </RenderIfAllowed>
          }
          filter={[{ name: 'term', type: 'term' }]}
          order={[{ field: 'id', direction: 'desc' }]}
          params={{
            organisationId: this.props.organisation
              ? this.props.organisation.id
              : undefined,
          }}
          model={OrganisationMembersModel}
          getColumnActions={(member, index) => {
            return (
              <div>
                <Link
                  to={`/user-details/${member.user.id}`}
                  className="button-info"
                >
                  Details
                </Link>

                <RenderIfAllowed action="Admin:Organisation:Manage:*">
                  <DeleteButton
                    btnText="Remove"
                    onConfirm={() => this.onDeleteItem(member.id)}
                    text={`Are you sure you wish to remove user "${member.user.firstName} ${member.user.lastName}" from this organisation?`}
                  />
                </RenderIfAllowed>
                {/* <button className="button-info">Identify Customer</button> */}
              </div>
            );
          }}
        />
        <ModalDialog
          show={this.state.showInfoModal}
          onClose={() => this.setState({ showInfoModal: false })}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <p style={{ textAlign: 'center', paddingBottom: 20 }}>
              {this.state.infoModalContent}
            </p>
            <button
              type="button"
              onClick={() => this.setState({ showInfoModal: false })}
              className="button-info"
            >
              OK
            </button>
          </div>
        </ModalDialog>
      </>
    );

    if (this.props.justTable) {
      return inner;
    }

    return (
      <div className="dashboard-management">
        <h1>
          <i className="fa fa-users" aria-hidden="true" /> Users
        </h1>
        {inner}
      </div>
    );
  }
}
