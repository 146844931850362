import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'accounts';

export default class AccountsModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'accounts.*';
  }

  getPolicy() {
    return 'Admin:Account:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Accounts',
        icon: 'address-card',
        path: null,
        permission: 'accounts.*',
        policy: 'Admin:Account:View:*',
        children: [
          {
            label: 'Accounts',
            icon: 'address-card',
            path: '/accounts',
            permission: 'accounts.view',
            policy: 'Admin:Account:View:*',
          },
          {
            label: 'Account Products',
            path: '/accounts/account-products',
            icon: 'product-hunt',
            permission: 'accounts.products',
            policy: 'Admin:Account:View:*',
          }
        ]
      }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}accounts`}
        load={() => import('./Accounts')}
      />
    ];
  }
}
