import React, { Component } from 'react';
import {
  FormSubmitWrapper,
  FormHandler,
  InputField,
  ModalDialog,
  AppCheckboxInput,
} from '@launchpad/index';

export default class EditEmbossData extends Component {
  constructor(props) {
    super(props);
    // Initial state
    const Model = new props.Model({
      ...this.props.additionalData,
      id: props.id,
    });

    this.formHandler = new FormHandler(this, Model);
  }

  onSubmitSuccess() {
    this.props.onSubmitSuccess();
  }

  getSubmitData() {
    const { updateAllCards, ...submitData } = this.state.record;
    return {
      embossLine3: submitData.embossLine3,
      embossLine4: submitData.embossLine4,
      updateConfiguration: {
        updateAllCards,
      },
    };
  }

  getModalMessage(data) {
    if (!data.success) {
      return data.message;
    }
    if (data.success) {
      return 'Successfully changed emboss details!';
    }
    return null;
  }

  render() {
    const { formHandler } = this;
    return (
      <ModalDialog
        show={this.props.showModal}
        onClose={() => this.props.onClose()}
        customContentStyle="remove-overflow"
      >
        <div>
          <h3>Edit emboss data</h3>
          <div
            className="element-with-blur"
            style={{ paddingTop: '2px', marginRight: 20 }}
          >
            <div className="add-new-group" style={{ position: 'relative' }}>
              <div className="container">
                <FormSubmitWrapper
                  formHandler={formHandler}
                  buttonText={this.props.buttonText}
                  customCloseFunction={() => this.props.onClose()}
                  getModalMessage={(data) => this.getModalMessage(data)}
                >
                  <InputField
                    materialProps={{
                      fullWidth: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Emboss cardholder name"
                    type="text"
                    name="embossLine3"
                    value={this.state.record.embossLine3}
                    handler={formHandler}
                  />
                  <InputField
                    materialProps={{
                      fullWidth: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Emboss company name"
                    type="text"
                    name="embossLine4"
                    value={this.state.record.embossLine4}
                    handler={formHandler}
                  />
                  {this.props.updateForAllCards && (
                    <AppCheckboxInput
                      name="updateAllCards"
                      value={this.state.record.updateAllCards}
                      id="updateAllCards"
                      htmlFor="updateAllCards"
                      labelText="Update for all cards"
                      handler={formHandler}
                      style={{ marginTop: 20 }}
                    />
                  )}
                </FormSubmitWrapper>
              </div>
            </div>
          </div>
        </div>
      </ModalDialog>
    );
  }
}
