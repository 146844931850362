import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getTransactionColor } from '@launchpad/util/ColorHelper';
import { LoadingOverlay } from '@launchpad/components';
import AppTable from '@launchpad/components/crud/AppTable';
import TransactionModel from '../../models/TransactionsModel';
import { currency } from '../../../../util/NumberHelper';
import { date } from '../../../../util/DateHelper';

export default class AccountTransactions extends Component {
  render() {
    const { account } = this.props;

    return (
      <div className="dashboard-management">
        {!account || !account.id ? (
          <LoadingOverlay />
        ) : (
          <AppTable
            icon="exchange"
            title="Transactions"
            params={{
              accountId: account.id
            }}
            filter={[
              {
                name: 'term',
                type: 'term'
              }
            ]}
            renderCell={(column, item) => {
              if (column.name === 'amount') {
                return currency(item.amount, item.amountCurrency);
              }
              if (column.name === 'postDate') {
                return date(item.postDate, 'D / MMMM / Y');
              }
            }}
            getRowClass={item => {
              if (!item) return null;
              if (item && item.status) {
                return getTransactionColor(item.status);
              }
            }}
            model={TransactionModel}
            getColumnActions={member => {
              return (
                <div>
                  <Link
                    to={`/transaction/details/${member.id}`}
                    className="button-info"
                  >
                    Details
                  </Link>
                </div>
              );
            }}
          />
        )}
      </div>
    );
  }
}
