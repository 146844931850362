import { ModalDialog } from '@launchpad';
import AppTable, { IdButton } from '@launchpad/components/crud/AppTable';
import React, { useState } from 'react';
import MemberFileRowModel from '../../models/MemberFileRowModel';

type UploadNewFileModalProps = {
  file: { id: string };
};

type MemberRow = {
  id: string;
  memberNumber: string;
  token: string;
  createdAt: string;
  additionalData: Record<string, string>;
};

export const MembersFileRows = (props: UploadNewFileModalProps) => {
  const [currentItem, setCurrentItem] = useState<MemberRow | null>(null);
  return (
    <>
      <ModalDialog show={!!currentItem} onClose={() => setCurrentItem(null)}>
        {currentItem ? (
          <div className="main">
            <div className="customers-info">
              <div className="customer-details">
                <h2 className="customer-name">
                  <span className="company">{currentItem.id}</span>
                </h2>
              </div>

              <div className="customers-main">
                <div className="row">
                  <div className="col">
                    <div className="padded">
                      <div className="row text-white">
                        <div className="col-lg-12">
                          <h4>Info</h4>
                          <hr />
                          <dl>
                            <dt>Id:</dt>
                            <dd>{currentItem.id || '-'}</dd>
                          </dl>
                          <dl>
                            <dt>Memer number:</dt>
                            <dd>{currentItem.memberNumber || '-'}</dd>
                          </dl>
                          <dl>
                            <dt>Token:</dt>
                            <dd>{currentItem.token || '-'}</dd>
                          </dl>
                          <dl>
                            <dt>Other data:</dt>
                            <dd>
                              {currentItem.additionalData ? (
                                <pre
                                  style={{ textAlign: 'left', color: 'white' }}
                                >
                                  {JSON.stringify(
                                    currentItem.additionalData,
                                    null,
                                    2
                                  )}
                                </pre>
                              ) : (
                                '-'
                              )}
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </ModalDialog>
      <AppTable
        icon="list"
        title="File rows"
        filter={[
          {
            name: 'term',
            type: 'term',
          },
        ]}
        order={[
          {
            field: 'id',
            direction: 'desc',
          },
        ]}
        params={{
          id: props.file.id,
        }}
        model={MemberFileRowModel}
        renderCell={(column, item, index) => {
          switch (column.name) {
            case 'id':
              return <IdButton value={item.id} />;
            default:
              return undefined;
          }
        }}
        getColumnActions={(file, index) => {
          return (
            <div>
              <button
                type="button"
                onClick={() => setCurrentItem(file)}
                className="button-info"
              >
                Details
              </button>
            </div>
          );
        }}
      />
    </>
  );
};

export default MembersFileRows;
