import React from 'react';
import BaseModule from '../../logic/module/BaseModule';
import { AppRoute } from '../../components';

export const NAME = 'my-account';

export default class MyAccountModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'my-account.*';
  }

  getPolicy() {
    return 'Admin:MyAccount:View:*';
  }

  getNavigation() {
    return [
      // {
      //   label: 'My account',
      //   icon: 'user',
      //   path: null,
      //   permission: null
      // }
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}my-account`}
        load={() => import('./MyAccount')}
        // component={MyAccount}
      />
    ];
  }
}
