import React from 'react';

export default class AppValidationErrors extends React.Component {

  render() {
    if(!this.props.errors || !this.props.name) return null;
    const messages = [];

    this.props.errors.filter(x => x.field == this.props.name).forEach(
      error => messages.push(error.message)
    )
    if (messages.length == 0 ) {
      return null;
    }
    return <div className="validation-message" key={`${this.props.name  }-error`}>{messages.join("<br/>")}</div>;
  }
}
