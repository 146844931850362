import Api from '@launchpad/logic/api/Api';
import ToastHelper from '@launchpad/util/ToastHelper';
import { CircularProgress } from '@material-ui/core';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
  AppButton,
  FormHandler,
  FormSubmitWrapper,
  InputField,
  LoadingOverlay,
} from '../../../../../../@launchpad/index';
import CountdownTimer from './countdown-timer.component';
import ForgotPasswordModel from './models/ForgotPasswordModel';

export default class ForgotPassword extends Component<any, any> {
  formHandler: FormHandler;

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: Readonly<{}>) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new ForgotPasswordModel({
        identifier: '',
        identifierType: 'username',
      }),
      {
        authorizationRequest: {
          id: '',
          identifier: '',
          publicHash: '',
          status: '',
          authorizationCode: '',
          channel: '',
          flow: '',
        },
        step: 1,
        resendCodeEnabled: false,
        code: '',
        password: '',
        passwordConfirmation: '',
        isLoading: false,
      }
    );
    this.formHandler.on(
      'submitSuccess',
      (data: { success: any; data: any }) => {
        if (data.success) {
          const { authorizationRequest } = data.data;
          setTimeout(() => {
            this.setState({
              authorizationRequest: {
                ...authorizationRequest,
                identifier: this.state.record.identifier,
                flow: 'reset-password',
              },

              step: 2,
            });
          }, 1000);
        }
      }
    );
  }

  async onResendCode() {
    try {
      await Api.call('auth/authorization/resend', 'POST', {
        publicHash: this.state.authorizationRequest.publicHash,
      });
    } catch (error) {
      // Handled by Api.ts
      console.log('Unexpected error:', error);
    }
    this.setState({ resendCodeEnabled: false });
  }

  _getModalMessage(data: { success: any; message: any }) {
    if (!data.success) {
      return data.message;
    }

    if (data.success && !data.message) {
      return 'An email is sent to your mailbox';
    }
  }

  async verifyCode() {
    if (this.state.code === '') {
      ToastHelper.show('Please enter verification code!');
      return;
    }

    if (this.state.code.length < 6) {
      ToastHelper.show('Please enter valid code!');
      return;
    }

    try {
      this.setState({
        isLoading: true,
      });
      const result: any = await Api.call('auth/authorization/confirm', 'POST', {
        publicHash: this.state.authorizationRequest.publicHash,
        secret: this.state.code,
      });

      if (result.data) {
        const { hash } = result.data;
        this.setState({
          authorizationRequest: {
            ...this.state.authorizationRequest,
            publicHash: hash,
          },
          step: 3,
        });
      }
    } catch (error) {
      // Handled by Api.ts
      ToastHelper.show('Please enter valid code!');
      console.log('Unexpected error:', error);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async saveNewPassword() {
    if (this.state.password === '') {
      ToastHelper.show('Please enter a password!');
      return;
    }

    if (this.state.password.length < 8) {
      ToastHelper.show('Your password must be at least 8 characters long');
      return;
    }

    if (this.state.passwordConfirmation === '') {
      ToastHelper.show('Please re-enter a password!');
      return;
    }

    if (this.state.password !== this.state.passwordConfirmation.trim()) {
      ToastHelper.show('Passwords do not match!');
      return;
    }

    try {
      this.setState({
        isLoading: true,
      });
      const result: any = await Api.call(
        'auth/user/reset-password/complete',
        'POST',
        {
          hash: this.state.authorizationRequest.publicHash,
          password: this.state.password,
        }
      );
      if (result.success) {
        this.setState({
          step: 3,
        });

        ToastHelper.show(
          'Password configured! You will be redirected in 3s...',
          'success'
        );
        setTimeout(() => {
          this.setState({
            redirect: true,
            isLoading: false,
          });
        }, 3000);
      }
    } catch (error) {
      console.log('Error: ', error);
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }

    const { step } = this.state;

    const { formHandler } = this;

    let content = <LoadingOverlay />;

    if (step === 1) {
      content = (
        <div className="public-form-holder">
          <h4>Reset Password</h4>
          <div className="form-wrapper">
            <FormSubmitWrapper
              noCancelButton
              formHandler={formHandler}
              buttonClass="gold-button"
              buttonText="RESET PASSWORD"
              getModalMessage={(data: { success: any; message: any }) =>
                this._getModalMessage(data)
              }
            >
              <div className="login-input-wrapper">
                <InputField
                  className="input-group"
                  materialProps={{
                    fullWidth: true,
                  }}
                  autoComplete={this.state.record.identifier}
                  label="Enter E-mail"
                  name="identifier"
                  // placeholder={'Enter E-mail'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.record.identifier}
                  handler={formHandler}
                />
              </div>
            </FormSubmitWrapper>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
              }}
            >
              <Link
                to="/login"
                style={{
                  color: 'white',
                  textDecoration: 'none',
                  fontWeight: 'lighter',
                  fontSize: 15,
                }}
              >
                {' '}
                Back{' '}
              </Link>
            </div>
          </div>
        </div>
      );
    }
    if (step === 2) {
      content = (
        <div className="public-form-holder">
          <h4>Please confirm this action</h4>
          <div className="form-wrapper">
            <div style={{ textAlign: 'center' }}>
              <span>
                Enter 6 digit code we sent to {this.state.record.identifier}
              </span>
            </div>
            <div className="login-input-wrapper">
              <InputField
                className="input-group"
                materialProps={{
                  fullWidth: true,
                }}
                label="Code"
                name="code"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ maxLength: 6 }}
                value={this.state.code}
                type="text"
                onChange={(e) =>
                  this.setState({
                    code: e.target.value
                      .replace(/[^0-9.]/g, '')
                      .replace(/(\..*?)\..*/g, '$1'),
                  })
                }
              />
            </div>
            {!this.state.resendCodeEnabled ? (
              <div className="d-flex pt-5 justify-content-center">
                <p className="pr-1">You can resend your code in </p>
                <CountdownTimer
                  key={`${this.state.resendCodeEnabled}`}
                  time={!this.state.resendCodeEnabled ? 60 : 0}
                  timerEnd={() => this.setState({ resendCodeEnabled: true })}
                />
                <p className="pl-1"> seconds.</p>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 20,
                }}
              >
                <AppButton
                  onClick={() => this.onResendCode()}
                  disabled={!this.state.resendCodeEnabled}
                >
                  RESEND CODE
                </AppButton>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
              }}
            >
              <AppButton type="submit" onClick={() => this.verifyCode()}>
                {this.state.isLoading ? (
                  <CircularProgress
                    className="circle-progress"
                    size={30}
                    color="secondary"
                  />
                ) : (
                  'VERIFY'
                )}
              </AppButton>
            </div>
          </div>
        </div>
      );
    }
    if (step === 3) {
      content = (
        <div className="public-form-holder">
          <h4>RESET PASSWORD</h4>
          <div className="form-wrapper">
            <div className="login-input-wrapper">
              <InputField
                className="input-group"
                materialProps={{
                  fullWidth: true,
                }}
                label="Enter new password"
                type="password"
                name="password"
                // placeholder={'Password'}
                InputLabelProps={{
                  shrink: true,
                }}
                value={this.state.password}
                onChange={(e) =>
                  this.setState({
                    password: e.target.value,
                  })
                }
              />
              <InputField
                className="input-group"
                materialProps={{
                  fullWidth: true,
                }}
                label="Re-enter new password"
                type="password"
                name="passwordConfirmation"
                // placeholder={'Password'}
                InputLabelProps={{
                  shrink: true,
                }}
                value={this.state.passwordConfirmation}
                onChange={(e) =>
                  this.setState({
                    passwordConfirmation: e.target.value,
                  })
                }
              />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
              }}
            >
              <AppButton type="submit" onClick={() => this.saveNewPassword()}>
                {this.state.isLoading ? (
                  <CircularProgress
                    className="circle-progress"
                    size={30}
                    color="secondary"
                  />
                ) : (
                  'SAVE'
                )}
              </AppButton>
            </div>
          </div>
        </div>
      );
    }

    return <div>{content}</div>;
  }
}
