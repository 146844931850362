import React from 'react';
import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';

export const NAME = 'accesscontrol';

export default class AccessControlModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'accesscontrol.*';
  }

  getPolicy() {
    return 'Admin:RolesAndPermissions:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Roles & Permissions',
        icon: 'key',
        path: null,
        permission: 'accesscontrol.*',
        policy: 'Admin:RolesAndPermissions:View:*',
        children: [
          // {
          //   label: 'Roles',
          //   path: '/users/roles',
          //   icon: 'user-plus',
          //   permission: 'accesscontrol.roles.*',
          //   policy: 'Admin:RolesAndPermissions:Role:View:*',
          // },
          // {
          //   label: 'Permissions',
          //   path: '/users/permissions',
          //   icon: 'key',
          //   permission: 'accesscontrol.permissions.*',
          //   policy: 'Admin:RolesAndPermissions:Permission:View:*',
          // },
          {
            label: 'Team Members',
            path: '/users/team-members',
            icon: 'users',
            permission: 'accesscontrol.team-members.*',
            policy: 'Admin:RolesAndPermissions:TeamMember:View:*',
          },
        ],
      },
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        path={`${currentUrl}users`}
        load={() => import('./AccessControlPage')}
      />,
    ];
  }
}
