import { ModalDialog, SwitchButton } from '@launchpad';
import AppTable from '@launchpad/components/crud/AppTable';
import { date } from '@launchpad/util/DateHelper';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ActiveInactiveAppBadge from '../../../../../@launchpad/components/typography/ActiveInactiveAppBadge';
import RenderIfAllowed from '../../../../../@launchpad/util/access-control/access-control.component';
import LimitsAdjustmentsModel from '../models/LimitsAdjustmentsModel';

// TODO: change name since this is used for cards as well
export default class AccountLimitAdjustments extends Component {
  _tableComponent;

  constructor(props) {
    super(props);
    this.state = {
      showInfoModal: false,
      infoModalContent: '',
    };
  }

  onChangeItemStatus(limitGroup) {
    new LimitsAdjustmentsModel()
      .changeIsActive(limitGroup.id, !limitGroup.isActive)
      .then(() => {
        this._tableComponent._refresh();
        if (this.props.onRefresh) this.props.onRefresh();
      })
      .catch((e) => {
        this.setState({
          showInfoModal: true,
          infoModalContent: 'Something went wrong!',
        });
      });
  }

  _renderEnableDisableSwitch(item) {
    return (
      <RenderIfAllowed
        action="Admin:LimitControl:Manage:*"
        renderOnForbidden={<ActiveInactiveAppBadge active={item.isActive} />}
      >
        <SwitchButton
          onChange={() => this.onChangeItemStatus(item)}
          isChecked={item.isActive}
        />
      </RenderIfAllowed>
    );
  }

  render() {
    const entity = this.props.account || this.props.card;

    return (
      <div className="dashboard-management">
        <AppTable
          icon="lock"
          title="Limit adjustments"
          onInit={(tableComponent) => (this._tableComponent = tableComponent)}
          params={{
            entityId: entity.id || undefined,
          }}
          filter={[
            {
              name: 'term',
              type: 'term',
            },
          ]}
          headerActions={
            <RenderIfAllowed action="Admin:LimitControl:Manage:*">
              <Link
                to={`${entity.id}/add-new-limit-adjustment`}
                className="button width-240"
                style={{ marginRight: '10px' }}
              >
                <i className="fa fa-fw fa-plus" />
                Add new
              </Link>
            </RenderIfAllowed>
          }
          renderCell={(column, item, index) => {
            switch (column.name) {
              case 'activeFrom':
                return item.activeFrom ? date(item.activeFrom) : '-';
              case 'activeTo':
                return item.activeTo ? date(item.activeTo) : '-';
              case 'isActive':
                return this._renderEnableDisableSwitch(item);
              case 'configuration.items[0].amount':
                return item.configuration.items &&
                  item.configuration.items.length > 0
                  ? item.configuration.items[0].amount
                  : item.configuration.amount;
              case 'configuration.items[0].currency':
                return item.configuration.items &&
                  item.configuration.items.length > 0
                  ? item.configuration.items[0].currency
                  : item.configuration.currency;
              default:
            }
          }}
          model={LimitsAdjustmentsModel}
          getTableColumns={() => [
            {
              name: 'id',
              label: 'Id',
              sortable: false,
              type: 'field',
            },
            {
              name: 'name',
              label: 'name',
              sortable: false,
              type: 'field',
            },
            {
              name: 'configuration.items[0].amount',
              label: 'Amount',
              sortable: false,
              type: 'field',
            },
            {
              name: 'configuration.items[0].currency',
              label: 'Currency',
              sortable: false,
              type: 'field',
            },
            {
              name: 'activeFrom',
              label: 'Active from',
              sortable: false,
              type: 'field',
            },
            {
              name: 'activeTo',
              label: 'Active To',
              sortable: false,
              type: 'field',
            },
            {
              name: 'isActive',
              label: 'Is Active',
              sortable: false,
              type: 'field',
            },
            {
              name: null,
              label: 'Actions',
              sortable: false,
              type: 'actions',
            },
          ]}
          getColumnActions={(member) => {
            return (
              <div>
                <RenderIfAllowed action="Admin:LimitControl:Manage:*">
                  <Link
                    to={`${entity.id}/${member.id}/edit-limit-adjustment`}
                    className="button-info"
                  >
                    Edit
                  </Link>
                </RenderIfAllowed>
              </div>
            );
          }}
        />
        <ModalDialog
          show={this.state.showInfoModal}
          onClose={() => this.setState({ showInfoModal: false })}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <p style={{ textAlign: 'center', paddingBottom: 20 }}>
              {this.state.infoModalContent}
            </p>
            <button
              type="button"
              onClick={() => this.setState({ showInfoModal: false })}
              className="button-info"
            >
              OK
            </button>
          </div>
        </ModalDialog>
      </div>
    );
  }
}
