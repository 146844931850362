import { AppCheckboxInput } from '@launchpad/components';
import AppSelect from '@launchpad/components/crud/AppSelect';
import AppNumberField from '@launchpad/components/elements/form/AppNumberField';
import { FormHandler, FormSubmitWrapper, InputField } from '@launchpad/index';
import CountryModel from '@launchpad/logic/model/CountryModel';
import { AccountProductTypeModel } from '@launchpad/logic/model/ProductTypeModel';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import UserModel from '../models/UsersModel';

export default class UserForm extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new UserModel({
        title: '',
        firstName: '',
        middleName: '',
        lastName: '',
        companyName: '',
        email: '',
        mobileCountryCode: '+44',
        mobileShort: '',
        dateOfBirth: '',
        addressLineOne: '',
        addressLineTwo: '',
        addressLineThree: '',
        city: '',
        postcode: '',
        country: '',
        status: '',
        accountNos: '',
        productId: '',
        correspondenceAddressCountry: '',
        correspondenceAddressLineOne: '',
        correspondenceAddressLineTwo: '',
        correspondenceAddressLineThree: '',
        correspondenceAddressCity: '',
        correspondenceAddressPostcode: '',

        correspondenceOneTimeAddressCountry: '',
        correspondenceOneTimeAddressCity: '',
        correspondenceOneTimeAddressLine: '',
        correspondenceOneTimeAddressLineTwo: '',
        correspondenceOneTimeAddressLineThree: '',
        correspondenceOneTimeAddressPostcode: '',
        isCorrespondenceOneTimeAddress: false,

        embossLine3: '',
        embossLine4: '',
        priorityPassNumber: '',
        externalReference: '',
      })
    );
  }

  componentDidMount() {
    const { id } = this.props;
    if (id) {
      new UserModel().get(id).then((result) => {
        this.setState({
          // record: { ...result, countryId: 225 }
          record: result,
        });
      });
    }
  }

  getSubmitData() {
    return {
      ...this.state.record,
      phoneNumber:
        `${this.state.record.mobileCountryCode}|${this.state.record.mobileShort}`.trim(),
      landlineNumber:
        this.state.record.landlineCountryCode &&
        this.state.record.landlineNumberShort
          ? `${this.state.record.landlineCountryCode}|${this.state.record.landlineNumberShort}`.trim()
          : '',
    };
  }

  _getUserStatusOptions() {
    return [
      {
        id: 'active',
        name: 'Active',
      },
      {
        id: 'inactive',
        name: 'Inactive',
      },
    ];
  }

  async resetOneTimeAddress(val) {
    if (!val) {
      this.setState({
        record: {
          ...this.state.record,
          correspondenceOneTimeAddressCountry: '',
          correspondenceOneTimeAddressCity: '',
          correspondenceOneTimeAddressLine: '',
          correspondenceOneTimeAddressLineTwo: '',
          correspondenceOneTimeAddressLineThree: '',
          correspondenceOneTimeAddressPostcode: '',
          isCorrespondenceOneTimeAddress: val,
        },
      });
    }
  }

  render() {
    const { formHandler } = this;

    return (
      <div>
        <h1>Add new user</h1>
        <div
          className="element-with-blur"
          style={{ paddingTop: '2px', marginRight: 20 }}
        >
          <div className="dark-blur" />
          <div className="box-header">
            <h2>Add new user</h2>
          </div>
          <div className="add-new-group" style={{ position: 'relative' }}>
            <div className="container">
              <FormSubmitWrapper formHandler={formHandler} to="/">
                <div className="row">
                  <div className="col-md-6">
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      customInput={TextField}
                      label="Title"
                      type="text"
                      name="title"
                      value={this.state.record.title}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      customInput={TextField}
                      label="First Name"
                      type="text"
                      name="firstName"
                      value={this.state.record.firstName}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Last Name"
                      type="text"
                      name="lastName"
                      value={this.state.record.lastName}
                      handler={formHandler}
                    />
                    <div
                      style={{ position: 'relative', zIndex: 9999999999999 }}
                    >
                      <DatePicker
                        selected={
                          this.state.record.dateOfBirth
                            ? moment(this.state.record.dateOfBirth).toDate()
                            : ''
                        }
                        onChange={(date) =>
                          this.formHandler.handleInputChange(
                            'dateOfBirth',
                            moment(date).format('YYYY-MM-DD')
                          )
                        }
                        style={{ width: '100%', zIndex: 9999999999999 }}
                        // maxDate={moment().subtract(18, 'years')._d}
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        customInput={
                          <InputField
                            materialProps={{
                              fullWidth: true,
                            }}
                            InputLabelProps={{
                              name: 'dateOfBirth',
                              shrink: true,
                            }}
                            label="Date of birth"
                            type="text"
                            value={this.state.record.dateOfBirth}
                            handler={formHandler}
                          />
                        }
                      />
                    </div>
                    <div style={{ paddingTop: 14, zIndex: 9999999 }}>
                      <AppSelect
                        getOptionValue={(country) => country.iso3}
                        wrapperStyle={{ marginBottom: 5, zIndex: 9999999 }}
                        textFieldProps={{
                          label: 'Country',
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                        model={CountryModel}
                        value={this.state.record.country}
                        handler={formHandler}
                        placeholder="Search a Country"
                        name="country"
                      />
                    </div>
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Address Line"
                      type="text"
                      name="addressLineOne"
                      value={this.state.record.addressLineOne}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Address Line 3"
                      type="text"
                      name="addressLineThree"
                      value={this.state.record.addressLineThree}
                      handler={formHandler}
                    />

                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Account number"
                      type="text"
                      name="accountNos"
                      value={this.state.record.accountNos}
                      handler={formHandler}
                    />
                    <div className="row new-user-phone">
                      <div className="col-sm-7 input-wrap">
                        <AppSelect
                          textFieldProps={{
                            label: 'Country Code',
                            InputLabelProps: {
                              shrink: true,
                            },
                          }}
                          model={CountryModel}
                          getOptionValue={(country) => `+${country.phoneCode}`}
                          getOptionLabel={(country) =>
                            `${country.name} (+${country.phoneCode})`
                          }
                          name="landlineCountryCode"
                          value={this.state.record.landlineCountryCode}
                          handler={formHandler}
                          placeholder="Search a Country"
                          wrapperStyle={{ zIndex: 999 }}
                        />
                      </div>
                      <div className="col-sm-5 input-wrap-mobile">
                        <AppNumberField
                          format="################"
                          label="Landline number"
                          type="tel"
                          name="landlineNumberShort"
                          value={
                            this.state.record.landlineNumberShort &&
                            this.state.record.landlineNumberShort.trim()
                          }
                          handler={formHandler}
                        />
                      </div>
                    </div>
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Emboss cardholder name"
                      type="text"
                      name="embossLine3"
                      value={this.state.record.embossLine3}
                      handler={formHandler}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Email"
                      type="email"
                      name="email"
                      value={this.state.record.email}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Middle Name"
                      type="text"
                      name="middleName"
                      value={this.state.record.middleName}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Company name"
                      type="text"
                      name="companyName"
                      value={this.state.record.companyName}
                      handler={formHandler}
                    />
                    <div style={{ paddingTop: 14, zIndex: 99 }}>
                      <AppSelect
                        wrapperStyle={{ marginBottom: 5 }}
                        textFieldProps={{
                          label: 'Status',
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                        options={this._getUserStatusOptions()}
                        name="status"
                        value={this.state.record.status}
                        handler={formHandler}
                        placeholder="Status"
                      />
                    </div>
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Postcode"
                      type="text"
                      name="postcode"
                      value={this.state.record.postcode}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Address Line 2"
                      type="text"
                      name="addressLineTwo"
                      value={this.state.record.addressLineTwo}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="City"
                      type="text"
                      name="city"
                      value={this.state.record.city}
                      handler={formHandler}
                    />

                    <div className="row new-user-phone">
                      <div className="col-sm-7 input-wrap">
                        <AppSelect
                          textFieldProps={{
                            label: 'Country Code',
                            InputLabelProps: {
                              shrink: true,
                            },
                          }}
                          model={CountryModel}
                          getOptionValue={(country) => `+${country.phoneCode}`}
                          getOptionLabel={(country) =>
                            `${country.name} (+${country.phoneCode})`
                          }
                          name="mobileCountryCode"
                          value={this.state.record.mobileCountryCode}
                          handler={formHandler}
                          placeholder="Search a Country"
                        />
                      </div>
                      <div className="col-sm-5 input-wrap-mobile">
                        <AppNumberField
                          format="################"
                          label="Mobile"
                          type="tel"
                          name="mobileShort"
                          value={this.state.record.mobileShort.trim()}
                          handler={formHandler}
                        />
                      </div>
                    </div>
                    <div className="input-wrap" style={{ zIndex: 9 }}>
                      <AppSelect
                        getOptionValue={(product) => product.id}
                        wrapperStyle={{ marginBottom: 5 }}
                        textFieldProps={{
                          label: 'Product',
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                        model={AccountProductTypeModel}
                        name="productId"
                        value={this.state.record.productId}
                        handler={formHandler}
                        placeholder="Search a product"
                      />
                    </div>
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Emboss company name"
                      type="text"
                      name="embossLine4"
                      value={this.state.record.embossLine4}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Customer Reference"
                      type="text"
                      name="externalReference"
                      value={this.state.record.externalReference}
                      handler={formHandler}
                    />
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      marginTop: 30,
                      marginBottom: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ minWidth: '20%', fontSize: 18 }}>
                      Correspondence Address
                    </span>
                    <div
                      style={{
                        width: '100%',
                        backgroundColor: 'white',
                        height: 1,
                      }}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div style={{ paddingTop: 14, zIndex: 99 }}>
                        <AppSelect
                          wrapperStyle={{ marginBottom: 7 }}
                          getOptionValue={(country) => country.iso3}
                          textFieldProps={{
                            label: 'Country',
                            InputLabelProps: {
                              shrink: true,
                            },
                          }}
                          model={CountryModel}
                          name="correspondenceAddressCountry"
                          value={this.state.record.correspondenceAddressCountry}
                          handler={formHandler}
                          placeholder="Search a Country"
                        />
                      </div>
                      <InputField
                        materialProps={{
                          fullWidth: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Address Line"
                        type="text"
                        name="correspondenceAddressLineOne"
                        value={this.state.record.correspondenceAddressLineOne}
                        handler={formHandler}
                      />
                      <InputField
                        materialProps={{
                          fullWidth: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Address Line 2"
                        type="text"
                        name="correspondenceAddressLineTwo"
                        value={this.state.record.correspondenceAddressLineTwo}
                        handler={formHandler}
                      />
                      <InputField
                        materialProps={{
                          fullWidth: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Address Line 3"
                        type="text"
                        name="correspondenceAddressLineThree"
                        value={this.state.record.correspondenceAddressLineThree}
                        handler={formHandler}
                      />
                      <div
                        style={{
                          margin: '20px 0',
                          display: 'flex',
                          alignItems: 'flex-start',
                        }}
                      >
                        <AppCheckboxInput
                          name="isCorrespondenceOneTimeAddress"
                          value={
                            this.state.record.isCorrespondenceOneTimeAddress
                          }
                          onChange={(val) => this.resetOneTimeAddress(val)}
                          id="isCorrespondenceOneTimeAddress"
                          htmlFor="isCorrespondenceOneTimeAddress"
                          labelText="One-time address for delivery"
                          handler={formHandler}
                        />
                      </div>
                      {this.state.record.isCorrespondenceOneTimeAddress && (
                        <div>
                          <div style={{ paddingTop: 14, zIndex: 99 }}>
                            <AppSelect
                              wrapperStyle={{ marginBottom: 7 }}
                              getOptionValue={(country) => country.iso3}
                              textFieldProps={{
                                label: 'Country (One-time entry)',
                                InputLabelProps: {
                                  shrink: true,
                                },
                              }}
                              model={CountryModel}
                              name="correspondenceOneTimeAddressCountry"
                              value={
                                this.state.record
                                  .correspondenceOneTimeAddressCountry
                              }
                              handler={formHandler}
                              placeholder="Search for a country (one-time entry)"
                            />
                          </div>
                          <InputField
                            materialProps={{
                              fullWidth: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="City (One-time entry)"
                            type="text"
                            name="correspondenceOneTimeAddressCity"
                            value={
                              this.state.record.correspondenceOneTimeAddressCity
                            }
                            handler={formHandler}
                          />
                          <InputField
                            materialProps={{
                              fullWidth: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Address Line One (One-time entry)"
                            type="text"
                            name="correspondenceOneTimeAddressLine"
                            value={
                              this.state.record.correspondenceOneTimeAddressLine
                            }
                            handler={formHandler}
                          />
                          <InputField
                            materialProps={{
                              fullWidth: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Address Line Two (One-time entry)"
                            type="text"
                            name="correspondenceOneTimeAddressLineTwo"
                            value={
                              this.state.record
                                .correspondenceOneTimeAddressLineTwo
                            }
                            handler={formHandler}
                          />
                          <InputField
                            materialProps={{
                              fullWidth: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Address Line Three (One-time entry)"
                            type="text"
                            name="correspondenceOneTimeAddressLineThree"
                            value={
                              this.state.record
                                .correspondenceOneTimeAddressLineThree
                            }
                            handler={formHandler}
                          />
                          <InputField
                            materialProps={{
                              fullWidth: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Postcode (One-time entry)"
                            type="text"
                            name="correspondenceOneTimeAddressPostcode"
                            value={
                              this.state.record
                                .correspondenceOneTimeAddressPostcode
                            }
                            handler={formHandler}
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <InputField
                        materialProps={{
                          fullWidth: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="City"
                        type="text"
                        name="correspondenceAddressCity"
                        value={this.state.record.correspondenceAddressCity}
                        handler={formHandler}
                      />
                      <InputField
                        materialProps={{
                          fullWidth: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Postcode"
                        type="text"
                        name="correspondenceAddressPostcode"
                        value={this.state.record.correspondenceAddressPostcode}
                        handler={formHandler}
                      />
                    </div>
                  </div>
                </div>
              </FormSubmitWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserForm.defaultProps = {
  title: '',
  id: null,
};
