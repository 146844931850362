import React from 'react';
import { LoadingOverlay } from '../../components/index';
import { currency } from '../../util/NumberHelper';

const FeeCollectionDetailsModal = (props) => {

  const { fee, entityCurrency } = props;


  return (
    <div className="main" key={fee}>
      <div className="customers-info">
        <div className="customer-details">
            <h2 className="customer-name">
              <span className="company">
                {fee.title}
              </span>
            </h2>
        </div>

        <div className="customers-main">
          <div className="row">
            <div className="col">
              {!fee.id ? (
                <LoadingOverlay />
              ) : (
                <div className="padded">
                  <div className="row text-white">
                    <div className="col-lg-12">
                      <h4>Info</h4>
                      <hr />
                      <dl>
                        <dt>Id:</dt>
                        <dd>{fee.id || '-'}</dd>
                        <dt>Fee id:</dt>
                        <dd>{fee.feeId || '-'}</dd>
                        <dt>Fee Name:</dt>
                        <dd>{fee.feeName || '-'}</dd>
                        <dt>Fee type name:</dt>
                        <dd>{fee.feeTypeName || '-'}</dd>
                        <dt>Fee type id:</dt>
                        <dd>{fee.feeTypeId || '-'}</dd>
                        <dt>Entity:</dt>
                        <dd>{fee.entity.replaceAll('_', ' ') || '-'}</dd>
                        <dt>Entity id:</dt>
                        <dd>{fee.entityId || '-'}</dd>
                        <dt>Status:</dt>
                        <dd>{fee.status || '-'}</dd>
                        <dt>Fixed amount:</dt>
                        <dd>{currency(fee.fixedAmount, entityCurrency)}</dd>
                        <dt>Rate amount:</dt>
                        <dd>{currency(fee.rateAmount, entityCurrency)}</dd>
                        <dt>Total amount:</dt>
                        <dd>{currency(fee.totalAmount, entityCurrency)}</dd>
                        <dt>Original fixed amount:</dt>
                        <dd>{currency(fee.originalFixedAmount, fee.originalCurrency)}</dd>
                        <dt>Original rate amount:</dt>
                        <dd>{currency(fee.originalRateSAmount, fee.originalCurrency)}</dd>
                        <dt>Original total amount:</dt>
                        <dd>{currency(fee.originalTotalAmount, fee.originalCurrency)}</dd>
                        <dt>Fully collected:</dt>
                        <dd>{fee.fullyCollected ? 'YES' : 'NO'}</dd>
                        <dt>Transaction:</dt>
                        <dd>{fee.transactionId ?? '-'}</dd>
                      </dl>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>               
  );
};

export default FeeCollectionDetailsModal;
