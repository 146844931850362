import { AppPage } from '@launchpad';
import React from 'react';
import { VisitsFileTable } from '../../components';

const VisitsFilesList = (props) => {
  return (
    <AppPage title="Uploaded visits files" icon="list">
      <VisitsFileTable />
    </AppPage>
  );
};

export default VisitsFilesList;
