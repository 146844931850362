import { AppRoute } from '@launchpad';
import React from 'react';
import { Switch } from 'react-router-dom';
import AddLimitAdjustment from '../limit-control/pages/AddLimitAdjustment';
import Accounts from './Accounts';
import AccountDetails from './details/AccountDetails';
import AccountAddNewOwnership from './details/tabs/AccountAddNewOwnership';
import AccountAddPaymentDevice from './details/tabs/AccountAddPaymentDevice';

export default class AccountsNavigation extends React.PureComponent {
  render() {
    return (
      <Switch>
        <AppRoute
          path="/accounts/details/:id/add-new-ownership"
          component={AccountAddNewOwnership}
        />
        <AppRoute
          path="/accounts/details/:id/add-new-card"
          component={AccountAddPaymentDevice}
        />
        <AppRoute
          policy="Admin:LimitControl:Manage:*"
          path="/accounts/details/:id/add-new-limit-adjustment"
          component={AddLimitAdjustment}
        />
        <AppRoute
          policy="Admin:LimitControl:Manage:*"
          path="/accounts/details/:id/:limitId/edit-limit-adjustment"
          component={AddLimitAdjustment}
        />
        <AppRoute path="/accounts/details/:id" component={AccountDetails} />
        <AppRoute path="/accounts" component={Accounts} />
      </Switch>
    );
  }
}
