import React from 'react';
import EditUserForm from './EditUserForm';

export default (props) => {
  return (
    <div>
      {/* <FormSubmitWrapper  to='/users/all-users/'> */}
      <EditUserForm title="Edit User" id={props.match.params.id} />
      {/* </FormSubmitWrapper> */}
    </div>
  );
};
