import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NotesModel from '../models/NotesModel';
import NoteBasicInfo from './tabs/NoteBasicInfo';
import BreadcrumbHelper from '../../../util/BreadcrumbHelper';
import { AppFetcher } from '../../../index';
import AppTabs from '../../../components/crud/tabs/AppTabs';

export default class NotesDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: {},
      activeTab: 'basic'
    };

    BreadcrumbHelper.setCrumbs([
      {
        label: 'Notes',
        link: '/notes'
      }
    ]);
  }

  getTabs(note) {
    const basicInfo = <NoteBasicInfo note={note} />;
    const allTabs = [
      {
        id: 'basic',
        icon: 'info',
        title: 'Basic Info',
        component: basicInfo
      }
    ];
    return allTabs;
  }

  _renderContent(noteDetails) {
    BreadcrumbHelper.addCrumb(noteDetails.id);
    return (
      <div>
        <h1>
          <i className="fa fa-files-o" aria-hidden="true" /> Note
          Details
        </h1>
        <AppTabs
          onTabChange={activeTab => this.setState({ activeTab })}
          activeTab={this.state.activeTab}
          tabs={this.getTabs(noteDetails)}
        />
      </div>
    );
  }

  /**
   * Render
   *
   * @returns {XML}
   */

  render() {
    return (
      <div className="dashboard-management">
        <AppFetcher
          model={NotesModel}
          id={this.props.match.params.id}
          isIdInUrl
          renderContent={record => this._renderContent(record)}
         />
      </div>
    );
  }
}

// Inject router
NotesDetails.contextTypes = {
  router: PropTypes.object
};
