import Api from '../../../logic/api/Api';
import BaseModel from '../../../logic/model/BaseModel';

export default class FeeModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/fees';
  }

  deleteFee(id) {
    return Api.call(`admin/fees/${id}`, 'delete');
  }
}
