import React, { Component } from 'react';
import { FormSubmitWrapper, FormHandler, InputField } from '@launchpad/index';
import AppSelectWithSearch from '@launchpad/components/crud/AppSelectWithSearch';
import AssignUserToAccountModel from '../models/AssignUserToAccountModel';

export default class AssignUserToAccountForm extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new AssignUserToAccountModel({
        id: props.id,
        userId: props.userId || '',
        ownerId: props.ownerId || '',
        organisationId: props.organisationId || '',
      }),
      {
        dataType: 'userId',
      }
    );
  }

  onSubmitSuccess() {
    this.props.customCloseFunction();
  }

  getSubmitMethod() {
    return 'PATCH';
  }

  getSubmitData() {
    return {
      ...this.state.record,
      ownerId: this.state.record.userId,
    };
  }

  _getModalMessage(data) {
    if (!data.success) {
      return data.message;
    }
    if (data.success) {
      return 'Successfully assigned user to account!';
    }
    return null;
  }

  renderSwitchButtons(type, title) {
    return (
      <button
        type="button"
        onClick={() =>
          this.setState({
            dataType: type,
            record: {
              ...this.state.record,
              userId: '',
              ownerId: '',
            },
          })
        }
        disabled={this.state.dataType === type}
        className={
          this.state.dataType === type
            ? 'custom-active-button'
            : 'custom-inactive-button'
        }
      >
        <p className="text-center">{title}</p>
      </button>
    );
  }

  render() {
    const { formHandler } = this;

    return (
      <div>
        <h3 className="text-center">Assign user to account</h3>
        <h5 className="text-center">
          Note: this will reassign the existing user on account (if any)
        </h5>
        <div>
          <p className="text-center pt-3">Select the data type</p>
          <div className="w-75 d-flex align-items-center justify-content-around pt-4 m-auto">
            {this.renderSwitchButtons('userId', 'User id')}
            {/* {this.renderSwitchButtons('ownerId', 'Owner id')} */}
            {this.renderSwitchButtons('organisationId', 'Organisation id')}
          </div>
        </div>
        <div
          className="element-with-blur"
          style={{ paddingTop: '2px', marginRight: 20 }}
        >
          <div className="add-new-group" style={{ position: 'relative' }}>
            <div className="container">
              <FormSubmitWrapper
                formHandler={formHandler}
                to={this.props.redirectRoute}
                buttonText={this.props.buttonText}
                customCloseFunction={this.props.customCloseFunction}
                getModalMessage={(data) => this._getModalMessage(data)}
              >
                {this.state.dataType === 'userId' && (
                  <AppSelectWithSearch
                    url="admin/users"
                    label="User"
                    name="userId"
                    value={this.state.record.userId}
                    handler={formHandler}
                    placeholder="Search a user"
                    wrapperStyle={{ zIndex: 99999 }}
                    getOptionValue={(user) => user.id}
                    getOptionLabel={(user) =>
                      `${user.firstName} ${user.lastName} (${user.username}) (${user.customerReference})`
                    }
                  />
                )}

                {/* this.state.dataType === 'ownerId' && (
                  <InputField
                    materialProps={{
                      fullWidth: true,
                    }}
                    type="text"
                    label="Owner id"
                    name="ownerId"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.record.ownerId}
                    handler={formHandler}
                  />
                ) */}

                {this.state.dataType === 'organisationId' && (
                  <InputField
                    materialProps={{
                      fullWidth: true,
                    }}
                    type="text"
                    label="Organisation id"
                    name="organisationId"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.record.organisationId}
                    handler={formHandler}
                  />
                )}
              </FormSubmitWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
AssignUserToAccountForm.defaultProps = {
  id: null,
};
