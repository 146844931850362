import ToastHelper from '@launchpad/util/ToastHelper';
import axios from 'axios';
import _ from 'lodash';
import React from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import Config from '../../config';
import Api from '../../logic/api/Api';
import AppValidationErrors from './AppValidationErrors';
import ProgressBarComponent from './ProgressBarComponent';

const STATUS_OLD = 'old';
const STATUS_NEW = 'new';
const STATUS_UPLOADING = 'uploading';
const STATUS_QUEUED = 'queued';
const STATUS_REMOVED = 'removed';

const extractName = value => {
  return value.split('/').pop();
};

const mapStateToProps = state => {
  return {
    token: state.auth.token
  };
};

class AppUploadField extends React.Component {
  state = {
    files: [],
    errors: []
  };

  constructor(props) {
    super(props);

    if (props.value) {
      this.state = {
        initialValue: null,
        files: [
          {
            id: _.uniqueId('prefix-'),
            file: props.value,
            name: extractName(props.value),
            path: Config.publicUrl + props.value,
            status: STATUS_OLD,
            progress: 0,
            value: props.value
          }
        ]
      };
    }
  }

  static getDerivedStateFromProps(props, state) {
    const stateChange = {};

    if (props.value && !state.initialValue) {
      stateChange.initialValue = props.value;
      let path = null;
      if (props.value) {
        path =
          props.value[0] === '/'
            ? Config.publicUrl + props.value
            : `/admin/api/tools/preview/${props.value}`;
      }
      stateChange.files = [
        {
          id: _.uniqueId('prefix-'),
          file: props.value,
          name: extractName(props.value),
          path,
          status: STATUS_OLD,
          progress: 0,
          value: props.value
        }
      ];
    }

    return stateChange;
  }

  onImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = e => {
        this.setState({ image: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  handleDrop = acceptedFiles => {
    if (acceptedFiles.length > 0) this._addFiles(acceptedFiles);
  };

  _addFiles(acceptedFiles) {
    const parsedFiles = [];

    acceptedFiles.forEach(file => {
      parsedFiles.push({
        id: _.uniqueId('prefix-'),
        file,
        name: file.name,
        path: file.preview,
        status: STATUS_QUEUED,
        progress: 0,
        value: null
      });
    });
    if (!this.props.multiple) {
      this.setState(
        {
          files: [parsedFiles[0]],
          errors: []
        },
        () => this._triggerUpload()
      );
    } else {
      this.setState(
        {
          files: [...this.state.files, ...parsedFiles],
          errors: []
        },
        () => this._triggerUpload()
      );
    }
  }

  _triggerUpload() {
    this.state.files.forEach(file => {
      if (file.status === STATUS_QUEUED) {
        this._startUploading(file);
      }
    });
  }

  async _startUploading(file) {
    if (this.props.onUploadStart) {
      this.props.onUploadStart(file);
    }
    const data = new FormData();
    data.append('file', file.file);
    data.append('filename', file.name);
    if (this.props.appendData) {
      this.props.appendData(data, file);
    }

    if (this.props.additionalData) {
      data.append(
        this.props.additionalData.name,
        this.props.additionalData.value
      );
    }

    this._updateFile(file, {
      status: STATUS_UPLOADING
    });
    axios
      .post(`${Config.url}${this.props.submitUrl}`, data, {
        headers: {
          Authorization: `Bearer ${Api.resolveToken()}`,
          'Provided-Device-Id': Api.getProvidedDeviceId()
        },
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this._updateFile(file, {
            progress: percentCompleted
          });
        }
      })
      .then(response => {
        if (response.data.success) {
          this._updateFile(file, {
            value: response.data?.data?.name || '',
            path: response.data?.data?.preview
              ? Config.publicUrl + response.data.data.preview
              : null,
            status: STATUS_NEW
          });
          if (this.props.onFileUpload) {
            this.props.onFileUpload(file, response.data);
          }
          ToastHelper.show('File successfully uploaded.', 'success');
        } else {
          ToastHelper.show('Something went wrong.');
          this._removeFile(file);
        }
      })
      .catch(error => {
        const errors = error?.response?.data.errors ?? [];
        if (errors && errors.length > 0) {
          ToastHelper.show(`Error: ${errors.map(x => x.message).join(';')}`);
        } else {
          ToastHelper.show('Something went wrong.');
        }
        this.setState({
          files: [],
          errors: errors.map(x => x.message)
        });
        if (this.props.onFileUpload) {
          this.props.onFileUpload(file, error.response?.data);
        }
      });
  }

  _updateFile(file, data) {
    const files = [...this.state.files];

    const foundFile = files.find(x => x.id === file.id);

    if (foundFile) {
      const index = files.indexOf(foundFile);
      const newFile = {
        ...foundFile,
        ...data
      };
      files[index] = newFile;
      this.setState(
        {
          files
        },
        () => this._afterFilesUpdate()
      );
    }
  }

  _removeFile(file) {
    const files = [...this.state.files];
    const index = files.indexOf(file);
    if (index >= 0) {
      files.splice(index, 1);
      this.setState(
        {
          files
        },
        () => {
          this._afterFilesUpdate();
          if (this.props.onFileRemove) {
            this.props.onFileRemove(file);
          }
        }
      );
    }
  }

  _afterFilesUpdate() {
    if (this.props.handler) {
      if (!this.props.multiple) {
        this.props.handler.handleInputChange(
          this.props.name,
          this.state.files[0] ? this.state.files[0].value : null
        );
      }
    }
  }

  _getDropzone() {
    if (!this.props.multiple && this.state.files.length > 0) return null;

    return (
      <Dropzone
        accept={this.props.accept}
        onDrop={this.handleDrop}
        multiple={this.props.multiple}
        style={{
          display: 'inline-block',
          padding: 20,
          textAlign: 'center',
          border: '2px dashed #fff',
          borderRadius: 10
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <div
              style={{
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative'
              }}
              className="uploadButton"
            >
              <button
                type="file"
                value="upload"
                className="cancelBtn"
                style={{
                  color: 'white',
                  background: 'transparent',
                  opacity: 0
                }}
              >
                {' '}
              </button>
              <p>BROWSE</p>
              <input
                type="file"
                id="profile_pic"
                name="profile_pic"
                title=" "
                accept={this.props.accept}
                onChange={this.onImageChange.bind(this)}
                style={{ opacity: 0 }}
                className="fileInput-addnewproject"
                {...getInputProps()}
              />
              <div style={{ color: '#fff', marginTop: 20 }}>
                <div>
                  <em
                    className="fa fa-fw fa-upload"
                    style={{ fontSize: '4em' }}
                  />
                </div>
                <div>
                  <span>or</span>
                </div>
                <div>
                  <span>drag n' drop here</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    );
  }

  _getUploadedFiles() {
    if (!this.state.files.length) return null;

    const elements = [];
    const UploadedFile = props => this._getUploadedFileItem(props.file);

    this.state.files.forEach((file, index) => {
      elements.push(<UploadedFile file={file} key={index} />);
    });

    return elements;
  }

  _getThumbnailPath(path) {
    if (!path) return require('../../assets/no-image.png');

    const parts = path.split('.');
    const filename = path.split('/').pop();

    const extension = parts[parts.length - 1];

    if (['jpg', 'jpeg', 'png'].filter(x => x === extension).length) {
      return path;
    }

    if (path.substring(0, 5) === 'http:' || path.substring(0, 6) === 'https:') {
      return path;
    }

    if (path[0] === '/') return Config.publicUrl + path;

    return require('../../assets/no-image.png');
  }

  _getUploadedFileItem(file) {
    if (!file) return <div />;
    let bottomElement = null;

    if (file.status === STATUS_UPLOADING) {
      bottomElement = (
        <div
          style={{
            border: '1px solid blue',
            minHeight: 20,
            display: 'flex'
          }}
        >
          <ProgressBarComponent percentage={file.progress} />
        </div>
      );
    } else {
      bottomElement = (
        <div style={{ marginTop: 10 }}>
          <a
            href="javascript:void(0)"
            onClick={() => this._removeFile(file)}
            className="btn btn-sm button-danger px-3 py-1"
          >
            New file
          </a>
        </div>
      );
    }
    return (
      <div
        className="file-preview"
        style={{
          display: 'inline-block',
          border: '1px solid #e0e0e0',
          padding: 10,
          textAlign: 'center'
        }}
      >
        <p>{file.name}</p>
        <div
          style={{
            width: 300,
            height: 200,
            backgroundPosition: 'center center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url("${this._getThumbnailPath(file.path)}")`
          }}
        />
        {bottomElement}
      </div>
    );
  }

  render() {
    const dropzone = this._getDropzone();
    const uploadedFiles = this._getUploadedFiles();
    const formHandlerErrors = this.props.handler?.getErrors();
    return (
      <div className="app-upload-btn-holder" style={{ cursor: 'pointer' }}>
        <label style={{ color: 'white' }}>{this.props.label}</label>
        <div style={this.props.dropzoneStyle}>
          {dropzone}
          {uploadedFiles}
        </div>
        <AppValidationErrors
          errors={[
            ...(formHandlerErrors ?? []),
            { field: this.props.name, message: this.state.errors?.join(';') }
          ]}
          name={this.props.name}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(AppUploadField);

AppUploadField.defaultProps = {
  label: '*Upload the cover image',
  accept: '.jpg, .jpeg, .png',
  multiple: false
};
