import Api from '@launchpad/logic/api/Api';
import BaseModel from '@launchpad/logic/model/BaseModel';

export default class UserModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/users';
  }

  /**
   * Get details url
   */
  getDetailsUrl() {
    return 'users/details';
  }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'admin/tnbank/users';
  }

  /**
   * Get delete url
   */
  getDeleteUrl(id) {
    return `admin/user/${id}`;
  }

  async delete(id) {
    const result = await Api.call(this.getDeleteUrl(id), 'DELETE');
    return result;
  }

  // /**
  //  * Set user status to enabled or disabled
  //  * @param {int} userid
  //  * @param {boolean} isEnabled
  //  */
  // async changeUserStatus(userId, isEnabled) {
  //   let url = 'user/disable';
  //   if (isEnabled) {
  //     url = 'user/enable';
  //   }
  //   const result = await Api.call(url, { id: userId });
  //   return result;
  // }

  /**
   * Set user status on Marketing Consent
   * @param {int} userid
   * @param {boolean} isEnabled
   */
  async changeMarketingConsentStatus(userId, isEnabled) {
    const url = 'admin/marketing-consent';
    const result = await Api.call(url, 'POST', {
      userId,
      marketingConsent: isEnabled,
    });

    return result;
  }

  /**
   * Get changes list url
   */
  getRequestsUrl() {
    return 'change-request/list';
  }

  async geRequestChanges(id) {
    const result = await Api.call(this.getRequestsUrl(), {
      id,
    });
    return result.data;
  }

  /**
   * Get resend-invitation url
   */
  getResendInvitationUrl(id) {
    return `admin/tnbank/users/${id}/resend-invitation`;
  }

  async getResendInvitation(id) {
    const result = await Api.call(this.getResendInvitationUrl(id), 'POST');
    return result;
  }

  async getLastInvitation(id) {
    const result = await Api.call(
      `admin/tnbank/users/${id}/get-last-invitation`,
      'GET'
    );
    return result.data;
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: false,
        type: 'field',
      },
      {
        name: 'customerReference',
        label: 'Cust. Reference',
        sortable: false,
        type: 'field',
      },
      {
        name: 'firstName',
        label: 'First Name',
        sortable: false,
        type: 'field',
      },
      {
        name: 'lastName',
        label: 'Last Name',
        sortable: false,
        type: 'field',
      },
      {
        name: 'email',
        label: 'E-mail',
        sortable: false,
        type: 'field',
      },
      {
        name: 'kycStatus',
        label: 'KYC Status',
        sortable: false,
        type: 'field',
      },
      {
        name: 'createdAt',
        label: 'Created At',
        sortable: false,
        type: 'field',
      },
      {
        name: 'updatedAt',
        label: 'Updated At',
        sortable: false,
        type: 'field',
      },
      // {
      //   name: 'marketingConsent',
      //   label: 'Marketing Consent',
      //   sortable: false,
      //   type: 'field'
      // },
      {
        name: 'userStatus',
        label: 'Enable / Disable User',
        sortable: false,
        type: 'field',
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions',
      },
    ];
  }

  /**
   * Get wallet for the user
   *
   * @param {Integer} id Id of the user
   */
  async getAccount(id) {
    const result = await Api.call('users/wallet', {
      id,
    });

    return result.data;
  }

  /**
   * Get cards of the user
   *
   * @param {Integer} id Id of the user
   */
  async getCards(id) {
    const result = await Api.call('users/cards', {
      id,
    });

    return result.data;
  }

  // /**
  //  * Get payment cards for specific user id
  //  *
  //  * @param {Integer} id Id of the user
  //  */
  // async getPaymentCards(id) {
  //   const result = await Api.call('payment-cards/list', {
  //     userId: id
  //   });
  // }

  /**
   * Get reset password url
   */
  getResetPasswordUrl(id) {
    return `admin/user/${id}/password-reset`;
  }

  /**
   * Reset password
   *
   * @param {string} id
   * @returns
   */
  async resetPassword(id) {
    const result = await Api.call(this.getResetPasswordUrl(id), 'POST', {
      sendEmail: true,
      returnPassword: true,
    });

    return result.data;
  }

  async sendForgotUsername(email) {
    const result = await Api.call(`admin/user/forgot-username`, 'POST', {
      email,
    });

    return result.data;
  }

  /**
   * Get KYC approved reject url
   */
  getKYCApproveRejectUrl(id) {
    return `admin/user/${id}/kyc-status`;
  }

  async approveRejectKYC(userId, status) {
    const result = await Api.call(
      this.getKYCApproveRejectUrl(userId),
      'PATCH',
      {
        kycStatus: status,
      }
    );
    return result.data;
  }

  async updateSecurityQA(userId, securityQuestion, securityAnswer) {
    const result = await Api.call(
      `admin/user/${userId}/additional-data`,
      'POST',
      {
        securityQuestion,
        securityAnswer,
      }
    );
    return result.data;
  }

  async changeUserStatus(userId, status) {
    const result = await Api.call(`admin/user/${userId}/user-status`, 'PATCH', {
      userStatus: status,
    });
    return result.data;
  }

  async triggerKYC(id) {
    const result = await Api.call('users/kyc-status/trigger', {
      id,
    });
    return result.data;
  }

  /**
   * Trigger eKYC again
   */
  async triggerEKYC(id) {
    const result = await Api.call('users/kyc-status/trigger-ekyc', {
      id,
    });
    return result.data;
  }

  async triggerPEP(id) {
    const result = await Api.call('users/kyc-status/trigger-pep', {
      id,
    });
    return result.data;
  }

  async triggerSIS(id) {
    const result = await Api.call('users/kyc-status/trigger-sis', {
      id,
    });
    return result.data;
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      title: {
        presence: {
          allowEmpty: false,
          message: '^Please enter title',
        },
      },
      firstName: {
        presence: {
          allowEmpty: false,
          message: '^Please enter first name',
        },
      },
      lastName: {
        presence: {
          allowEmpty: false,
          message: '^Please enter last name',
        },
      },
      email: {
        presence: {
          allowEmpty: false,
          message: '^Please enter an email address',
        },
        email: {
          message: '^Please enter a valid email address',
        },
      },
      mobileCountryCode: {
        presence: {
          allowEmpty: false,
          message: '^Please select country code',
        },
      },
      mobileShort: {
        presence: {
          allowEmpty: false,
          message: '^Please enter mobile number',
        },
      },
      dateOfBirth: {
        presence: {
          allowEmpty: false,
          message: '^Please enter birth date',
        },
      },
      addressLineOne: {
        presence: {
          allowEmpty: false,
          message: '^Please enter address line',
        },
      },
      city: {
        presence: {
          allowEmpty: false,
          message: '^Please enter city',
        },
      },
      postcode: {
        presence: {
          allowEmpty: false,
          message: '^Please enter postcode',
        },
      },
      country: {
        presence: {
          allowEmpty: false,
          message: '^Please select country',
        },
      },
      status: {
        presence: {
          allowEmpty: false,
          message: '^Please select status',
        },
      },
      // accountNos: {
      //   presence: {
      //     allowEmpty: false,
      //     message: '^Please enter account number',
      //   },
      // },
      // productId: {
      //   presence: {
      //     allowEmpty: false,
      //     message: '^Please select account type',
      //   },
      // },
      // embossCardholderName: {
      //   presence: {
      //     allowEmpty: false,
      //     message: '^Please enter cardholder name',
      //   },
      // },
      correspondenceAddressCountry: {
        presence: {
          allowEmpty: false,
          message: '^Please select country',
        },
      },
      correspondenceAddressLineOne: {
        presence: {
          allowEmpty: false,
          message: '^Please enter street',
        },
      },
      correspondenceAddressCity: {
        presence: {
          allowEmpty: false,
          message: '^Please enter city',
        },
      },
      correspondenceAddressPostcode: {
        presence: {
          allowEmpty: false,
          message: '^Please enter postcode',
        },
      },
    };
  }

  customValidation(record) {
    const errors = [];

    if (!record.isCorrespondenceOneTimeAddress) return [];

    const mandatoryFields = [
      'correspondenceOneTimeAddressCountry',
      'correspondenceOneTimeAddressCity',
      'correspondenceOneTimeAddressLine',
      'correspondenceOneTimeAddressPostcode',
    ];

    mandatoryFields.forEach((mandatoryField) => {
      if (
        !record[mandatoryField] ||
        record[mandatoryField].trim().length === 0
      ) {
        errors.push({
          field: `${mandatoryField}`,
          message:
            mandatoryField === 'correspondenceOneTimeAddressLine'
              ? `Please enter the ${mandatoryField
                  .replace('correspondenceOneTime', '')
                  .replace(/([a-z])([A-Z])/g, '$1 $2')
                  .toLowerCase()}`
              : `Please enter the ${mandatoryField
                  .replace('correspondenceOneTimeAddress', '')
                  .toLowerCase()}`,
        });
      }
    });

    return errors;
  }
}
