import BaseModel from '@launchpad/logic/model/BaseModel';

export default class EditUserAdditionalDataModel extends BaseModel {
  getMethod() {
    return 'GET';
  }

  getSubmitMethod() {
    return 'POST';
  }

  getSubmitUrl(payload) {
    return `admin/user/${payload.id}/additional-data`;
  }
}
