import BaseModel from '@launchpad/logic/model/BaseModel';

export default class UsersAccountModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'admin/accounts';
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      accountNumber: {
        presence: {
          allowEmpty: false,
          message: '^Please enter account number',
        },
      },
      productId: {
        presence: {
          allowEmpty: false,
          message: '^Please choose product',
        },
      },
    };
  }
}
