import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import ModalDialog from './ModalDialog';

const ConfirmationModalDialog = props => {
  const { cancelButton = 'Cancel', submitButton = 'Submit' } = props;
  const [loading, setLoading] = useState(false);

  // Set loading to false every time when new modal is opened
  useEffect(() => {
    setLoading(false);
  }, [props.show]);

  function handleSubmitClick() {
    if (props.noLoading) {
      setLoading(false);
      props.onSubmit();
    } else {
      setLoading(true);
      props.onSubmit();
    }
  }

  return (
    <ModalDialog show={props.show} onClose={props.onModalClose}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <p style={{ textAlign: 'center', paddingBottom: 20 }}>
          {props.text ? props.text : null}
        </p>
        {props.content ? props.content : null}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {cancelButton !== null && (
            <button
              type="button"
              onClick={props.onModalClose}
              className="button-danger px-3 py-1"
            >
              {cancelButton}
            </button>
          )}
          <button
            type="button"
            onClick={handleSubmitClick}
            className="button-info px-3 py-1"
          >
            {loading ? (
              <CircularProgress className="circle-progress" size={20} />
            ) : (
              submitButton
            )}
          </button>
        </div>
      </div>
    </ModalDialog>
  );
};

export default ConfirmationModalDialog;
