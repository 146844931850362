import BaseModel from '@launchpad/logic/model/BaseModel';
import Api from '@launchpad/logic/api/Api';
import ValidateEmailService from '../../models/ValidateEmailService';

export default class ForgotPasswordModel extends BaseModel {
  getSubmitUrl() {
    return 'auth/user/reset-password';
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    // return {
    //   email: {
    //     presence: {
    //       message: '^Please enter an email address'
    //     },
    //     email: {
    //       message: '^Please enter a valid email address'
    //     }
    //   }
    // };
  }

  customValidation(record) {
    try {
      ValidateEmailService.validateEmail(record.identifier);
    } catch (e) {
      return [
        {
          field: 'identifier',
          message: e.message,
        },
      ];
    }

    return [];
  }
}
